<template>
  <div class="scoller-wrap" @click="goEvent">
    <div class="no-data" v-if="!listData.length">暂无报警事件</div>
    <vue-seamless-scroll
      v-else
      :data="listData"
      :class-option="classOption"
      class="warp"
    >
      <ul class="ul-item">
        <li v-for="(item, index) in listData" :key="index" class="li-item">
          {{ item }}
        </li>
      </ul>
    </vue-seamless-scroll>
  </div>
</template>

<script type="text/ecmascript-6">
import moment from 'moment';
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  components: { vueSeamlessScroll },
  data() {
    return {
      listData: [],
      classOption: {
        limitMoveNum: 1,
        direction: 3,
      },
      audiosrc:"",
      highlightedArr:[],
      synth001:null,
      synth002:null,
      timer:null
    };
  },
  computed: {
    client(){
        return this.$store.state.app.client;
    },
    // mqttMessage(){
    //     return this.$store.state.app.mqttMessage;
    // }
  },
  watch: {
    // mqttMessage(newV,oldV){
    //   if(newV&&(newV.topic.split("/")[3]=="alertEvent")){
    //     let res = this.$drainageApi.Utf8ArrayToStr(newV.data);
    //     this.player(JSON.parse(res).alertName);
    //   }
    // }
  },
  mounted() {
    this.client.subscribe('wid/org/'+this.$store.state.user.orgId+'/alertEvent/#');
    setTimeout(()=>{
      this.getEventData();
      this.timer = setInterval(()=>{
        this.getEventData();
      },20000)
    },15000)

  },
  methods: {
    player(data){
      data = data.replace(/>/g,'大于');
      data = data.replace(/#/g,'号');
      data = data.replace(/min/g,'分钟')
      this.synth001 = window.speechSynthesis; // 启用文本
      const msg = new SpeechSynthesisUtterance();
      var tips = data;
      msg.text = tips;
      msg.lang = "zh-CN"; // 使用的语言:中文
      msg.volume = 1; // 声音音量：1
      msg.rate = 1; // 语速：1
      msg.pitch = 1; // 音高：1
      this.synth001.speak(msg); // 播放
      this.listData.push(data);
    },
    getEventData() {
      this.listData = [];
      let paramas = {
          page: 0,
          size: 100,
          endTime: moment().endOf('day').toISOString(),
          startTime: moment().startOf('day').toISOString(),
          eventClosed:"open"
      }
      this.$api.getEvent(paramas).then((res) => {
          if (res.status == 200&&res.data.content.length>0) {
            let warnData = res.data.content.filter(val=>{
              return val.eventSource!="gateway";
            }),str='',flashData=[],obj={};
            // this.listData = [];
            if(warnData.length>0){
              warnData.forEach(val=>{
                if(val.eventSource=="watcher"&&val.releaseAlarmStatus=="notReleased"){
                  str+= '；'+val.alertName;
                }
              });
              str = str.replace(/>/g,'大于');
              str = str.replace(/</g,'小于');
              str = str.replace(/#/g,'号');
              str = str.replace(/min/g,'分钟');
              this.synth002 = window.speechSynthesis; // 启用文本
              const msg = new SpeechSynthesisUtterance();
              var tips = str;
              msg.text = tips;
              msg.lang = "zh-CN"; // 使用的语言:中文
              msg.volume = 1; // 声音音量：1
              msg.rate = 1; // 语速：1
              msg.pitch = 1; // 音高：1
              this.synth002.speak(msg); // 播放
              !!str&&this.listData.push(str);
            }
          }
      });
    },
    goEvent(){
      this.$router.push("/event/eventcenter");
    }
  },
  destroyed() {
    // 清除定时器
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>

<style scoped lang="scss">
.scoller-wrap {
  width: 100%;
  display: inline-block;
  .no-data {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .warp {
    width: 100%;
    height: 50px;
    margin: 0 auto;
    overflow: hidden;
    ul {
      list-style: none;
      padding: 0;
      margin: 0 auto;
      &.ul-item {
        display: flex;
        .li-item {
          height: 50px;
          margin-right: 10px;
          line-height: 50px;
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }
}
.scoller-wrap:hover{
  cursor: pointer;
}
</style>
