import axios from "../axios";
import qs from "qs";
const gatewayApi = {
  getGatewayList(params) {
    /**
     * 分页查询网关信息
     * @parmas {Number} page 第几页 必填
     * @parmas {Number} size 页的数量 必填
     * @parmas {array} status 状态
     */
    return axios.get("/gateway/list", {
      params,
      showLoading: true,
    });
  },
  createGateway(params) {
    /**
     * 新增网关
     * @parmas {string} name 网关名称
     * @parmas {string} protocolType 网关类型
     * @parmas {Number} status 状态 默认为1
     * @parmas {Object} config 角色
     */
    return axios.post("/gateway", params, {
      headers: {
        "Content-Type": "application/json",
      },
      showLoading: true,
    });
  },
  searchByGatewayId(params) {
    /**
     * 根据网关id查询网关信息
     * @params {string} gatewayId 网关id
     */
    return axios.get("/gateway/" + params, { showLoading: true });
  },
  modifyGateway(id, params) {
    /**
     * 更新网关信息
     "gatewayId": "string"
     * { "config": {},
        "name": "string",
        "protocolType": "string",
        "status": 0
        }
     */
    return axios.put(
      "/gateway/" + id,
      params,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
      { showLoading: true }
    );
  },
  deleteByGatewayId(params) {
    /**
     * 删除网关
     * @params {string} gatewayId 网关id
     */
    return axios.delete("/gateway/" + params, { showLoading: true });
  },
  modifyGatewayStatus(params) {
    /**
     * 停用或启用gateway
     *  @params {string} gatewayId 网关id
     * @parmas {Number} status 状态
     */
    return axios.put(
      "/gateway/status",
      params,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
      { showLoading: true }
    );
  },
};
export default gatewayApi;
