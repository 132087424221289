import axios from "./axios";
export const controlCenterConfig = {
    controlCenterConfig: {
        // 查询列表
        getList(params) {
            return axios.get("/ctl/device/config/list", {
                params,
                showLoading: true,
            });
        },
        // 新增
        add(params) {
            return axios.post("/ctl/device/config", params, {
                headers: {
                    "node-Type": "application/json",
                },
                showLoading: true,
            });
        },
        // 删除
        del(ctlDeviceIds) {
            return axios.delete("/ctl/device/config", {
                params: {
                    ctlDeviceIds,
                },
                showLoading: true,
            });
        },
        // 根据id 查询
        getNodeInfo(id) {
            return axios.get("/ctl/device/config/" + id, {
                showLoading: true,
            });
        },
        // 修改
        modify(params) {
            return axios.put("/ctl/device/config", params, {
                headers: {
                    "node-Type": "application/json",
                },
                showLoading: true,
            });
        },

        /*查询回路树形菜单数据*/
        getLoopTreeData(params){
            return axios.post("/ctl/loop/tree", params, {
                headers: {
                    "node-Type": "application/json",
                },
                showLoading: true,
            });
        },
        /*新增回路*/
        addLoop(params){
            return axios.post("/ctl/loop", params, {
                headers: {
                    "node-Type": "application/json",
                },
                showLoading: true,
            });
        },
        /*修改回路*/
        editLoop(params){
            return axios.put("/ctl/loop", params, {
                headers: {
                    "node-Type": "application/json",
                },
                showLoading: true,
            });
        },
        /*删除回路*/
        delLoop(ctlLoopId){
            return axios.delete(`/ctl/loop/${ctlLoopId}`, {
                showLoading: true,
            });
        },
        /*根据id查询回路*/
        getLoopById(ctlLoopId){
            return axios.get(`/ctl/loop/${ctlLoopId}`, {
                showLoading: true,
            });
        },

        /*添加回路配置*/
        addLoopConfig(params){
            return axios.post("/ctl/loop/config", params, {
                headers: {
                    "node-Type": "application/json",
                },
                showLoading: true,
            });
        },
        /*查询回路配置*/
        getLoopConfig(){
            return axios.get(`/ctl/loop/config`, {
                showLoading: true,
            });
        }
    },
};
