// @ts-nocheck
import axios from "axios";
import store from "../store";
import router from "../router";
import qs from "qs";
import local from "@/util/local";

import {
  showFullScreenLoading,
  tryHideFullScreenLoading,
} from "./servicesHelp";
import { Message,MessageBox  } from "element-ui";

import domMessage from '@/util/messageOnce'

const messageOnce = new domMessage()

// 全局配置axios请求的url
axios.defaults.baseURL = process.env.VUE_APP_API_URL_XJR;
//请求拦截
axios.interceptors.request.use(
  (config) => {
    const token = store.state.user.token;
    let Wid_Org_Id = store.state.user.orgId;
    if (token) {
      config.headers.Authorization = token || ""; //每次发送请求之前自动将在session中的token提取出来当做响应头header
      config.headers["Wid-Org-Id"] = Wid_Org_Id || "";
    }
    //? 开启加载中
    if (config.showLoading) {
      showFullScreenLoading(config.showLoading);
    }
    return config;
  },
  (err) => {
    console.log(err);
  }
);

//返回拦截
axios.interceptors.response.use(
  (response) => {
    if (response.config.showLoading) {
      tryHideFullScreenLoading();
    }
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    console.log(error.response,232323);
    tryHideFullScreenLoading();
    if (error.response.status === 401){
      if (error.response.data.code == 1019){
        messageOnce.error({
          message: "你的账号异地登录，请重新登录",
          type: "error",
          duration: 5 * 1000,
        });
      }

      store.commit("user/SET_USERID", "");
      store.commit("user/SET_TOKEN", "");
      store.commit("user/SET_REALNAME", "");
      store.commit("user/SET_ROLES", "");
      store.commit("user/SET_USERNAME", "");
      store.commit("permission/SET_ROUTES", []);
      store.commit("user/SET_PERMISSIONS", []);
      store.commit("user/SET_MENU", []);
      store.commit("user/SET_ORGLIST", []);
      store.commit("user/SET_ORGID", undefined);
      store.commit("user/SET_PERMISSIONMAP", {});
      // token过期，返回到登录页面，重新登录
      router.replace({ path: "/login" })

      return Promise.reject(error.response);
    }
    if (error.response.status == 403) {
      // 没有权限返回首页
      // router.replace({ path: "/dashboard" });
    }if(error.response.status == 404){
      if(error.response.config.url=="/sys/ui/table/aleer-event-list"||error.response.config.url=="/sys/ui/summary/alert-event-summary"){
        return Promise.reject(error.response);
      }
    }
    if (error.response.config.url.indexOf("schedule/plan/takeOver")>=0){
      if (error.response.data.message.indexOf("not found")>=0){
        messageOnce.error({
          message: "当前用户暂无交接班",
          type: "info",
          duration: 5 * 1000,
        });
      }
    }else{
      messageOnce.error({
        message: "操作失败：" + error.response.data.message,
        type: "error",
        duration: 5 * 1000,
      });
    }

    return Promise.reject(error.response);
  }
);

export default axios;
