import {getVectorContext} from "ol/render";

const getters = {
    sidebar: (state) => state.app.sidebar,
    device: (state) => state.app.device,
    token: (state) => state.user.token,
    avatar: (state) => state.user.avatar,
    name: (state) => state.user.name,
    // 当前选中的节点
    currentNode: (state, getters) => {
        let data = getters.currentUnitData;
        if (
            Object.keys(data).length &&
            state.taskManange.currentContentIndex != undefined
        ) {
            return data.patrolRecordNodeDtos[state.taskManange.currentContentIndex]
                .nodeName;
        }
        return null;
    },
    // 当前选中节点的数据
    currentNodeData: (state, getters) => {
        // let data = getters.currentUnitData;
        // if (Object.keys(data).length) {
        //   return data.patrolRecordNodeDtos[state.taskManange.currentContentIndex];
        // }
        // return {};
        if (!state.taskManange.nodeList.length) {
            return {};
        }
        let data = state.taskManange.nodeList[state.taskManange.currentNodeIndex];
        if (
            Object.keys(data).length &&
            state.taskManange.currentContentIndex != undefined
        ) {
            return data.patrolRecordNodeDtos[state.taskManange.currentContentIndex];
        }
        return {};
    },
    // 当前选中的巡检单元的数据-----巡检单元位置数据
    currentUnitData: (state, getters) => {
        if (!state.taskManange.nodeList.length) {
            return {};
        }
        let data = state.taskManange.nodeList[state.taskManange.currentNodeIndex];
        // 查看任务时，根据状态晒选出数据
        // let isExcute = state.taskManange.isExcute;
        // if (!isExcute) {
        //   // 查看任务时过滤当前巡检单元里巡检点数据
        //   let nodeState = state.taskManange.nodeState;
        //   if (nodeState) {
        //     // 筛选出当前巡检单元异常的巡检点
        //   }
        // }
        if (data) {
            return data;
        }
        return {};
    },
    // 当前选中单元节点数量
    currentUnitLength: (state, getters) => {
        let data = getters.currentUnitData;
        if (data) {
            return data.patrolRecordNodeDtos.length;
        }
        return 0;
    },
    // 当前选中巡检单元节点对应的内容
    content: (state, getters) => {
        let currentUnitData = getters.currentUnitData;
        let currentContentIndex = state.taskManange.currentContentIndex;
        if (currentContentIndex == undefined) {
            return [];
        }
        if (Object.keys(currentUnitData).length) {
            let data = currentUnitData.patrolRecordNodeDtos;
            if (data.length) {
                return data[currentContentIndex].patrolRecordContentDtos;
            }
        }
        return [];
    },
    // 当前节点对应的内容完成项
    currentCompleted: (state, getters) => {
        let content = getters.content;
        let index = 0;
        if (content.length) {
            content.forEach((item) => {
                if (item.abnormalStatus != null) {
                    index++;
                }
            });
            return index;
        }
    },
    // 下一步按钮的使能状态
    nextDisabled: (state, getters) => {
        console.log(getters.content, 11111);
        if (getters.content.length == 0) {
            return false;
        }
        if (getters.content.length == getters.currentCompleted) {
            return false;
        } else {
            return true;
        }
    },
    // 当前的permission
    currentPermission: (state, getters) => {
        let orgId = state.user.orgId;
        console.log(123, orgId);
        if (orgId != "") {
            return state.user.orgPermissionMap[orgId];
        } else {
            return [];
        }
    },


    /*无人巡检 v2*/

    // 当前正在巡检的单元位置数据
    currentUnitDataV2: (state, getters) => {
        // 如果无巡检数据，则返回空对象
        if (!state.taskRecord.pollingList.length) {
            return {}
        }
        let unitData = state.taskRecord.pollingList[state.taskRecord.currentUnitIndex];
        if (unitData) {
            return unitData;
        }
        return {}
    },
    // 当前巡检单元的摄像头数量
    currentUnitCameraLength: (state, getters) => {
        let data = getters.currentUnitDataV2;
        if (Object.keys(data).length !== 0) {
            return data.channelList.length;
        }
        return 0;
    },
    // 当前正在巡检的摄像头数据
    currentCameraData: (state, getters) => {

        if (!state.taskRecord.pollingList.length) {
            return {};
        }
        let data = state.taskRecord.pollingList[state.taskRecord.currentUnitIndex];
        if (
            Object.keys(data).length &&
            state.taskRecord.cameraIndex != undefined
        ) {
            return data.channelList[state.taskRecord.cameraIndex];
        }
        return {};
    },




    // 当前正在巡检的摄像头对应的巡检项数据
    currentContentData:(state, getters)=>{

        let currentUnitData = getters.currentUnitDataV2;
        let currentContentIndex = state.taskRecord.cameraIndex;
        if (currentContentIndex == undefined) {
            return [];
        }
        if (Object.keys(currentUnitData).length) {
            let data = currentUnitData.channelList;
            if (data.length) {
                return data[currentContentIndex].recordVideoDtoList;
            }
        }
        return [];
    },
    // 当前正在巡检的摄像头对应的巡检项数据的长度
    currentContentDataLength:(state, getters)=>{
        if(getters.currentContentData.length){
            return getters.currentContentData.length;
        }else{
            return 0
        }
    },
    // 当前正在巡检的摄像头对应的巡检项完成的下标
    currentContentCompletedIndex:(state, getters)=>{
        let content = getters.currentContentData;
        let index = 0;
        if (content.length) {
            content.forEach((item) => {
                if (item.abnormalStatus != null) {
                    index++;
                }
            });
            return index;
        }
        return index;
    },
    // 下一步按钮的使能状态
    nextDisabledV2: (state, getters) => {
        if (getters.currentContentData.length === 0) {
            return false;
        }
        if (getters.currentContentData.length === getters.currentContentCompletedIndex) {
            return false; // 下一步能点击
        } else {
            return true; // 下一步不能点击
        }
    },
};
export default getters;
