<template>
  <!-- <el-row class="index">
    <el-col :span="4"> <LeftMenu /></el-col>
    <el-col :span="20"> <RightMain /></el-col>
  </el-row> -->
  <div class="index">
    <Header />
    <div class="index-wrap">
      <LeftMenu />
      <RightMain />
    </div>
    <div class="copyright">{{$store.state.app.copyrightLogo}}</div>
    <img :src="bottomFilePath" alt="" class="fixedlogo" />
  </div>
</template>

<script>
import store from "@/store";

const version = require("element-ui/package.json").version; // element-ui version from node_modules
const ORIGINAL_THEME = "#409EFF"; // default color
import Header from "./moudle/Header";
import LeftMenu from "./moudle/LeftMenu";
import RightMain from "./moudle/RightMain";
import {mapState} from "vuex";
import ConfigMixin from "@/mixin/configMixin.js";
import router from "@/router";
const imgPrefix = process.env.VUE_APP_LOGO_URP; // LOGO 前缀

export default {
  components: {
    Header,
    LeftMenu,
    RightMain,
  },
  computed: {
    // defaultTheme() {
    //   return this.$store.state.theme;
    // },
    ...mapState({
      theme: (state) => state.app.theme,
      logPath: (state) => state.app.logoFilePath,
      refreshTokenTime: (state) => state.user.refreshTokenTime,
      tokenId:(state) => state.user.tokenId,
      bottomFilePath:(state) => {
        return imgPrefix +state.app.bottomFilePath
      },
    }),
    /*mqtt实例*/
    client() {
      return this.$store.state.app.client;
    },
    /*mqtt信息*/
    mqttMessage() {
      return this.$store.state.app.mqttMessage;
    }
  },
  watch: {
    mqttMessage(newV) {
      let topic = newV.topic;
      let res = JSON.parse(this.Utf8ArrayToStr(newV.data));
      if (topic.indexOf("wid/userLogin/") >= 0 ) {
        this.$alert('你的账号在异地登录', '提示', {
          confirmButtonText: '确定',
          callback: action => {
            this.$store.commit("user/SET_USERID", "");
            this.$store.commit("user/SET_TOKEN", "");
            this.$store.commit("user/SET_REALNAME", "");
            this.$store.commit("user/SET_ROLES", "");
            this.$store.commit("user/SET_USERNAME", "");
            this.$store.commit("permission/SET_ROUTES", []);
            this.$store.commit("user/SET_PERMISSIONS", []);
            this.$store.commit("user/SET_MENU", []);
            this.$store.commit("user/SET_ORGLIST", []);
            this.$store.commit("user/SET_ORGID", undefined);
            this.$store.commit("user/SET_PERMISSIONMAP", {});
            // token过期，返回到登录页面，重新登录
            this.$router.replace({ path: "/login" })
          }
        });
      }
    },
  },
  mixins: [ConfigMixin],
  methods: {
    //换皮肤 防止F5后皮肤丢失
    async handleUserTheme() {
      let val = this.theme;
      const oldVal = this.chalk ? this.theme : ORIGINAL_THEME;
      if (typeof val !== "string") return;
      const themeCluster = this.getThemeCluster(val.replace("#", ""));
      const originalCluster = this.getThemeCluster(oldVal.replace("#", ""));
      const getHandler = (variable, id) => {
        return () => {
          const originalCluster = this.getThemeCluster(
              ORIGINAL_THEME.replace("#", "")
          );
          const newStyle = this.updateStyle(
              this[variable],
              originalCluster,
              themeCluster
          );

          let styleTag = document.getElementById(id);
          if (!styleTag) {
            styleTag = document.createElement("style");
            styleTag.setAttribute("id", id);
            document.head.appendChild(styleTag);
          }
          styleTag.innerText = newStyle;
        };
      };

      if (!this.chalk) {
        const url = `https://unpkg.com/element-ui@${version}/lib/theme-chalk/index.css`;
        await this.getCSSString(url, "chalk");
      }

      const chalkHandler = getHandler("chalk", "chalk-style");

      chalkHandler();

      const styles = [].slice
          .call(document.querySelectorAll("style"))
          .filter((style) => {
            const text = style.innerText;
            return (
                new RegExp(oldVal, "i").test(text) && !/Chalk Variables/.test(text)
            );
          });
      styles.forEach((style) => {
        const {innerText} = style;
        if (typeof innerText !== "string") return;
        style.innerText = this.updateStyle(
            innerText,
            originalCluster,
            themeCluster
        );
      });
    },

    getThemeCluster(theme) {
      const tintColor = (color, tint) => {
        let red = parseInt(color.slice(0, 2), 16);
        let green = parseInt(color.slice(2, 4), 16);
        let blue = parseInt(color.slice(4, 6), 16);

        if (tint === 0) {
          // when primary color is in its rgb space
          return [red, green, blue].join(",");
        } else {
          red += Math.round(tint * (255 - red));
          green += Math.round(tint * (255 - green));
          blue += Math.round(tint * (255 - blue));

          red = red.toString(16);
          green = green.toString(16);
          blue = blue.toString(16);

          return `#${red}${green}${blue}`;
        }
      };

      const shadeColor = (color, shade) => {
        let red = parseInt(color.slice(0, 2), 16);
        let green = parseInt(color.slice(2, 4), 16);
        let blue = parseInt(color.slice(4, 6), 16);

        red = Math.round((1 - shade) * red);
        green = Math.round((1 - shade) * green);
        blue = Math.round((1 - shade) * blue);

        red = red.toString(16);
        green = green.toString(16);
        blue = blue.toString(16);

        return `#${red}${green}${blue}`;
      };

      const clusters = [theme];
      for (let i = 0; i <= 9; i++) {
        clusters.push(tintColor(theme, Number((i / 10).toFixed(2))));
      }
      clusters.push(shadeColor(theme, 0.1));
      return clusters;
    },

    updateStyle(style, oldCluster, newCluster) {
      let newStyle = style;
      oldCluster.forEach((color, index) => {
        newStyle = newStyle.replace(new RegExp(color, "ig"), newCluster[index]);
      });
      return newStyle;
    },

    getCSSString(url, variable) {
      return new Promise((resolve) => {
        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            this[variable] = xhr.responseText.replace(/@font-face{[^}]+}/, "");
            resolve();
          }
        };
        xhr.open("GET", url);
        xhr.send();
      });
    },
    // 刷新token
    refreshToken(timeStr) {
      if (this.dayjs(timeStr).unix() > this.dayjs(this.refreshTokenTime).unix()) {
        this.$api.refreshToken().then(res => {
          let data = res.data
          let token = data.jwtTokenDto?.token;
          let refreshToken = data.jwtTokenDto?.refreshTime
          this.$store.commit("user/SET_TOKEN",token)
          this.$store.commit("user/SET_REFRESHTOKENTIME",refreshToken)
        })
      }
    },
    Utf8ArrayToStr(array) {
      var out, i, len, c;
      var char2, char3;

      out = "";
      len = array.length;
      i = 0;
      while (i < len) {
        c = array[i++];
        switch (c >> 4) {
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
          case 7:
            // 0xxxxxxx
            out += String.fromCharCode(c);
            break;
          case 12:
          case 13:
            // 110x xxxx 10xx xxxx
            char2 = array[i++];
            out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f));
            break;
          case 14:
            // 1110 xxxx 10xx xxxx 10xx xxxx
            char2 = array[i++];
            char3 = array[i++];
            out += String.fromCharCode(
                ((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0)
            );
            break;
        }
      }

      return out;
    },
  },
  mounted(handle) {
    this.handleUserTheme();
    this.dynamicChangeFavcion(process.env.VUE_APP_LOGO_URP + this.logPath);
    this.refreshTokenTimeout = setInterval(() => {
      this.refreshToken(this.dayjs())
    }, 1000*60*60)
    /*订阅单终端登录*/
    this.client.subscribe('wid/userLogin/' + this.tokenId);
  },
  destroyed() {
    clearInterval(this.refreshTokenTimeout)
    this.refreshTokenTimeout = null;
    /*取消订阅单终端登录*/
    this.client.unsubscribe('wid/userLogin/' + this.tokenId);
  }
};
</script>

<style lang="less" scoped>
.index {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .header {
    height: 64px;
  }

  .index-wrap {
    width: 100%;
    height: calc(100% - 64px);
    display: flex;

    .right-main {
      flex: 1;
    }
  }

  overflow-x: hidden;

  position: relative;

  .copyright {
    font-size: 0.5em;
    text-align: center;
    position: absolute;
    bottom: 1%;
    left: 0;
    right: 0;
    text-shadow: 2px 2px 2px #ccc;
  }

  .fixedlogo {
    position: absolute;
    bottom: 1%;
    right: 1%;
    width: 100px;
  }
}
</style>
