import axios from "../axios";
import qs from "qs";
const onlinemodelApi = {
    //查询所有地图
    getRegistryMapInter(params){
        return axios.get('/wm/gismap/map',{ params });
    },
    //注册地图
    registryMapInter(params){
        return axios.put('/wm/gismap',params);
    },
    //注销地图
    cancellationMapInter(id){
        return axios.delete('/wm/gismap/'+id);
    },
    //查询注册地图
    getRegistryedMapInter(){
        return axios.get('/wm/gismap');
    },
    //模型列表
    waterModelListInter(params){
        return axios.get('/wm/list',{ params });
    },
    //新增模型
    addModelInter(params){
        return axios.post('/wm/swmm',params);
    },
    //删除并停用模型
    deleteModelInter(id){
        return axios.delete('/wm/'+id);
    },
    //获取文件流
    getFileFlowInter(url){
        return axios.get(url)
    },
    //编辑模型
    editModelInter(params){
        return axios.put("/wm/swmm",params)
    },
    //获取模型配置内容
    getModelContentInter(params){
        return axios.get("/wm/"+params.modelId+"/config/"+params.configName+"/content");
    },
    //查看模型信息关联地图的图层信息
    getModelLayers(modelId){
        return axios.get("/wm/"+modelId+"/gis/map/layers")
    },
    getLayerRelationInter(modelId){
        return axios.get("/wm/"+modelId+"/swmm/gisLayerRelation")
    },
    //添加、修改swmm模型与图层关系
    addModelRelationInter(modelId,params){
        return axios.put("/wm/"+modelId+"/swmm/gisLayerRelation",params)
    },
    //查询swmm模型的输出的最近预测时序数据
    getLatestSwmmDataInter(modelId,params){
        return axios.post('/wm/'+modelId+'/swmm/output/latest/ts',params,{
            headers: {
              "Content-Type": "application/json",
              "Accept":"text/csv"
            },
            showLoading: true,
        });
    },
    //查询最新的swmm模型的输出信息
    getLatestOutputInter(modelId){
        return axios.get("/wm/"+modelId+"/swmm/output/latest")
    },
    //查询swmm输出的时序数据
    getOutputTimeDataInter(modelId,outputId,params){
        return axios.post('/wm/'+modelId+'/swmm/output/'+outputId+'/ts',params,{
            headers: {
              "Content-Type": "application/json",
              "Accept":"text/csv"
            },
            showLoading: false,
        })
    },
    addSwmmbindInter(params){
        return axios.post('/wm/swmm/bind',params)
    },
    //模型与scada绑定列表
    swmmModelList(params){
        return axios.get('/wm/swmm/bind/list',{params})
    },
    //查询模型的图例配置信息
    getModelLegendInter(modelId,params){
        return axios.get('/wm/'+modelId+'/swmm/legend',{params})
    },
    //编辑图例配置
    editeModellegendInter(modelId,params){
        return axios.put('/wm/'+modelId+'/swmm/legend',params)
    },
    //编辑组合
    editGroupInter(params){
        return axios.put('/wm/swmm/bind',params)
    },
    //删除组合
    deleteGroupInter(id){
        return axios.delete('/wm/swmm/bind/'+id)
    },
    //获取关联指标信息
    getRelatedScadaInter(params){
        return axios.get('/wm/swmm/bind/relatedMetrics',{params})
    },
    //获取模型回算数据
    getModelBackcalInter(id,params){
        return axios.post('/wm/'+id+'/swmm/queryBackcal',params,{
            headers: {
                "Content-Type": "application/json"
            },
            showLoading: false,
        })
    },
    //查询路径
    findPathInter(modelId,params){
        return axios.get('/wm/'+modelId+'/swmm/profile/findPath',{params})
    },
    //保存路径
    savePathInter(modelId,params){
        return axios.post('/wm/'+modelId+'/swmm/profile', params )
    },
    //剖面路径
    getProfileList(params){
        return axios.get('/wm/swmm/profile/list',{params})
    },
    //根据剖面列表查剖面路径
    getpathById(modelId,profileId){
        return axios.get('/wm/'+modelId+'/swmm/profile/'+profileId)
    },
    //删除剖面路径信息
    deleteProfile(modelId,profileId){
        return axios.delete('/wm/'+modelId+'/swmm/profile/'+profileId)
    },
    //修改剖面序号
    changeProfileIndex(params){
        return axios.put('/wm/swmm/profile/order',params)
    },
    // //查询模型回算时序数据
    // queryModelBack(modelId,params){
    //     return axios.post('/wm/'+modelId+'/swmm/queryBackcal',params,{
    //         headers: {
    //             "Content-Type": "application/json"
    //         },
    //     })
    // }
    //获取关联的指标信息,没有指标信息的站点会被过滤
    getBindswmmScadaInter(params){
        return axios.get('/wm/swmm/bind/bindTree',{params})
    },
    //修改绑定信息
    editIndicatorsInter(params){
        return axios.put('/wm/swmm/bind',params)
    },
    //删除指标
    deleteIndicatorsInter(bindId){
        return axios.delete('/wm/swmm/bind/'+bindId)
    },
    //批量查询绑定关系关联的时序数据
    multiQueryInter(params){
        return axios.post('/wm/swmm/bind/ts/multiQuery',params)
    },
    //数据组列表
    getDataGroupInter(params){
        return axios.get('/wm/swmm/group/list',{params})
    },
    //添加数据组
    addGroupInter(params){
        return axios.post('/wm/swmm/group',params)
    },
    //查询单个组信息
    getGroupDetailInter(groupId){
        return axios.get("/wm/swmm/group/"+groupId)
    }
};
export default onlinemodelApi;