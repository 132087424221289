import axios from "./axios";
import qs from "qs";
const locationApi = {
  locationGetPeopleApi(params){
    return axios.get("/gis/locationManagement/userInfo/list",{ params });
  },
  locationLatestOnlinePeople(params){
    return axios.get("/gis/locationManagement/userLatestLocation/geojson",{params})
  },
  locationHistoryForPeople(params){
    return axios.get("/gis/locationManagement/userLocationHistory/geojson",{params})
  }
};
export default locationApi;
