// import Cookies from "js-cookie";
import defaultSettings from "@/settings";
const state = {
  menuStatus: defaultSettings.menuStatus,
  theme: defaultSettings.theme,
  systemName: defaultSettings.systemName,
  systemLogo: defaultSettings.systemLogo,
  systemDesc: undefined,
  logoFilePath: undefined, // 系统logo
  coreFoldFilePath: undefined, // 菜单折叠logo
  coreOpenFilePath: undefined, // 菜单展开logo
  mqttMessage:null,
  client:null,
  loginPath:"http://192.168.1.211:80",// 中台默认的访问地址
  copyrightLogo:undefined,  /*版权*/
  bottomFilePath:undefined, /*右下角logo*/
  unifiedLoginUrl: undefined, /*统一登录页访问地址*/
  siteUrls:{}, /*所有子系统的访问地址*/
  xjrUrl: undefined,
  patrolLineModelUrl:undefined,
  ownerName:undefined
};

const mutations = {
  changeMenuStatus(state) {
    state.menuStatus = !state.menuStatus;
  },
  changeTheme(state, chooseTheme) {
    state.theme = chooseTheme;
  },
  changeSysName(state, name) {
    state.systemName = name;
  },
  changeSysLogo(state, logo) {
    state.systemLogo = logo;
  },
  changeSystemDesc(state, data) {
    state.systemDesc = data;
  },
  changeLogoFilePath(state, data) {
    state.logoFilePath = data;
  },
  changeCoreFoldFilePath(state, data) {
    state.coreFoldFilePath = data;
  },
  changeCoreOpenFilePath(state, data) {
    state.coreOpenFilePath = data;
  },
  setMqttMessage(state,data){
    state.mqttMessage = data;
  },
  setClient(state,data){
    state.client = data;
  },
  SET_LOGINPATH(state,data){
    state.loginPath = data;
  },
  SET_COPYRIGHT(state,data){
    state.copyrightLogo = data;
  },
  SET_BOTTOMFILPATH(state,data){
    state.bottomFilePath = data;
  },
  SET_UNIFIEDLOGIN(state,data){
    state.unifiedLoginUrl = data;
  },
  SET_siteUrls(state,data){
    state.siteUrls = data;
  },
  SET_XJRURL(state,data){
    state.xjrUrl = data;
  },
  SET_patrolLineModelUrl(state,data){
    state.patrolLineModelUrl = data;
  },
  SET_ownerName(state,data){
    state.ownerName = data;
  }

};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
