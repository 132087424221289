<template>
    <div class="report-render-wrap">
      <div class="header-wrap" id="printMe">

        <table class="html-table" ref="tableExcel" style="width: 100%"  cellpadding="3" cellspacing="0">
          <colgroup >
                      <col v-for="(item,idx) in colArray" :key="idx+'tHeader'" :style="{'width': 120+'px'}" />
                       <col />
          </colgroup>
          <tr v-for="(item,idx) in tableData" :key="idx" v-if="item.ifHeadRow ">
            <td v-for="obj in item.cols" :colspan="obj.span" :style="{'max-width':obj.colWidth+'px',textAlign:'center'}">
              {{ obj.value }}
            </td>
          </tr>
        </table>
      </div>
      <div class="tbody-wrap" style="page-break-after:always;">
        <table style="width: 100%"  class="html-table" ref="tableExcel"   cellpadding="3" cellspacing="0">
          <colgroup >
            <col v-for="(item,idx) in colArray" :key="idx+'tHeader'" :style="{'width': 120+'px'}" />
            <col />
          </colgroup>
          <tr v-for="(item,idx) in tableData" :key="idx" v-if="!item.ifHeadRow">
            <td v-for="obj in item.cols" :colspan="obj.span" :style="{'max-width':obj.colWidth+'px',textAlign:'center'}">
<!--             <div :title="obj.value" style="overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">{{ obj.value }}</div>-->
              <div :title="obj.value" >{{ obj.value }}</div>
            </td>
          </tr>
        </table>
      </div>
    </div>
</template>

<script>
export default {
  name: "reportRender",
  props:{
    tableData:{
      type:Array,
      default:()=>[]
    }
  },
  data(){
    return {
      tHeader:[],
      tBody:[],
      colArray:[]
    }
  },
  watch:{
    tableData(newVal){
      this.parseData(newVal)
    }
  },
  mounted() {
    this.parseData(this.tableData)
    console.log(this.colArray,123)
  },
  methods:{
    parseData(tableData){
      let tHeader = []
      let tBody = []
      console.log(tableData,11212)
      tableData.forEach(item=>{
        if (item.ifHeadRow){
          tHeader.push(item)
        }else {
          tBody.push(item)
        }
      })

      console.log(this.tBody,this.tHeader)


     this.tHeader = tHeader
      this.tBody = tBody
      if (!this.tableData.length){
        this.colArray =[]
      }else{
        this.colArray = this.tBody[0].cols
      }


    }
  }
}
</script>

<style scoped lang="scss">
.report-render-wrap{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .header-wrap{
    width: 100%;
    td{
      background: var(--themeColor) !important ;
      color:#ebeef5;
      font-weight: bold;
    }
  }
  .tbody-wrap{
    width: 100%;
    display: flex;
    flex: 1;
    //overflow: auto;
    &::-webkit-scrollbar { width: 0 !important }
    & tr:hover{
      background: var(--themeColor) !important ;
      color:#ebeef5;
      cursor: pointer;
    }
  }
  .html-table{
    font-size: 14px;
    & td{
      padding: 12px 0;
      min-width: 0;
      box-sizing: border-box;
      text-overflow: ellipsis;
      vertical-align: middle;
      position: relative;
      text-align: left;
      border-bottom: 1px solid #ebeef5;
      border-right: 1px solid #ebeef5;
    }
  }
}
</style>
