import axios from "../axios";
import qs from "qs";
const fileApi = {
    creFile(params){
      //上传文件
        return axios.post('/file/upload',params)
    },
    getFile(params){
        return axios.get("/file/static/"+params,{
            responseType: 'blob'
        })
    }
};
export default fileApi;
