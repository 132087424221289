import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./permission";
import "../src/assets/css/icon.css";

/*引入Splitpanes*/
import {Splitpanes, Pane} from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'


/*全局注册组件*/
Vue.component('Splitpanes', Splitpanes)
Vue.component('Pane', Pane)

import local from "@/util/local";
import "bootstrap";
import moment from "moment";
import drainageApi from "../src/apis/drainageApiUitl";
import _ from "lodash";
import db from "./apis/indexedDB";

import '@/util/dialogDrag.js';

Vue.prototype.moment = moment;
Vue.prototype._ = _;
Vue.use(drainageApi);
Vue.config.productionTip = false;
Vue.config.productionTip = false;
Vue.config.productionTip = false;
Vue.config.productionTip = false;
//! 全局注册组件
import "./util/CmptRegist";

//! 使用element
import ElementUI from "element-ui";
import "./assets/css/element-variables.scss";
// import "element-ui/lib/theme-chalk/index.css";

/*
* 引入iconfont
* */
import "./assets/font/iconfont.css";


import Avue from '@smallwei/avue';
import '@smallwei/avue/lib/index.css';

Vue.use(Avue);


Vue.use(ElementUI, {size: "small"});

//!全局注册Api
import Api from "./apis/apis";

import request from "@/apis/axios";


Vue.prototype.$api = Api;
Vue.prototype.$request = request;

import directives from "@/util/directives";

Vue.use(directives)

// 全局注册dayjs
let utc = require('dayjs/plugin/utc')

import dayjs from "dayjs";

dayjs.extend(utc)
Vue.prototype.dayjs = dayjs;

//! 注册cookie
import VueCookies from "vue-cookies";

Vue.use(VueCookies);

//! 换色
import "@/assets/css/index.css";

import {Message} from "element-ui";


/*打印*/
import Print from 'vue-print-nb'
// Global instruction
Vue.use(Print);

import VuePrinter from 'vue-dom-printer'


/*
* vue-cesium
* 相关配置
* */


Vue.use(VuePrinter)


// import "xe-utils";
// import VXETable from "vxe-table";
// // import "@/assets/css/vex-variables.scss";
// import "vxe-table/lib/style.css";
// vex 主题样式

// Vue.use(VXETable);

//! 对请求结果进行判断
Vue.prototype.$MyPrompt = function (status) {
    if (status >= 200 && status < 300) {
        //弹出成功框
        Message({
            type: "success",
            message: "操作成功",
        });
    } else {
        Message.error("操作失败");
    }
};

Vue.prototype.$judge = function (obj) {
    let roles = store.state.user.roles;


    // 过滤属于该系统的权限
    roles = roles.filter(item => {
        return item.subSystem == "ZT"
    })
    if (roles) {
        let rolesstr = JSON.stringify(roles);
        let objstr = JSON.stringify(obj);
        let adminRoleArray = _.filter(roles, (item) => {
            return item.resType == "**" && item.resOpt == "**"
        })
        if (
            adminRoleArray.length > 0
        ) {
            return 1;
        }
        let hasPrsArray = _.filter(roles, (item) => {
            return item.resType == obj.resType && item.resOpt == obj.resOpt
        })
        if (hasPrsArray.length > 0) {
            return 1;
        } else {
            return 0;
        }
    }
};
import {confirm} from "@/util/commonUtils";

Vue.prototype.confirm = confirm;

//? 引用echarts
// import Echarts from "echarts";
// Vue.prototype.$echarts = Echarts;

// import wichart from "./components/WiChart";
// Vue.component("wi-chart", wichart);
// function startup(){
//   new Vue({
//     router,
//     store,
//     render: h => h(App),
//   }).$mount('#app')
// }
// XE.ready().then(startup);
// new Vue({
//   router,
//   store,
//   render: (h) => h(App),
// }).$mount("#app");
XE.ready().then(function startup() {
    new Vue({
        router,
        store,
        render: h => h(App),
    }).$mount('#app')
});
