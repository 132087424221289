<template>
  <div class="vue-cesium-box" >
    <vc-viewer @ready="ready">
      <vc-layer-imagery>
        <vc-layer-imagery>
<!--          <vc-provider-imagery-tianditu map-style="img_c" token="436ce7e50d27eede2f2929307e6b33c0"></vc-provider-imagery-tianditu>-->
        </vc-layer-imagery>
      </vc-layer-imagery>
    </vc-viewer>
  </div>
</template>

<script>
export default {
  name: "vueCesium",
  props: {
    modelurl:{
      type: String,
      default: '',
    }
  },
  data(){
    return {

    }
  },
  mounted() {

  },
  methods: {
    ready(cesiumInstance){
      this.cesiumInstance = cesiumInstance;
      const {Cesium,viewer}= cesiumInstance;
      if (this.$listeners['getInstance']){
        this.$emit(getInstance,cesiumInstance);
      }

      var tileset = viewer.scene.primitives.add(
          new Cesium.Cesium3DTileset({
            url: !!this.modelurl?this.modelurl:'3dmodel/factory/tileset.json',
            modelMatrix: Cesium.Matrix4.fromArray([0.4822268369316778,-0.5915523040692681,0.6461603123786004,0,0.3514576279073688,0.8062631670420253,0.47583320739213686,0,-0.8024554900607229,-0.0023615718712978184,0.5967073063485155,0,-20398.81225124467,-2843.336559048854,-6351.084889937192,1]),
          })
      );
      tileset.readyPromise
          .then(function() {
            viewer.flyTo(tileset);
          })
          .otherwise(function(error) {
            throw error
          });
      viewer._cesiumWidget._creditContainer.style.display = "none";// 隐藏 LOGO 版权
    }
  },
}
</script>

<style scoped lang="scss">
.vue-cesium-box{
  //width: 100%;
  height: 600px;
}
</style>
