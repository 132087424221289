import axios from "../axios";
import qs from "qs";
const layerApi = {
  getscenceTree(url){
    return axios.get(url+"/scenetree.json")
  },
  getDigitalTwinLayer(modelId,params){
    return axios.put("/digitalTwin/configDoc/"+modelId+"/scencelayer",params)
  },
  getscenceLayerApi(modelId){
    return axios.get("/digitalTwin/configDoc/"+modelId+"/scencelayer")
  }
};
export default layerApi;
