import axios from "../axios";
import qs from "qs";
const uploadApi = {
    uploadLayer(params){
        return axios.post('/gis/layer/upload',params)
    },
    /**
     *
     * @return {Promise<AxiosResponse<DmaImportResultDto>>}
     */
    uploadshp(params){
        return axios.post("/dma/shapeFile/import",params)
    }
}
export default uploadApi;
