<template>
    <div class="layercontrol" :style="{'background':isExpand?'#409EFF':'#c0bfbf'}">
        <i class="drainage drainagetuceng1"  @click="expandLayer" style="fontSize:20px;"></i>
        <transition name="el-zoom-in-top">
            <div class="btncon" v-if="isExpand">
                <div class="layer_list" v-for="(val,index) in layerData" :key="index">
                    <span class="list_label">{{val.label}}</span>
                    <el-switch v-model="val.isopen" active-color="#409EFF" inactive-color="#808080" @change="showLayer($event, val)"></el-switch>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    data(){
        return {
            isExpand:false,
            layerData:[],
            layerDataDefault:[
                {
                    label:"地图",
                    id:"tdrodenet",
                    isopen:false
                },{
                    label:"地形",
                    id:"terrain",
                    isopen:false
                },{
                    label:"影像",
                    id:"YX",
                    isopen:false
                },{
                    label:"矢量",
                    id:"vector",
                    isopen:true
                }
            ]
        }
    },
    props:['map','layerDeatil'],
    created () {
         !!this.layerDeatil? this.layerData = this.layerDeatil:this.layerData = this.layerDataDefault;
    },
    mounted(){
        setTimeout(()=>{
            this.initLayer();
        })
    },
    methods:{
        expandLayer(){
            this.isExpand = !this.isExpand;
        },
        initLayer(){
            this.layerData.forEach(val=>{
                this.showLayer(val.isopen,val);
            })
        },
        showLayer(e,data){
           let layer = this.getLayerById(this.map,data.id);
           layer.setVisible(e);
           this.map.getView().changed();
        },
        getLayerById(mymap, layerId) {
            var mylayer = null;
            var layers = mymap.getLayers();
            for(var i=0;i<layers.getLength();i++){
                var layer = layers.item(i);
                if(layer.get("id")==layerId){
                    mylayer = layer;
                    break;
                }
            }
            return mylayer;
        },
    }
}
</script>

<style scoped>
    .layercontrol{
        width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        break-after: avoid;
        border-radius: 5px;
        position: absolute;
        top: 2vh;
        right: 2vh;
        z-index: 1;
    }
    .layercontrol:hover {
        cursor: pointer;
    }
    .tuceng{
        font-size: 1.2vw;
    }
    .btncon{
        width: 150px;
        max-height: 240px;
        overflow: auto;
        background: rgba(255,255,255,.8);
        position: absolute;
        right: 40px;
        top: 0px;
        border-radius: 5px;
        padding: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    }
    .layer_list{
        width: 100%;
        height: 30px;
        /* color: #fff; */
    }
    .list_label{
        display: inline-block;
        width: 40%;
    }
    .layercontrol /deep/ .el-switch__core{
        height:17px;
        width: 36px;
    }
    .layercontrol /deep/ .el-switch__core:after{
        width: 13px;
        height: 13px;
    }
</style>