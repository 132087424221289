import router from "./router";
import store from "./store";
import { Message } from "element-ui";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import getPageTitle from "@/util/get-page-title";
import local from "@/util/local";
let timer = undefined;
NProgress.configure({ showSpinner: false }); // NProgress Configuration
// 路由白名单，不需要token验证和权限验证
const whiteList = ["/login"]; // no redirect whitelist
router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start();
  // set page title
  // document.title = getPageTitle(to.meta.title);
  document.title = store.state.app.systemName;
  let vuexStorage = local.get("vuexStorage", "sessionStorage");

  let token = vuexStorage.user.token;
  let addRoutes = store.state.permission.addRoutes;
  const hasToken = token ? true : false; //判断是否登陆
  // 有token情况（token没有过期）
  if (hasToken) {
    if (to.path === "/login") {
      // if is logged in, redirect to the home page
      next({ path: "/" });
      NProgress.done();
    } else {
      // 如果有角色权限
      // store.state.user.roles.length;
      if (addRoutes.length) {
        // if is logged in, redirect to the home page
        next();
        NProgress.done();
      } else {
        console.log("加载权限");
        // 没有权限
        try {
          const accessRoutes = await store.dispatch(
            "permission/generateRoutes"
          );
          // 动态的生成路由
          router.addRoutes(accessRoutes);
          let routes = store.state.permission.routes;
          timer = setTimeout(() => {
            store.commit("user/SET_MENU", routes);
            next({ ...to, replace: true });
          }, 0);
        } catch (error) {
          console.log(error, 123334);
          $cookies.remove("token"); // 清楚cookies
          store.commit("user/SET_USERID", "");
          store.commit("user/SET_TOKEN", "");
          store.commit("user/SET_REALNAME", "");
          store.commit("user/SET_ROLES", "");
          store.commit("user/SET_USERNAME", "");
          store.commit("permission/SET_ROUTES", []);
          store.commit("user/SET_PERMISSIONS", []);
          store.commit("user/SET_MENU", []);
          store.commit("user/SET_ORGLIST", []);
          store.commit("user/SET_ORGID", undefined);
          store.commit("user/SET_PERMISSIONMAP", {});
          Message.error("获取系统菜单失败");
          next(`/login`);
          NProgress.done();
        }
      }
    }
  } else {
    /* has no token*/

    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login`);
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done();

  clearTimeout(timer);
  timer = undefined;
});
