import axios from "../axios";
export const deviceMangerApi = {
  deviceMangerApi: {
    // 查询列表
    getList(params) {
      return axios.get("/patrol/v2/asset/list", {
        params,
        showLoading: true,
      });
    },
    // 新增巡检内容
    add(params) {
      return axios.post("/patrol/v2/asset", params, {
        headers: {
          "node-Type": "application/json",
        },
        showLoading: true,
      });
    },
    // 删除巡检内容
    del(assetId) {
      return axios.delete(`/patrol/v2/asset/${assetId}`, {
        showLoading: true,
      });
    },
    // 批量删除
    delBetch(params) {
      return axios.delete(`/patrol/v2/asset`, {
        showLoading: true,
        params,
      });
    },
    // 根据id 查询 巡检点详细内容
    getNodeInfo(id) {
      return axios.get("/patrol/node/" + id, {
        showLoading: true,
      });
    },
    // 修改巡检内容
    modify(params) {
      return axios.put("/patrol/v2/asset", params, {
        headers: {
          "node-Type": "application/json",
        },
        showLoading: true,
      });
    },


    // 查询所有巡检类别，已改
    getType() {
      return axios.get("/patrol/v2/asset/inspection/type/like", {
        showLoading: true,
      });
    },


    // 查询所有单位为主
    getUnit() {
      return axios.get("/patrol/range/like", {
        showLoading: true,
        params: {
          range: "",
        },
      });
    },

    ///////////////////////////////////////////////////////

    // 查询巡检类别
    getTypeList(params) {
      return axios.get("/patrol/node/type/list", {
        params,
        showLoading: true,
      });
    },

    // 单元位置、设备名称查询
    getUnitAndDevice(params) {
      return axios.get("/patrol/device/region/list", {
        params,
        showLoading: true,
      });
    },
    // 查询巡检类别和巡检内容
    getContentAndType(params) {
      return axios.get("/patrol/content/type/list", {
        params,
        showLoading: true,
      });
    },
    // 查询巡检点
    getPoint(contentType) {
      return axios.get("/patrol/asset/list", {
        params: {
          contentType,
        },
        showLoading: true,
      });
    },

    // 查询巡检类别--资产
    getInspectionTypeList() {
      return axios.get("/patrol/asset/inspection/type/list", {
        showLoading: true,
      });
    },

    // 根据巡检类别查询巡检内容
    getInspectionContentByType(inspectionContentType) {
      return axios.get("/patrol/content/list/type", {
        params: {
          inspectionContentType,
        },
        showLoading: true,
      });
    },

    // 查询单元位置列表
    getRegionList(region){
      return axios.get("/patrol/v2/asset/region/list", {
        params: {
          region,
        },
        showLoading: false,
      });
    }


  },
};
