/**
 * PlayerItem
 */
class PlayerItem {
    /**
     * @param {*} opt.wrapperDomId 父级id
     * @param {*} opt.index 索引
     */
    constructor(opt) {
        // dom
        this.$el = null
        // 播放用元素
        this.canvasElem = null
        this.videoElem = null
        // 每个组件的dom唯一 id
        this.domId = opt.wrapperDomId + '-' + opt.index
        // 所属的wsplayer
        this.wsPlayer = opt.wsPlayer
        // index序号
        this.index = opt.index
        // 第一帧事件
        this.firstTime = 0
        // audioOn
        this.isAudioPlay = false
    }

    /**
     * @param {*} domId 此播放器的id
     */
    initDom() {
        let template = this.getTemplate()
        let player = $(template)
        this.wsPlayer.$wrapper.append(player[0])
        this.$el = $('#' + this.domId)
        this.canvasElem = document.getElementById(this.canvasId)
        this.videoElem = document.getElementById(this.videoId)
    }
    // 添加监听
    initMouseEvent() {
        this.$el.click((evt) => {
            this.wsPlayer.setSelectIndex(this.index)
            this.$el.siblings().removeClass('selected').addClass('unselected')
            this.$el.removeClass('unselected').addClass('selected')
        })
        this.$el.dblclick((evt) => {
            if (this.wsPlayer.$el.hasClass('fullplayer')) {
                this.wsPlayer.setPlayerNum(4)
            } else {
                this.wsPlayer.setPlayerNum(1)
            }
            this.wsPlayer.setSelectIndex(this.index)
            this.$el.siblings().removeClass('selected').addClass('unselected')
            this.$el.removeClass('unselected').addClass('selected')
        })
        $('.end-icon', this.$el).on('click', () => {
            this.close()
        })
        $('.audio-icon', this.$el).click((evt) => {
            if (this.isAudioPlay) {
                // 正在播放，关闭声音
                this.player.setAudioVolume(0);
                $(evt.target).removeClass('on').addClass('off')
            } else {
                // 未播放，打开声音
                this.player.setAudioVolume(1);
                $(evt.target).removeClass('off').addClass('on')
            }
            this.isAudioPlay = !this.isAudioPlay
        })
        $('.capture-icon', this.$el).click((evt) => {
            this.player.capture(`抓图-${Date.now()}`);
        })
        $('.close-icon', this.$el).click((evt) => {
            this.player.stop()
            this.player.close()
            this.setStatus('closed')
        })
    }
    // 设置状态
    setStatus() {}
    /**
     * 关闭视频
     */
    play() {
        this.player.play()
        this.setStatus('playing')
    }
    /**
     * 关闭视频
     */
    pause() {
        this.player.pause()
        this.setStatus('pause')
    }
    /**
     * 关闭视频
     */
    close() {
        this.player.stop()
        this.player.close()
        this.setStatus('closed')
    }

    // 设置元素是否可见
    setDomVisible(dom, visible) {
        dom.css({
            visibility: visible ? 'visible' : 'hidden'
        })
    }
}
export default PlayerItem