import axios from "../axios";
import qs from "qs";

const userApi = {
    getUserList(params) {
        /**
         * 分页查询用户信息
         * @parmas {Number} page 第几页 必填
         * @parmas {Number} realName 姓名
         * @parmas {Number} size 页的数量 必填
         * @parmas {array} status 状态
         * @parmas {string} userName 用户名
         */
        return axios.get("/user/list", {
            params,
        });
    },


    getUserListInRole(params) {
        /**
         * 用于角色页面分页查询用户--由于权限管理
         * @parmas {Number} page 第几页 必填
         * @parmas {Number} realName 姓名
         * @parmas {Number} size 页的数量 必填
         * @parmas {array} status 状态
         * @parmas {string} userName 用户名
         */
        return axios.get("/role/user/list", {
            params,
        });
    },



    createUser(params) {
        /**
         * 新增用户
         * @parmas {string} userName 用户名
         * @parmas {string} password 密码
         * @parmas {string} realName 真实姓名
         * @parmas {Number} status 状态
         * @parmas {Arrey} roleIdList 角色
         */
        return axios.post("/user", params, {
            headers: {
                "Content-Type": "application/json",
            },
            showLoading: true,
        });
    },
    modifyUserinfo(params) {
        /**
         * 修改用户信息
         * "password": "string",
         "realName": "string",
         "roleIdList": [
         "string"
         ],
         "userId": "string"
         */
        return axios.put("/user", params, {
            headers: {
                "Content-Type": "application/json",
            },
            showLoading: true,
        });
    },
    searchById(userId) {
        /**
         * 根据用户id查询用户
         * @params {string} userId 用户id
         */
        return axios.get("/user/" + userId, {showLoading: true});
    },
    changeStatus(params) {
        /**
         * 改变用户状态
         * @params {String} userId
         * @params {Number} status
         */
        return axios.put("/user/status", params, {
            headers: {
                "Content-Type": "application/json",
            },
            showLoading: true,
        });
    },
    // 用户自己修改密码
    userUpdatePassword(params) {
        return axios.put("/user/userUpdatePassword", params, {
            headers: {
                "Content-Type": "application/json",
            },
            showLoading: true,
        });
    },

    // 修改密码
    updatePassword(params) {
        return axios.put("/user/updatePassword", params, {
            headers: {
                "Content-Type": "application/json",
            },
            showLoading: true,
        });
    },

    // 根据token 获取用户信息
    getUserInfoByToken(token) {
        return axios.get("/login/refreshToken", {
            showLoading: true,
            headers: {
                "Authorization": token
            },
        });
    },

};
export default userApi;
