<template>
  <el-card>
    <div slot="header" class="clearfix" v-if="showHeader">
      <slot name="header" ></slot>
    </div>
    <div class="content-card">
      <slot name="content"></slot>
    </div>
  </el-card>
</template>

<script>
export default {
  props:{
    showHeader:{
      type:Boolean,
      default:true
    }
  }
};
</script>

<style lang="less" scoped>
/deep/.card-header{
  display: flex;
  justify-content: space-between;
  // height: 5%;
  .control-header{
    display: flex;
    align-items: center;
  }
  /deep/.el-form{
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-bottom:0px;
    margin-bottom: -20px;
    height:100%;
    .el-form-item{
      height: 100%;
    }
  }
  .inlineform{
    display: flex;
    align-items: center;
  }
}
</style>
