import axios from "@/util/lrrequest";
const lrApi = {
    lrgetTypeOption() {
        return axios.get("/data-items/eam_repair_type/detail/tree");
    },
    lrgetSourcesOption(){
        return axios.get("/data-sources/6e97e7d28ce8bde07ecd2d7684e36457/data")
    },
    lrStatuesList(){
        return axios.get("/data-items/eam_event/detail")
    },
    lrgetassetslist(params){
        return axios.get("/form-scheme/4eea7fbd57bb3582133bed70d09e807c/instance-form-list",{params})
    }
  };
export default lrApi
  