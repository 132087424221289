<template>
  <el-autocomplete
      class="iptWithSug-wrap"
      v-model="state"
      :fetch-suggestions="querySearch"
      placeholder="请输入内容"
      :trigger-on-focus="false"
      @select="handleSelect"
  ></el-autocomplete>
</template>

<script>
export default {
  name: "iptWithSug-index",
  components:{},
  props:{
    sugData:{
      type:Array,
      default:()=>[]
    },
    defaultProps:{
      type:Object,
      default:()=>{
        return {
          value:"value",
          
        }
      }
    }
  },
  data() {
    return {
      state:""
    }
  },
  computed:{

  },
  watch:{
    state(newVal){
     this.handleSelect(newVal)
    }
  },
  mounted() {
    // 单列模式
    this.CommonWorkInstance = this.commonWork()
  },
  methods:{
    // 初始化加载
    init(){
    
    },
    // 公共逻辑
    commonWork(){
        if(!this.CommonWorkInstance){
            return {
        
            }
        }
        return this.CommonWorkInstance
        
    },
    handleSelect(item) {
      if (this.$listeners['onIpt']){
        this.$emit('onIpt',item)
      }
    },
    querySearch(queryString, cb) {
      let restaurants = this.sugData;
      let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      let valueSign = this.defaultProps.value
      results = results.map(item=>{
        return {
          value:item[valueSign],
          label:item[valueSign],
        }
      })
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      let valueSign = this.defaultProps.value
      return (restaurant) => {
        return (restaurant[valueSign].toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
  },
  destroyed() {
  }
}
</script>

<style scoped lang="scss">
.iptWithSug-wrap {
  height: 100%;
}
</style>
