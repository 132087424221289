<template>
  <div style="height: 72vh"></div>
</template>

<script>
export default {
  props: {
    option: Object,
  },
  data() {
    return {
      myChart: "",
    };
  },
  mounted() {
    this.myChart = this.$echarts.init(this.$el);
    this.$emit("stopload", false);
    this.myChart.setOption(this.option);
  },
  watch: {
    dataAll() {
      this.myChart.clear();
      this.changeOption();
      this.myChart.setOption(this.option);
      this.$emit("stopload", false);
    },
  },
  watch: {
    //观察option的变化
    option: {
      handler(newVal, oldVal) {
        console.log(newVal, oldVal);
        if (this.myChart) {
          if (newVal) {
            this.myChart.clear();
            this.myChart.setOption(newVal);
          } else {
            this.myChart.setOption(oldVal);
          }
        } else {
          this.myChart = this.$echarts.init(this.$el);
          this.$emit("stopload", false);
          this.myChart.setOption(this.option);
        }
      },
      deep: true, //对象内部属性的监听，关键。
    },
  },

  beforeDestroy() {
    console.log(1111);
    this.myChart.clear();
  },
};
</script>

<style lang="less" scoped></style>
