import axios from "../axios";
export const terminalManagerApi = {
  terminalManagerApi: {
    // 查询列表
    getList(params) {
      return axios.get("/patrol/device/list", {
        params,
        showLoading: true,
      });
    },
    // 新增巡检内容
    register(params) {
      return axios.put("/patrol/device/register", params, {
        showLoading: true,
      });
    },
    // 删除巡检内容
    unRegister(params) {
      return axios.put("/patrol/device/unregister", params, {
        showLoading: true,
      });
    },
  },
};
