<template>
  <div class="right-header">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item
        v-for="(item,index) in breadArr"
        :key="index"
        :to="{ path: item.path }"
        >{{ item.title }}</el-breadcrumb-item
      >
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadArr: [],
    };
  },
  methods: {
    //!计算面包屑
    calcBreadArr() {
      let ret = [{ path: "/", title: "首页" }]; //默认首页
      this.$route.matched.forEach((item) => {
        if (item.meta.title) {
          ret.push({
            title: item.meta.title,
            path: item.path,
          });
        }
      });
      console.log(this.breadArr);
      this.breadArr = ret;
    },
  },
  created() {
    this.calcBreadArr(); //!刷新计算面包屑
  },
  watch: {
    "$route.path"() {
      this.calcBreadArr(); //!路由改变计算面包屑
    },
  },
};
</script>

<style lang="less" scoped>
.right-header {
  border-radius: 6px;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0.5em;
  margin-bottom: 0.5em;
  box-sizing: border-box;
  i {
    padding-right: 0.5em;
    font-size: 1.3em;
  }
  /deep/ .el-breadcrumb__inner {
    color: #aebbcc;
    font-weight: normal;
  }
  /deep/ .el-breadcrumb__item:last-child span {
    color: var(--themeColor);
  }
}
</style>
