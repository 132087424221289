import axios from "../axios";
import qs from "qs";
const processorApi = {
  creProcessor(params) {
    /**
     * ? 新增加工器
     * {
        "config": {
            "downSampling": {
            "fillEmpty": false,
            "influxWindowFu": "count",
            "periodSecond": "string"
            },
            "execIntervalSec": 0,
            "processFunc": "string",
            "processFuncConfig": {}
        },
        "inputMetricIds": [
            "string"
        ],
        "name": "string",
        "outputMetricTableId": "string",
        "status": 0
        }

     */
    return axios.post("/metric/processor", params, {
      headers: {
        "Content-Type": "application/json",
      },
      showLoading: true,
    });
  },
  upProcessor(id, params) {
    /**
       * ?更新加工器
       * {
            "config": {
                "downSampling": {
                "fillEmpty": false,
                "influxWindowFu": "count",
                "periodSecond": "string"
                },
                "execIntervalSec": 0,
                "processFunc": "string",
                "processFuncConfig": {}
            },
            "inputMetricIds": [
                "string"
            ],
            "name": "string",
            "status": 0
            }
       */
    return axios.put("/metric/processor/" + id, params, {
      headers: {
        "Content-Type": "application/json",
      },
      showLoading: true,
    });
  },
  delProcessor(id,params) {
    /**
     * ?删除加工器
     */
    return axios.delete("/metric/processor/" + id,{params}, {
      showLoading: true,
    });
  },
  getProcessor(params) {
    /**
     * ? 根据ID查询加工器信息
     */
    return axios.get("/metric/processor/" + params, {
      showLoading: true,
    });
  },
  getProcessorList(params) {
    /**
     * ? 获取加工器列表
     */
    return axios.get("/metric/processor/list", {
      params,
    });
  },
  chgProcessor(params) {
    /**
     *? 停用或启用加工器
     */
    return axios.put("/metric/processor/status", params, {
      showLoading: true,
    });
  },
};
export default processorApi;
