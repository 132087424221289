import { asyncRoutes, constantRoutes } from "@/router/config";
import store from "../index";
import _ from "lodash";

// 根据权限返回对应的动态路由
const filterAsyncRoutes = (routes, roles) => {
  let copyAsyncRoutes = _.cloneDeep(asyncRoutes);
  let userRoles = store.state.user.roles;
  if (
    userRoles.length &&
    _.findIndex(userRoles, { resType: "**", resOpt: "**" }) >= 0
  ) {
    return copyAsyncRoutes;
  }
  // 根据后端返回的权限来过滤动态路由
  let permissions = _.cloneDeep(store.state.user.permissions);
  console.log(permissions,"filterAsyncRoutes")
  let arry = permissions;
  console.log(arry,"过滤后")
  arry.forEach((item) => {
    modifyRoutes(item.resType, copyAsyncRoutes,item.resOpt);
  });

  console.log(copyAsyncRoutes,"111111console.log(copyAsyncRoutes)")
  delRoute(copyAsyncRoutes);
  console.log(copyAsyncRoutes,"22222console.log(copyAsyncRoutes)")
  return _.cloneDeep(copyAsyncRoutes);
};
// 打标拥有的菜单权限
const modifyRoutes = (flag, data,resOpt) => {
  data.forEach((item) => {
    item.children.forEach((obj) => {
      if (obj.meta.name == flag && resOpt == "present") {
        obj.hasPer = true;
        item.hasPer = true;
      }
      // if (flag=="present"){
      //   obj.hasPer = true;
      //     item.hasPer = true;
      // }
      if (obj.children && obj.children.length) {
        modifyRoutes(flag, obj.children);
      }
    });
  });
};
// 删除没有权限的菜单
const delRoute = (data) => {
  for (let i = 0; i < data.length; i++) {
    let item = data[i];
    if (!item.hasPer) {
      data.splice(i, 1);
      i--;
    } else {
      if (item.children && item.children.length) {
        delRoute(item.children);
      }
    }
  }
};

const state = {
  routes: [],
  addRoutes: [],
};

const mutations = {
  SET_ROUTES: (state, routes) => {
    // vue-router 动态生产路由
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  },
};

const actions = {
  /**
   * 动态生产路由
   * @param {*} param0
   * @param {*} roles
   */
  generateRoutes({ commit }, roles) {
    return new Promise((resolve) => {
      // 根据角色来过滤动态路由
      let newRoutes = filterAsyncRoutes();
      // 固定路由会在动态路由之前先加载
      newRoutes.push({
        path: "*",
        redirect: "/404",
        meta: { isMenu: false },
      });
      commit("SET_ROUTES", newRoutes);
      resolve(newRoutes);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
