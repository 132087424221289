import axios from "../axios";
export const inspectionContentApi = {
  inspectionContentApi: {
    // 查询列表
    getList(params) {
      return axios.get("/patrol/v2/item/list", {
        params,
        showLoading: true,
      });
    },

    // 条件筛选巡检列表
    getListByContion() {},
    // 新增巡检内容
    add(params) {
      return axios.post("/patrol/v2/item", params, {
        headers: {
          "Content-Type": "application/json",
        },
        showLoading: true,
      });
    },
    // 删除巡检内容
    del(itemIds) {
      return axios.delete("/patrol/v2/item", {
        params: {
          itemIds,
        },
        showLoading: true,
      });
    },
    // 修改巡检内容
    modify(params) {
      return axios.put("/patrol/v2/item", params, {
        headers: {
          "Content-Type": "application/json",
        },
        showLoading: true,
      });
    },
    // 查询巡检类别
    getTypeList(params) {
      return axios.get("/patrol/content/type/list", {
        params,
        showLoading: true,
      });
    },

    // 导入
    importExcel(params) {
      return axios.post("/patrol/content/import", params, {
        showLoading: true,
      });
    },

    // 导入
    exportExcel() {
      return axios.get("/patrol/content/export", {
        responseType: "blob",
        // headers: {
        //   "Content-Type": "application/json",
        // },
      });
    },
  },
};
