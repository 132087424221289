<!--el-table其他参数与属性如果用到请在此组件自行完善-->
<template>
    <el-table 
    :data="tableData"
    border
    row-key="id">
        <el-table-column v-for="(item, index) in col"
            :key="`col_${index}`"
            :prop="dropCol[index].prop"
            :label="item.label"> 
        </el-table-column>
    </el-table>
</template>

<script>
import Sortable from 'sortablejs'
export default {
    props:{
        'tableData':{
            default:[]
        },
        'stripe':{
            default:false
        },
        'border':{
            default:false
        },
        'show-header':{
            default:true
        },
        'col':{
            default:[]
        },
        'dropCol':{
            default:[]
        },
        'rowdrop':{
            default:false        
        },
        'coldrop':{
            default:false
        }
    },
    data() {
        return {
            
        }
    },
    mounted(){
        // 阻止默认行为
        document.body.ondrop = function (event) {
                event.preventDefault();
                event.stopPropagation();
        };
        this.rowdrop&&this.rowDrop();
        this.coldrop&&this.columnDrop()
    },
    methods:{
        rowDrop() {
            const tbody = document.querySelector('.el-table__body-wrapper tbody')
            const _this = this
            Sortable.create(tbody, {
                onEnd({ newIndex, oldIndex }) {
                const currRow = _this.tableData.splice(oldIndex, 1)[0]
                _this.tableData.splice(newIndex, 0, currRow)
                }
            })
        },
        //列拖拽
        columnDrop() {
            const wrapperTr = document.querySelector('.el-table__header-wrapper tr')
            this.sortable = Sortable.create(wrapperTr, {
            animation: 180,
            delay: 0,
            onEnd: evt => {
                const oldItem = this.dropCol[evt.oldIndex]
                this.dropCol.splice(evt.oldIndex, 1)
                this.dropCol.splice(evt.newIndex, 0, oldItem)
            }
            })
        }
    }
}
</script>

<style scoped>

</style>