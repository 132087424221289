<template>
  <div>
    <el-table
      ref="multipleTable"
      @selection-change="handleSelectionChange"
      border
      max-height="600"
      :data="AuthList"
      style="width: 100%"
    >
<!--      <el-table-column type="selection" width="55"> </el-table-column>-->
      <el-table-column prop="menuLabel" label="菜单权限"> </el-table-column>
      <el-table-column prop="operate" label="操作权限" width="500px">
        <template slot-scope="scope">
          <el-checkbox
            @change="onCheck"
            v-for="(item, index) in scope.row.resOptName"
            :key="item"
            v-model="scope.row.checked[index]"
            >{{ item }}</el-checkbox
          >
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            v-if="
              !scope.row.checked.every((item) => {
                return item == true;
              })
            "
            type="text"
            size="small"
            @click="onSelAll(scope.row)"
            >全选</el-button
          >
          <el-button
            v-else
            type="text"
            size="small"
            @click="onSelNone(scope.row)"
            >取消全选</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { menu } from "@/config/menu";
import _ from "lodash";
export default {
  props: ["hasPrs","menuType","isAdd"],
  data() {
    return {
      AuthList: [],
      permissions: [],
      multipleSelection: [],
      allAuthList:[],
      ownPrs:[]
    };
  },
  watch: {
    hasPrs: {
      handler: function (newVal) {
        console.log(newVal,"hasPrs")
        this.ownPrs = newVal.filter(item=>{
          return item.subSystem == this.menuType
        })
        if (this.AuthList.length){
          this.AuthList = _.cloneDeep(this.allAuthList[this.menuType]);

          // 用于编辑角色时候回显
          this.rebuildAuthList(this.isAdd);

          console.log(this.AuthList,"asdfasdf")
        }


      },
      deep: true,
    },
    menuType(value){
      console.log(value,"ssssss")
      // if (!value){
      //   return ;
      // }
      // this.AuthList = _.cloneDeep(this.allAuthList[value]);
      //
      // // 用于编辑角色时候回显
      // this.rebuildAuthList(this.isAdd);
      //
      // console.log(this.AuthList,"asdfasdf")
    }
  },
  created() {
    this.changeAuthList();
    window._ = this._;
  },
  mounted() {
    window.vue = this;
    console.log(11111111)
    this.getCustomizeSysMenu();
  },
  beforeDestroy() {
    this.AuthList = []
  },
  methods: {
    onClose(){
      this.AuthList = []
    },
    sendChsAuth() {
      let pesArr = [];
      this.AuthList.forEach((element) => {
        for (let i = 0; i < 1000; i++) {
          if (element.checked[i] == true) {
            let obj = {
              resType: element.resType,
              resOpt: element.resOpt[i],
            };
            pesArr.push(obj);
          }
        }
      });
      this.permissions = pesArr;

      this.$emit("sendChsAuth", this.permissions);
    },
    changeAuthList() {
      this.ownPrs.map((v) => {
        let rt = v.resType;
        let ro = v.resOpt;
        if (rt == "**") {
          this.AuthList.forEach((element) => {
            if (ro == "**") {
              //rt==** ro==**
              this.AuthList.map((v) => {
                v.checked = v.checked.map((v) => true);
              });
            } else {
              //rt==** ro!==**
              let index = element.resOpt.indexOf(ro);
              element.checked[index] = true;
            }
          });
        } else {
          //rt!=**
          this.AuthList.forEach((element) => {
            if (element.resType == rt) {
              if (ro == "**") {
                element.checked = element.checked.map((v) => true);
              } else {
                //rt!=** ro!=**
                let index = element.resOpt.indexOf(ro);
                element.checked[index] = true;
              }
            }
          });
        }
      });
    },
    // 全选菜单
    handleSelectionChange(val) {
      this.$emit("passPermission", this.findSelectedMenu(),this.menuType);
    },
    // 点击多选框
    onCheck() {
      this.$emit("passPermission", this.findSelectedMenu(),this.menuType);
    },
    // 全选
    onSelAll(row) {
      row.checked = row.checked.map((v) => true);
      this.$emit("passPermission", this.findSelectedMenu(),this.menuType);
    },
    // 取消全选
    onSelNone(row) {
      row.checked = row.checked.map((v) => false);
      this.$emit("passPermission", this.findSelectedMenu(),this.menuType);
    },


    // 获取所有菜单
    // 获取菜单配置
    getCustomizeSysMenu(){
      console.log(this.menuType,"menuType");
      this.$api.getCustomizeSysMenu().then(res=>{
        this.allAuthList = res.data;
        if (!this.allAuthList[this.menuType]){
          return false;
        }
        this.AuthList = _.cloneDeep(this.allAuthList[this.menuType]);
        // 用于编辑角色时候回显
        this.rebuildAuthList(this.isAdd);
      })

      // this.$api.getCustomizeSysMenu().then(res=>{
      //   console.log(res,"resdddddd");
      //   this.AuthList = res;
      //
      // })
    },

    // 重新构造
    rebuildAuthList(isAdd){
      let copyAuthList = _.cloneDeep(this.AuthList);
      console.log(copyAuthList,"copyAuthList")
      copyAuthList.forEach(item=>{
        let o = []
        item.resOpt.forEach(obj=>{
          o.push(false)
        })
        item.checked = o;
      })
      this.AuthList = copyAuthList;
      if (isAdd){ // 新增

      }else{ // 编辑
        console.log(this.ownPrs,"echo")
        this.echoChecked(this.ownPrs);
        this.$emit("passPermission", this.findSelectedMenu(),this.menuType);
      }
    },
    // 回显
    echoChecked(newVal){
      newVal.forEach((item) => {
        let menu = this._.find(this.AuthList, { resType: item.resType });
        console.log(menu,"echo-menu")
        if (menu) {
          let resOpt = menu.resOpt;
          for (let i = 0; i < resOpt.length; i++) {
            if (resOpt[i] === item.resOpt){
              menu.checked[i] = true;
              break;
            }
          }
        }
      });
    },

    // 过滤出已选择的权限
    findSelectedMenu(){
      let pesArr = [];
      this.AuthList.forEach((element) => {
        for (let i = 0; i < 1000; i++) {
          if (element.checked[i] == true) {
            let obj = {
              resType: element.resType,
              resOpt: element.resOpt[i],
              subSystem:this.menuType
            };
            pesArr.push(obj);
          }
        }
      });
      console.log(pesArr,"perArr")
      return pesArr;
    },

  },
};
</script>

<style lang="less" scoped></style>
