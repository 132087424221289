const state = {
    lineTime:{time:0,type:"number"},
    feature:null,
    grouppop:null,
    timetrigger:true,
}
const mutations = {
    SET_LINETIME(state,data){
        state.lineTime = data;
    },
    SET_FEATURE(state,data){
        state.feature = data;
    },
    SET_GROUPPOP(state,data){
        state.grouppop = data;
    },
    SET_TIMETRIGGER(state,data){
        state.timetrigger = data;
    }
}
const actions = {};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};