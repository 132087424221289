import { Loading } from "element-ui";
import _ from "lodash";

let needLoadingRequestCount = 0;
let loading;

function startLoading(loadingTarget) {
  loading = Loading.service({
    lock: true,
    text: "正在加载",
    background: "rgba(0, 0, 0, 0.3)",
    target: document.querySelector(loadingTarget),
    customClass: "loading-mask-wrap",
  });
}

function endLoading() {
  loading.close();
}

const tryCloseLoading = () => {
  if (needLoadingRequestCount === 0) {
    endLoading();
  }
};

export function showFullScreenLoading(loadingTarget) {
  if (needLoadingRequestCount === 0) {
    startLoading(loadingTarget);
  }
  needLoadingRequestCount++;
}

export function tryHideFullScreenLoading() {
  if (needLoadingRequestCount <= 0) return;
  needLoadingRequestCount--;
  if (needLoadingRequestCount === 0) {
    tryCloseLoading();
  }
}
