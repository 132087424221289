<template>
  <div class="ptz-new-wrap">
    <div class="left" v-if="type===3" @mousedown="IccZoomIn(2)"
         @mouseup="IccZoomOut(2)">
      <img src="./pollingComponet/img/缩小.png" alt="">
      <div class="effect-wrap"></div>
    </div>

    <div class="left" v-if="type===1">
      <img src="./pollingComponet/img/缩小.png" alt="">
      <div class="effect-wrap"></div>
    </div>

    <!-- 大华ICC云台控制   -->
    <div class="middle" v-if="type===1">
      <img src="./pollingComponet/img/操作云台.png" alt="">
      <div class="up-opt"></div>
      <div class="down-opt"></div>
      <div class="left-opt"></div>
      <div class="right-opt"></div>
    </div>


    <!-- 海康云台控制   -->
    <div class="middle" v-if="type===3">
      <img src="./pollingComponet/img/操作云台.png" alt="">
      <div class="up-opt" @mousedown="onControl(1)"
           @mouseup="onStop(1)"></div>
      <div class="down-opt" @mousedown="onControl(2)"
           @mouseup="onStop(2)"></div>
      <div class="left-opt" @mousedown="onControl(3)"
           @mouseup="onStop(3)"></div>
      <div class="right-opt" @mousedown="onControl(4)"
           @mouseup="onStop(4)"></div>
    </div>


    <div class="right" v-if="type===3" @mousedown="IccZoomIn(1)"
         @mouseup="IccZoomOut(1)">
      <img src="./pollingComponet/img/放大.png" alt="">
      <div class="effect-wrap"></div>
    </div>

    <div class="right" v-if="type===1">
      <img src="./pollingComponet/img/放大.png" alt="">
      <div class="effect-wrap"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PTZ_new",
  props: {
    type: {
      type: Number,
      default: 2
    },
    videoData: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      play: true,
    };
  },
  methods: {
    // 暂停或开始
    playOrPause() {
      this.play = !this.play;
    },
    // 控制方向开始
    onControl(arrow) {
      console.log(1212)
      if (this.type === 2) {
        this.$api.PTZ.HKControl(this.buildHK(this.videoData, arrow, 0)).then(res => {
          this.passEvent()
        });
      } else if (this.type === 3) {
        this.$api.PTZ.DHControl(this.buildICC(this.videoData, 1, arrow)).then(res => {
          this.passEvent()
        });
      }
    },
    // 控制方向停止
    onStop(arrow) {
      console.log(2222)
      if (this.type === 2) {
        this.$api.PTZ.HKControl(this.buildHK(this.videoData, arrow, 1)).then(res => {
          this.passEvent()
        });
      } else if (this.type === 3) {
        this.$api.PTZ.DHControl(this.buildICC(this.videoData, 0, arrow)).then(res => {
          this.passEvent()
        });
      }
    },

    // 大华icc平台的放大
    IccZoomIn(direct) {
      this.$api.PTZ.DHControlDistance({
        "command": 1,
        "direct": direct,
        "extend": "",
        "operateType": 1,
        "outChannelId": this.videoData.outChannelId,
        "platformId": this.videoData.platfromId,
        "step": 10
      })
    },
    // 大华icc平台的停止
    IccZoomOut(direct) {
      this.$api.PTZ.DHControlDistance({
        "command": 0,
        "direct": direct,
        "extend": "",
        "operateType": 1,
        "outChannelId": this.videoData.outChannelId,
        "platformId": this.videoData.platfromId,
        "step": 10
      })
    },


    //  build 大华 request params
    buildICC(data, command, arrow = 1) {
      return {
        "command": command,  // 0 停止 1开启
        "direct": arrow,
        "extend": "",
        "outChannelId": data.outChannelId,
        "platformId": data.platfromId,
        "stepX": 10,
        "stepY": 10
      };

    },
    //  build haikang request params
    // arrow 1 开始，0 停止
    buildHK(data, action, arrow = 1) {
      return {
        action: arrow,
        cameraIndexCode: data.outChannelId, // outChannelId
        command: action,
        platformId: data.platfromId,
      };
    },

    /*回到预置点*/
    goPreset(presetIndex) {
      if (this.type == 2) {
        this.$api.PTZ.HKControl({
          action: 1,
          cameraIndexCode: this.videoData.outChannelId, // outChannelId
          command: "GOTO_PRESET",
          platformId: this.videoData.platfromId,
          presetIndex
        }).then(res => {
          this.passEvent()
        })
      } else {
      }
    },


    /*传出事件*/
    passEvent() {
      if (this.$listeners["ifChangePreset"]) {
        this.$emit("ifChangePreset", true)
      }
    }
  },
}
</script>

<style scoped lang="scss">
.ptz-new-wrap {
  width: 128px;
  height: 40px;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: row;

  & > div {
    width: 33%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .effect-wrap{
    width: 80%;
    height: 80%;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

  }
  .effect-wrap:active{
    background: rgba(0, 0, 0, 0.4);
  }

  .left {
    img {
      width: 14px;
      height: 14px;
    }
    cursor: pointer;
  }

  .right {
    img {
      width: 14px;
      height: 14px;
    }
    cursor: pointer;
  }

  .middle {
    img {
      width: 100%;
      height: 100%;
    }

    position: relative;

    & > div {
      position: absolute;
      width: 18px;
      height: 18px;
      cursor: pointer;
    }

    & > div:active {
      background: rgba(0, 0, 0, 0.4);
      border-radius: 50%;
    }

    .up-opt {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    .down-opt {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    .left-opt {
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    .right-opt {
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

  }
}

</style>
