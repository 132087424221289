import axios from "../axios";
export const orgApi = {
  orgApi: {
    // 以树结构展示组织
    getList(params) {
      return axios.get("/org/tree", {
        params,
        showLoading: true,
      });
    },
    // 新增组织
    add(params) {
      return axios.post("/org", params, {
        showLoading: true,
      });
    },
    // 编辑组织
    update(params) {
      return axios.put("/org", params, {
        showLoading: true,
      });
    },
    // 删除任务
    del(id) {
      return axios.delete("/org/" + id, {
        showLoading: true,
      });
    },
    // 根据id查询组织
    check(id) {
      return axios.get(`/org/${orgId}`, {
        showLoading: true,
      });
    },
    // 根据组织id查询用户
    getUser(id) {
      return axios.get(`/org/${id}/user`, {
        showLoading: true,
      });
    },

    // 获取所有org
    getAllOrgList(params){
      return axios.get("/org/list",{
        params
      })
    },

    // 获取门户详情
    getSubSystemDetail(orgId,type){
      return axios.get(`/system/portal/${orgId}/${type}`,{

      })
    },

    // 保存门户设置
    saveSubSystem(params){
      return axios.post("/system/portal", params, {
        showLoading: true,
      });
    },
    setportaljson(params){
      return axios.post('/system/portalV2',params)
    },
    getportaljson(){
      return axios.get("/system/portalV2/all")
    }
  },
};
