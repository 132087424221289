<template>
  <div class="iccPlayer-container-wrap">
    <div :id="ele" style="height: 100%;width: 100%"></div>
  </div>
</template>

<script>
import WSPlayer from '@/util/WSPlayer/WSPlayer';

export default {
  name: "h5PlayerIcc",
  props:{
    url:{
      type: String,
      default: '',
    },
    serverAdress:{
      type: String,
      default: '',
    },
    channelId:{
      type: String,
      default: '',
    }
  },
  data() {
    return {
      realPlayer:undefined,
      ele:"ele"+new Date().getTime(),
    }
  },
  watch:{
    url(value){
      // if(this.realPlayer){
      //   this.realPlayer.close();
      // }
      if(value){
        if(!this.realPlayer){
          this.createPlayer()
        }else{
          this.realPlay(value)
        }
      }
    }
  },
  mounted() {
    if (this.url){
      this.createPlayer();
    }

  },
  methods: {
    createPlayer(){
      if (!this.realPlayer ) {
        this.realPlayer = new WSPlayer({
          el: this.ele, // 必传
          type: 'real', // real | record
          // serverIp: this.serverAdress,
          serverIp:"10.0.55.121:3306",   // 代码配置websocket 反代
          num: 1,
          showControl: false,
        })
        this.realPlayer.addListener('selectChange', function (index, options) {
          console.log(index, options)
        })
        this.realPlayer.playReal({
          rtspURL: this.url, // string | array[string]
          decodeMode: 'canvas', // 解码方式，video | canvas, h264默认video播放，h265默认canvas播放
          channelId:this.channelId || new Date().getTime(), // 可选，用来标记当前视频播放的通道id
        })
        window.realPlayer = this.realPlayer;
      }
    },
    realPlay(url){
      if(this.realPlayer){
        this.realPlayer.playReal({
          rtspURL: url, // string | array[string]
          // decodeMode: 'canvas', // 解码方式，video | canvas, h264默认video播放，h265默认canvas播放
          channelId: this.channelId || new Date().getTime(), // 可选，用来标记当前视频播放的通道id
        })
      }
    },
    close(){
      this.realPlayer.close();
    }

  },
  beforeDestroy() {
    // this.realPlayer.close();
    this.close();
  }
}
</script>

<style scoped lang="scss">
.iccPlayer-container-wrap{
  height: 100%;
  width: 100%;
  #ws-real-player{
    height: 100%;
    width: 100%;
  }
}
</style>
