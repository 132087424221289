import defaultSettings from "@/settings";

const title = defaultSettings.title || "智能助手";

export default function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${pageTitle} - ${title}`;
  }
  return `${title}`;
}

// export default function getSystemName(pageTitle) {
//   if (pageTitle) {
//     return `${pageTitle} - ${title}`;
//   }
//   return `${title}`;
// }
