<template>
  <div class="sort-table-wrap">
    <el-table
      :max-height="tableHeight"
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
      @row-click="rowClick"
      :data="data"
      style="width: 100%"
      tooltip-effect="dark"
      highlight-current-row
    >
      <slot></slot>
    </el-table>
    <!-- 分页 -->
    <div v-if="showPage">
      <el-pagination
        background
        style="text-align: left; margin-top: 10px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 15, 20]"
        :page-size="pageSize"
        layout="total, prev, pager, next ,jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    colums: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
    tableHeight: {
      type: Number,
    },
    showPage:{
      type:Boolean,
      default:true,
    }
  },
  data() {
    return {
      //分页数据
      currentPage: 1,
      pageSize: 10,
      multipleSelection: [],
    };
  },
  components: {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    sortChange(column, prop, order) {
      if (this.$listeners["sortChange"]) {
        this.$emit("sortChange", column, prop, order);
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      if (this.$listeners["handleSelectionChange"]) {
        this.$emit("handleSelectionChange", val);
      }
    },
    // 分页的功能
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      if (this.$listeners["handleSizeChange"]) {
        this.$emit("handleSizeChange", this.currentPage, this.pageSize);
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      if (this.$listeners["handleCurrentChange"]) {
        this.$emit("handleCurrentChange", this.currentPage, this.pageSize);
      }
    },
    // 设置分页页码
    setCurrentPage(num) {
      this.currentPage = num;
    },
    // 设置分页pagesize
    setPageSize(num) {
      this.pageSize = num;
    },
    rowClick(row, column, event){
      this.$emit("rowClick", row,column,event);
    }
  },
};
</script>

<style scoped lang="scss">
.sort-table-wrap {
  height: 100%;
  //margin-bottom: 20px;
}
</style>
<style lang="scss">
.sort-table-wrap {
  height: 100%;
  .el-pagination .el-select .el-input {
    width: 100px !important;
  }
  .el-pagination__editor.el-input {
    width: 50px !important;
  }
}
</style>
