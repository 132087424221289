import {AxiosError} from 'axios';
import {Notification} from 'element-ui';


export function axiosErrorStr(prefix, error) {
  let msg = prefix;
  //判断是不是AxiosError
  if (error && error.response) {
    if (error.response.data) {
      if (typeof error.response.data === 'string') {
        msg = prefix + ' ' + error.response.data;
      } else if (typeof error.response.data === 'object') {
        if (error.response.data.detail) {
          msg = prefix + ' ' + error.response.data.detail;
        } else if (error.response.data.message) {
          msg = prefix + ' ' + error.response.data.message;
        } else {
          msg = prefix + ' ' + JSON.stringify(error.response.data);
        }
      } else {
        msg = prefix + ' ' + error.response.data;
      }
    } else {
      msg = prefix + ' ' + error.response.statusText;
    }
  } else if (error && error.data) {
    if (typeof error.data === 'string') {
      msg = prefix + ' ' + error.data;
    } else if (typeof error.data === 'object') {
      if (error.data.detail) {
        msg = prefix + ' ' + error.data.detail;
      } else if (error.data.message) {
        msg = prefix + ' ' + error.data.message;
      } else {
        msg = prefix + ' ' + JSON.stringify(error.data);
      }
    } else {
      msg = prefix + ' ' + error.data;
    }
  } else {
    msg = prefix + ' ' + error.response.statusText;
  }
  return msg;
}

/**
 *
 * @param prefix {string}
 * @param error {AxiosError<any, any> | any }
 */
export function axiosErrorNotify(prefix, error) {
  let msg = axiosErrorStr(prefix, error);
  Notification.error({title: '请求失败', message: msg, duration: 5 * 1000});
}
