// 数据库名称
var dbName = 'drainage';
// 版本
var version = 1;
// 数据库数据结果
var db;

// 打开数据库
var DBOpenRequest = window.indexedDB.open(dbName, version);

// 如果数据库打开失败
DBOpenRequest.onerror = function(event) {
    logError('数据库打开失败');
};
// 数据库打开成功后
DBOpenRequest.onsuccess = function(event) {        
    // 存储数据结果
    db = DBOpenRequest.result;
    window.db = db;
};
// 下面事情执行于：数据库首次创建版本，或者window.indexedDB.open传递的新版本（版本数值要比现在的高）
DBOpenRequest.onupgradeneeded = function(event) {
    var db = event.target.result;
    
    db.onerror = function(event) {
        logError('数据库打开失败');
    };
 
    // 创建一个数据库存储对象
    var objectStore = db.createObjectStore(dbName, { 
        keyPath: 'id',
        autoIncrement: true
    });

    // 定义存储对象的数据项
    objectStore.createIndex('outid', 'outid');
    objectStore.createIndex('value', 'value');
    objectStore.createIndex('type','type');
};