import {basicApi} from "@/apis/basicApi";


class DutySituationApi extends basicApi{

    constructor(props) {
        super(props);
    }

    /*查询当前值班信息*/
    getCurrentDutyInfo(params,showLoading=true){
        return basicApi.$_$_axios.get("/schedule/plan/current", {
            params,
            showLoading: showLoading,
        });
    }


    /*查询值班记录列表*/
    getDutyRecordList(params,showLoading=true){
        return basicApi.$_$_axios.get("/schedule/plan/list", {
            params,
            showLoading: showLoading,
        });
    }


    /*查询交班日志*/
    getHandoverList(params,showLoading=true){
        return basicApi.$_$_axios.get("/schedule/plan/log/list", {
            params,
            showLoading: showLoading,
        });
    }

    /*查询交班记录统计*/
    getHandoverStatic(startTime,endTime){
        return basicApi.$_$_axios.get("/schedule/plan/log/statistics", {
            params:{
                startTime,
                endTime,
            },
            showLoading: false,
        });
    }


    /*查询巡检记录*/
    getInspectList(params,showLoading=true){
        return basicApi.$_$_axios.get("/schedule/plan/patrol/list", {
            params,
            showLoading: showLoading,
        });
    }

    /*查询巡检统计信息*/
    getInspectStatic(params,showLoading=false){
        return basicApi.$_$_axios.get("/schedule/plan/patrol/statistics", {
            params,
            showLoading: showLoading,
        });
    }

    /*查询事件记录*/
    getEventList(params,showLoading=false){
        return basicApi.$_$_axios.get("/schedule/plan/event/statistics", {
            params,
            showLoading: showLoading,
        });
    }

    /*事件记录统计查询*/
    getEvenStatic(params,showLoading=false){
        return basicApi.$_$_axios.get("/schedule/plan/event/statistics", {
            params,
            showLoading: showLoading,
        });
    }


    /*查询当前班次的详细信息*/
    getScheduleDetailInfo(showLoading=false){
        return basicApi.$_$_axios.get("/schedule/plan/current", {
            showLoading: showLoading,
        });
    }

    /*接班*/
    takeOnDuty(showLoading=false){
        return basicApi.$_$_axios.post("/schedule/plan/takeOver");
    }

    /*交班*/
    handleOver(){
        return basicApi.$_$_axios.post(`/schedule/plan/handOver`, {
            showLoading: false,
        });
    }


    // 获取值班报表
    getTaskReport(businessType){
        return basicApi.$_$_axios.get(`/report/v2/manage/${businessType}`, {
            showLoading: false,
        });
    }


    /*查询当前用户是否需要接班*/
    isTakeOnDuty(){
        return basicApi.$_$_axios.get("/schedule/plan/takeOver/check")
    }

    /*巡检历史记录*/
    getRecordList(params){
        return basicApi.$_$_axios.get("/schedule/location/patrol/v2/task/record/list",{params})
    }

    /*巡检历史路径*/
    getHistoryPath(params){
        return basicApi.$_$_axios.get("/schedule/location/userLocationHistory/geojson",{params})
    }

    /*维修单*/
    getRepairList(params){
        return basicApi.$_$_axios.get("/schedule/location/repairListWithLocation",{params})
    }

    /*巡检关联摄像机*/
    getPictureByInspection(params){
        return basicApi.$_$_axios.get("/schedule/location/userNvrChannelInfo",{params})
    }

    /*查询人员信息*/
    getPeople(params){
        return basicApi.$_$_axios.get("/schedule/location/userInfo/list",{params})
    }

    /*查询任务名称*/
    getTaskName(params){
        return basicApi.$_$_axios.get("/schedule/location/patrol/v2/task/list",{params})
    }
}

export default DutySituationApi
