<template>
  <div class="nvr-wrap">
<!--    <LivePlayer-->
<!--      :videoUrl="url"-->
<!--      fluent-->
<!--      autoplay-->
<!--      live-->
<!--      stretch-->
<!--      class="live_player"-->
<!--      aspect="fullscreen"-->
<!--    ></LivePlayer>-->
<!--    <H5PlayerHK :url="url" :szId="szId" v-if="type===2"></H5PlayerHK>-->

<!-- by罗奇熠: H5PlayerIcc应该是大华的播放器，可以完全废弃了  -->
<!--    <H5PlayerIcc :url="url" :szId="szId" :serverAdress="serverAdress" v-if="type===3 && icc" :channel-id="data.channelId"></H5PlayerIcc>-->
    <mpegtsComponent :url="url" :szId="szId" :serverAdress="serverAdress"  :channel-id="data.channelId"></mpegtsComponent>
    <div class="ptz-div"  :style="{display:!display?'none':''}">
<!--      <div class="ptz-div" :class="{ hide: false }" :style="{display:!display?'':''}">-->
<!--      <i @click="show1 = !show1" v-if="show1" class="el-icon-s-fold"></i>-->
<!--      <i @click="show1 = !show1" v-else class="el-icon-s-unfold"></i>-->
<!--      <PTZ @ifChangePreset="ifChangePreset" ref="ptz" :style="{opacity:show1?0:1}" class="ptz" :type="type" :video-data="data"></PTZ>-->
      <PTZNEW @ifChangePreset="ifChangePreset" ref="ptz"  class="ptz" :type="type" :video-data="data"></PTZNEW>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import PTZ from "./PTZ";
import LivePlayer from "@liveqing/liveplayer";
import H5PlayerHK from "@/components/h5PlayerHK";
import H5PlayerIcc from "@/components/h5PlayerIcc";
import PTZNEW from "@/components/PTZ_new"
import mpegtsComponent from "@/components/mpegtsComponent";
export default {
  props: {
    url: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: true,
    },
    type: {
      type: Number,
      defalut: 1, // 老大华是1，海康是2,3是icc
    },
    data: {
      type: Object,
      default: () => {},
    },
    display:{
      type:Boolean,
      default:true
    },
    szId:{
      type: String,
      default: "player",
    },
    serverAdress:{
      type:String,
      default:""
    },
    icc:{
      type:Boolean,
      default:true
    }
  },
  // provide() {
  //   return {
  //     type: this.type,
  //     videoData: this.data,
  //   };
  // },
  data() {
    return {
      show1: this.show,
    };
  },
  components: { PTZ, LivePlayer,H5PlayerHK ,H5PlayerIcc,PTZNEW,mpegtsComponent},
  mounted() {
  },
  computed: {},
  watch: {
    data:{
      handler(newVal, oldVal){
      },
      deep: true
    }
  },
  methods: {
    /*回到预置点*/
    gotoPreset(presetIndex){
      this.$refs.ptz.goPreset(presetIndex)
    },
    ifChangePreset(data){
      if(this.$listeners["ifChangePreset"]){
        this.$emit("ifChangePreset",true)
      }
    }
  },
};
</script>

<style scoped lang="scss">
.nvr-wrap {
  position: relative;
  height: 100%;
  .hide {
    right: -180px !important;
  }
  .ptz-div {
    position: absolute;
    bottom: -18px;
    //left: 0;
    //transform: translate(-50%, -50%);
    //width: 100%;
    right: -60px;
    transform: translate(-50%,-50%);
    transition: 0.5s;

    i {
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-100%);
      color: rgb(0, 174, 255);
      font-size: 20px;
      &:hover {
        color: rgb(0, 174, 255);
      }
      &:active {
        color: rgb(0, 225, 255);
      }
      cursor: pointer;
    }
    .hide-ptz{
      opacity: 0;
    }
  }
}
</style>
