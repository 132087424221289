import dayjs from "dayjs";
// 将blob 文件转换成xls
export const toExcel = (data, name,fileType=".xls") => {
    let url = window.URL.createObjectURL(new Blob([data]));
    let link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    link.setAttribute("download", name + fileType);
    document.body.appendChild(link);
    link.click();
    link.remove();
};


export const csvToExcel = (data, name) => {
    if (!data) {
        this.$message.error("下载失败，解析数据为空！");
        return;
    }
    let url = window.URL.createObjectURL(
        new Blob([`\ufeff${data}`], {type: "text/plain;charset=utf-8"})
    );
    let link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    link.setAttribute("download", name + ".csv");
    document.body.appendChild(link);
    link.click();
    link.remove();
};
// 将list转换成树形菜单
export const listToTree = (list) => {
    //遍历整个列表
    return list.filter((cur) => {
        // 获取当前节点的子节点
        let children = list.filter((item) => item.parentOrgId == cur.orgId);
        if (children.length > 0) {
            cur.children = children;
        }
        //只返回顶级节点
        return cur.parentOrgId == null;
    });
};

// 确认删除弹窗
export const confirm = (vue, tips, successFunc, cancelFunc) => {
    vue
        .$confirm(tips, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        })
        .then(() => {
            successFunc();
        })
        .catch(() => {
            cancelFunc && cancelFunc();
            this.$message({
                type: "info",
                message: "取消操作",
            });
        });
};

// 更改ht 路径前缀
export const prefixHT = (resourcePath) => {
    let profix = process.env.VUE_APP_LOGO_URP;
    let path = profix + resourcePath + "/storage/";
    window.prefixHT = path;
};

export const  prefixCesium = (resourcePath,storagePath) =>{
    let profix = process.env.VUE_APP_LOGO_URP;
    let path = profix + resourcePath + "/"+storagePath;
    console.log(path,"path")
    return path;
}


/*获取url后面的参数*/
export const getQueryString = (name) => {
    let hashVal = window.location.hash
    let value = hashVal.split("?")
    if (value.length >= 2) {

    }
    return null;
}

export const GetRequest = () => {
    let url = location.hash.split("?"); //获取url中"?"符后的字串\
    let val = url[1]
    if (!val) {
        return null
    } else {
        val = "?" + val
    }
    let theRequest = {};
    if (val.indexOf("?") !== -1) {
        let str = val.substr(1);
        let strs = str.split("&");
        for (let i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
    }
    return theRequest;
}

export const dictFilter = (dict) => {
    return dict.filter(item => {
        return item.status
    })
}

export const findDisplayName = (id, option) => {
    let displayName = ""
    for (let i = 0; i < option.length; i++) {
        if (option[i].detailId == id) {
            displayName = option[i].displayName
            break
        }
    }
    return displayName
}


/*导出excel*/
export const downloadExcel = (data, name) => {
    var blob = new Blob([data], {type: "application/vnd.ms-excel"});
    var objectUrl = URL.createObjectURL(blob);
    var a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display:none');
    a.setAttribute('href', objectUrl);
    var filename = `${name}`;
    a.setAttribute('download', filename);
    document.body.removeChild(a);
    a.click();
}


/*获取挡墙月份及该月有多少天*/
export const getDays = (date) => {
//构造当前日期对象
    if (date === undefined) {
        date = new Date();
    } else {
        date = new Date(date);
    }


//获取年份
    var year = date.getFullYear();

//获取当前月份
    var mouth = date.getMonth() + 1;
    if (mouth < 10) {
        mouth = "0" + mouth
    }

//定义当月的天数；
    var days;

//当月份为二月时，根据闰年还是非闰年判断天数
    if (mouth == 2) {
        days = year % 4 == 0 ? 29 : 28;

    } else if (mouth == 1 || mouth == 3 || mouth == 5 || mouth == 7 || mouth == 8 || mouth == 10 || mouth == 12) {
        //月份为：1,3,5,7,8,10,12 时，为大月.则天数为31；
        days = 31;
    } else {
        //其他月份，天数为：30.
        days = 30;

    }


    let startTime = dayjs(year + "-" + mouth + "-01" + " 00:00:00").toISOString()
    let endTime = dayjs(year + " " + mouth + "-" + days + " 00:00:00").toISOString()
    return {
        startTime,
        endTime
    }
}


/*将table 表格转换成excel 下载*/
export const downLoadCustomExcel = (worksheet, tableStr) => {
    var uri = "data:application/vnd.ms-excel;base64,";
    // 真正要导出（下载）的HTML模板
    var exportTemplate = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel"
                xmlns="http://www.w3.org/TR/REC-html40">
                <head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
                    <x:Name>${worksheet}</x:Name>
                        <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
                    </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
                </head>
                <body>
                    ${tableStr}
                </body>
                </html>`;
    //下载模板
    var a = document.createElement("a");
    a.download = worksheet + ".xls";
    a.href = uri + window.btoa(unescape(encodeURIComponent(exportTemplate)));
    a.click();
}
/*
* 获取浏览器网址后面的参数
*
* */
export const getSearchString = (key, Url) => {
    let str = Url;
    str = str.substring(str.indexOf("?"), str.length); // 获取URL中?之后的字符（去掉第一位的问号）
    str = str.substring(1, str.length);  /*去掉？*/
    // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
    let arr = str.split("&");
    let obj = new Object();
    // 将每一个数组元素以=分隔并赋给obj对象
    for (let i = 0; i < arr.length; i++) {
        let tmp_arr = arr[i].split("=");
        obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
    }
    return obj[key];
}


/*
* 生成uuid
*
* */
export const uuid = () => {
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "-";

    var uuid = s.join("");
    return uuid;
}

/*ip地址 0.0.0.0~255.255.255.255*/
export function validateIP(str) {
    const re =
        /^(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|[0-9])\.((1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)\.){2}(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)$/
    return re.test(str)
}

export const UseJump = () => {
    return {
        jumpToKpi(path, unifiedLoginUrl) {
            let protocol = document.location.protocol; // 'https:' or 'http:'
            window.location.replace(`${unifiedLoginUrl}?return=${path}/#/kpi&opt=clearNo&msg=` + "请登录");
            // window.open(`${unifiedLoginUrl}?return=${path}/#/dashboard&opt=clearNo&msg=` + "请登录")
        },
        jumpToPath(path, unifiedLoginUrl){
            let protocol = document.location.protocol; // 'https:' or 'http:'
            window.location.replace(`${unifiedLoginUrl}?return=${path}/#/dashboard&opt=clearNo&msg=` + "请登录");
        }
    }
}
