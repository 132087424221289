const state = {
  nodeList: undefined,
  content: [],
  currentNode: "", // 选中当前的巡检单元
  currentNodeIndex: 0, // 当前巡检单位索引（位置）
  nextDisabled: false,
  currentContentIndex: 0, // 当前巡检单元的当前巡检节点（位置）
  isExcute: true, // 执行任务还是查看任务
  nodeState: false, // 筛选当前巡检单元的状态
  taskType: 1,
};

const mutations = {
  SET_NODELIST(state, data) {
    state.nodeList = data;
  },
  SET_CURRENT_CONTENT(state, data) {
    state.content = data;
  },
  SET_CURRENT_NODE(state, data) {
    state.currentNode = data;
  },
  SET_CURRENTNODEINDEX(state, data) {
    state.currentNodeIndex = data;
  },
  SET_NEXTDISABLED(state, data) {
    state.nextDisabled = data;
  },
  SET_CURRENTCONTENTINDEX(state, data) {
    state.currentContentIndex = data;
  },
  SET_ISEXCUTE(state, data) {
    state.isExcute = data;
  },
  SET_NODESTATE(state, data) {
    state.nodeState = data;
  },
  SET_TASKTYPE(state, data) {
    state.taskType = data;
  },
};
const actions = {};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
