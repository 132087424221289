<template>
  <div class="earth-sdk-wrap" ref="earthContainer" id="earthContainer">


  </div>
</template>

<script>
export default {
  name: "myEarthSdkModel",
  props: {
    url:{
      type:String,
      default:""
    },
    optionSetting:{
      type:Object,
      default:()=>{
        return {
          // 这里设置Viewer的配置，和new Viewer(container, options)中的options一致
          animation: false,    //左下角的动画仪表盘
          baseLayerPicker: false, // 底图切换控件
          geocoder: false, // 地理位置查询定位控件
          homeButton: false, // 默认相机位置控件
          infoBox: false,//是否显示信息框
          sceneModePicker: false,//是否显示3D/2D选择器
          timeline: false,    //底部的时间轴
        }
      }
    },
    xbsjUseOriginTransform:{
      type:Boolean,
      default:true
    }
  },
  data() {
    return {
      _earth: undefined, // 注意：Earth和Cesium的相关变量放在vue中，必须使用下划线作为前缀！
      _viewer:undefined, //
      _sceneTree:undefined,
    }
  },
  mounted() {

    // 等待cesium资源加载完成时执行
    XE.ready().then(() => {
      this.startUp();

      // 设置默认的视图系统
      this.setDefaultViewer(this._viewer);
    })
  },
  methods: {
    startUp() {
      let earth = new XE.Earth(this.$refs.earthContainer, this.optionSetting);
      earth.interaction.picking.enable = true;
      let viewer = earth.czm.viewer;
      earth.sceneTree.root = {
        "children": [
          {
            "czmObject": {
              ref: "myTileSet",
              "name": "测试三维瓦片数据", // 可以不填写
              "xbsjType": "Tileset", // 必填项
              "url": this.url, // 必填项
              "xbsjUseOriginTransform": false, // 按照模型原有的位置加载
                "skipLevelOfDetail": false,
                "xbsjPosition": [
                  2.0966858068938654,
                  0.5114677319872892,
                  -8
                ],
                "xbsjRotation": [
                  1.117010721276371,
                  0,
                  0
                ],
            }
          },
        ]
      };

      // // 路径和上述json配置保持一致
      const tileset = earth.sceneTree.root.children[0].czmObject;
      // tileset.onclick = (pick)=>{
      //   console.log(pick.getProperty("id"),111111)
      // }
      //
      // // 1.2.4 数据双向绑定
      // // disposers 用来收集资源销毁函数，并在析构时自动调用！
      this._disposers = [];

      // // 监听模型的变化，当模型加载完成时，执行
      this._disposers.push(XE.MVVM.watch(tileset, 'ready',ready=>{
        if (ready){
          // tileset.flyTo();
          console.log("模型加载完成")
          if(this.$listeners['ready']){
            this.$emit('ready',{
              tileset, // 模型
              viewer,   // 视图系统
              earth,   // 整个地球系统
              sceneTree:earth.sceneTree, // 场景树
              camera:viewer.camera // 视图相机系统
            })
          }


        }
      }));

      // // 1.2.5 变量记录
      this._earth = earth;
      this._viewer = viewer;
      this._sceneTree = earth.sceneTree



      // only for Debug
      window.earth = earth;
      window.tileset = tileset;
    },

    // viewer设置
    setDefaultViewer(viewer){
      viewer.scene.sun.show = false;
      viewer.scene.moon.show = false;
      viewer.scene.skyBox.show = false;

      viewer.scene.undergroundMode = true;
      viewer.scene.globe.show = false;
      viewer.scene.screenSpaceCameraController.minimumZoomDistance = -500;//相机的高度的最小值
      viewer.scene.screenSpaceCameraController.maximumZoomDistance = 500;  //相机高度的最大值
      viewer.scene.skyBox = new Cesium.GroundSkyBox({//SkyBox--GroundSkyBox
        sources: {
          // positiveX : "sky/1/posx.png",
          // negativeX : "sky/1/negx.png",
          // positiveY : "sky/1/posz.png",
          // negativeY : "sky/1/negz.png",
          // positiveZ : "sky/1/posy.png",//
          // negativeZ : "sky/1/negy.png"//
          positiveX: "sky/9/negz.png",
          negativeX: "sky/9/negx.png",
          positiveY: "sky/9/posz.png",
          negativeY: "sky/9/posx.png",
          positiveZ: "sky/9/posy.png",//
          negativeZ: "sky/9/negy.png"//
        },
        show: true
      });
    },


  },
  // 1.2 资源销毁
  beforeDestroy() {
    // vue程序销毁时，需要清理相关资源
    this._disposers.forEach(d => d());
    this._disposers.length = 0;
    this._earth = this._earth && this._earth.destroy();
  },

  // 获取earth变量
  getEarth() {
    return this._earth;
  },
  // 获取 viewer
  getViewer(){
    return this._viewer;
  },
  // 获取场景树
  getSceneTree(){
    return this._sceneTree;
  },
  getEarthAndViewerAndSceneTree(){
    return {
      earth:this.getEarth(),
      views:this.getViewer(),
      scene:this.getSceneTree(),
    }
  }
}
</script>

<style scoped lang="scss">
.earth-sdk-wrap {
  width: 100%;
  height: 100%;
}
</style>
