import axios from "./axios";
import xjrAxios from "@/apis/xjrAxios";

export const basicApi = class basicApi {

    getUrl = "/"
    addUrl = "/"
    delUrl = "/"
    modUrl = "/"
    findByIdUrl = "/"
    axios = axios
    xjrAxios =xjrAxios

    // 构造私有属性
    static $_$_axios = axios

    // 构造器
    constructor(props = {
        getUrl: "",
        addUrl: "",
        delUrl: "",
        modUrl: "",
        findByIdUrl: "",
    }) {
        this.getUrl = props.getUrl && props.getUrl
        this.addUrl = props.addUrl && props.addUrl
        this.delUrl = props.delUrl && props.delUrl
        this.modUrl = props.modUrl && props.modUrl
        this.findByIdUrl = props.findByIdUrl && props.findByIdUrl
        this.axios = basicApi.$_$_axios
    }


    // get list
    getList(params, showLoading = true) {
        return basicApi.$_$_axios.get(this.getUrl, {
            params,
            showLoading: showLoading,
        });
    }


    // add
    add(params, showLoading = true) {
        return basicApi.$_$_axios.post(this.addUrl, params, {
            headers: {
                "node-Type": "application/json",
            },
            showLoading,
        });
    }

    // delete
    del(id, showLoading = true) {
        return basicApi.$_$_axios.delete(`${this.delUrl}/${id}`, {
            showLoading,
        });
    }

    // batch delete
    delBatch(params, showLoading = true) {
        return basicApi.$_$_axios.delete(this.delUrl, {
            showLoading,
            params,
        });
    }

    // modify
    modify(params, showLoading = true) {
        return basicApi.$_$_axios.put(this.modUrl, params, {
            headers: {
                "node-Type": "application/json",
            },
            showLoading,
        });
    }

    // 根据id 获取
    findById(id, showLoading = true){
        return basicApi.$_$_axios.get(this.findByIdUrl+"/"+id, {
            showLoading: showLoading,
        });
    }

    /*是否删除历史数据*/
    deleteHistory(kpiId, ifDeleteValue, showLoading = false) {
        return basicApi.$_$_axios.delete(`/kpi/${kpiId}/${ifDeleteValue}`, {
            showLoading,
        });
    }
}


