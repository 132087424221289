import axios from "../axios";
import qs from "qs";
const tsApi = {
  tsQuery(params) {
    /**
     * ? scada数据查询
     * {
            "from": "2020-11-01T08:38:24.860Z",
            "metricId": "8a8d80b675a703110175a70445490039",
            "to": "2020-11-09T08:38:24.860Z"
            }
     */
    return axios.post("/ts/query", params, { showLoading: true });
  },
  tsQueryAsync(params){
    return axios.post("/ts/query/async", params);
  },

  // 获取阈值信息  /metric/{metricId}/threshold
  getThreshold(metricId){
    return axios.get(`/metric/${metricId}/threshold`, {
      showLoading: false,
    });
  },


  // multiQueryV2
  multiQueryV2(params){
    return axios.post(`/ts/multiQueryV2`,params, {
      showLoading: false,
    });
  }




};

export default tsApi;
