<template>
    <div class="toolpage">
        <span class="shike">{{moments}}</span>
        <div class="shijian">
            <span>{{week}}</span>
            <span>{{time}}</span>
        </div>
        <i class="drainage weather" :class="{'drainageduoyun':tadayWeather=='多云','drainagewuqi':tadayWeather=='雾','drainagexiaoyu':tadayWeather=='小雨',
        'drainagefeng':tadayWeather=='风','drainagelieri':tadayWeather=='晴','drainageyujiaxue':tadayWeather=='雨夹雪','drainagedaxue':tadayWeather=='大雪','drainagezhongxue':tadayWeather=='中雪',
        'drainagedayu':tadayWeather=='大雨','drainageyin':tadayWeather=='阴'}"></i>
        <i class="drainage drainagejinru ibtn"></i>
        <i class="drainage drainagetuichu ibtn"></i>
      <!-- <i style="margin:0 10px;cursor: pointer" title="kpi" @click="jumpToPath" class="ibtn drainage drainageKPIfenxi"></i> -->
    </div>
</template>

<script>
import moment from "moment"
export default {
    data () {
        return {
            time:"",
            moments:"",
            week:"",
            tadayWeather:""
        }
    },
    mounted () {
        setInterval(()=>{
            this.getTime();
        },1000);
        this.getWeatherList();
    },
    methods: {
        getTime(){
            let year = moment().year(),
            month = moment().month().toString().length>1?moment().month()+1:'0'+(moment().month()+1),
            day = moment().date().toString().length>1?moment().date():'0'+moment().date(),
            hour = moment().hour().toString().length>1?moment().hour():'0'+moment().hour(),
            minute = moment().minute().toString().length>1?moment().minute():'0'+moment().minute(),
            second = moment().second().toString().length>1?moment().second():'0'+moment().second();
            this.week = moment().weekday()==0?'星期日':'星期' + (moment().weekday()==1?'一':moment().weekday()==2?"二":moment().weekday()==3?"三":moment().weekday()==4?"四":moment().weekday()==5?"五":"");
            this.time = year+'-'+month+'-'+day;
            this.moments = hour+':'+minute;
        },
        getWeatherList(){
            let params = {
                locationId:"金华"
            };
            this.$api.getWeatherList(params).then(res=>{
                if(res.status==200){
                    let weatherText = res.data.filter(val=>{
                        return val.date==moment().format("YYYY-MM-DD");
                    })
                    this.tadayWeather = weatherText[0].textDay
                }
            })
        }
    }
}
</script>

<style scoped>
    .toolpage{
        /* width: 100%;
        height: 100%; */
        display: flex;
        align-items: center;
    }
    .shike{
        font-size: 34px;
    }
    .shijian{
        display: flex;
        flex-direction: column;
        color: #90C8FF;
        margin-left: 10px;
    }
    .weather,.ibtn{
        color: #90C8FF;
        font-size: 23px;
        margin-left: 10px;
    }
    .ibtn{
        color: #fff;
        margin-left: 40px;
    }
</style>
