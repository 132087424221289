import axios from "./axios";
import qs from "qs";
const reportApi = {
  getReport(params) {
    return axios.get("/report/v2/manage/page", { params });
  },
  reportAddList(params){
    return axios.post("/report/v2/manage",params)
  },
  reportDeleteList(id){
    return axios.delete("/report/v2/manage/"+id)
  },
  reportEditSelect(params){
    return axios.put("/report/v2/manage",params)
  },
  getreporttable(){
    return axios.get("/report/v2/manage/exportExcel")
  },
  downloadtable(){
    return axios.get("/report/v2/manage/importTemplate")
  }
};
export default reportApi;
