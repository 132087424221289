export default (Vue)=>{
    Vue.directive("drag", function(oDiv){
        oDiv.onmousedown = function(ev) {
            if (!$(ev.target).hasClass("isdrag")) {
                return;
            }
            var disX = ev.clientX - oDiv.offsetLeft;
            var disY = ev.clientY - oDiv.offsetTop;
            document.onmousemove = function(ev) {
                var l = ev.clientX - disX;
                var t = ev.clientY - disY;
                // if(l<50){l=50};
                // if(t<50){t=56};
                oDiv.style.left = l + "px";
                oDiv.style.top = t + "px";
            };
            document.onmouseup = function() {
                document.onmousemove = null;
                document.onmouseup = null;
            };
        };
    })
}