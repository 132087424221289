import axios from "./axios";

export const crafTwins = {
  crafTwins: {
    // 查询列表
    getList(params) {
      return axios.get("/digitalTwin/model/list", {
        params,
        showLoading: true,
      });
    },
    // 新增巡检内容
    add(params) {
      return axios.post("/digitalTwin/model", params, {
        headers: {
          "node-Type": "application/json",
        },
        showLoading: true,
      });
    },
    // 删除巡检内容
    del(modelId) {
      return axios.delete(`/digitalTwin/model/${modelId}`, {
        showLoading: true,
      });
    },
    // 修改巡检内容
    modify(params) {
      return axios.put("/digitalTwin/model", params, {
        headers: {
          "node-Type": "application/json",
        },
        showLoading: true,
      });
    },

    // 上传文件
    uploadZip(data) {
      return axios.post("/digitalTwin/model", data, {
        // headers: {
        //   "Content-Type": "application/multipart/form-data",
        // },
        showLoading: true,
      });
    },
  },
};
