import axios from "../axios";
import qs from "qs";

const tableApi = {
    tableList(params) {
        /**
         * 查询表节点列表
         * @params {Number} page 第几页
         * @params {Number} size 页的数量
         */
        return axios.get("/metric/metricTable/list", {params});
    },
    createTableNode(params) {
        /**
         * 新增电表节点
         * {
    "desc": "string",
    "name": "string",
    "parentMetricTableId": "string"
    "type":"string"
  }
         */
        return axios.post("/metric/metricTable", params, {
            headers: {
                "Content-Type": "application/json",
            },
            showLoading: true,
        });
    },
    deleteTableNode(params, deleteChildMetrics) {
        /**
         * 删除
         */
        return axios.delete("/metric/metricTable/" + params, {params: {deleteChildMetrics: deleteChildMetrics}}, {
            showLoading: true,
        });
    },
    editTableNode(url, params) {
        /**更新 */
        return axios.put("/metric/metricTable/" + url, params, {
            headers: {
                "Content-Type": "application/json",
            },
            showLoading: true,
        });
    },
    //获取站点类型列表
    getMetricTableType(params) {
        return axios.get("/metric/metricTableType/list", {params})
    },
    //获取scada树结构
    getScadaTreeData() {
        return axios.get("/metric/metricTable/tree");
    },
    // 根据metricTableId 查询下面拥有的指标
    getMetricIdsWithMetricTable(rootMetricTableIds) {
        return axios.get(`/metric/metricTable/tree`, {
            params: {
                rootMetricTableIds,
                withMetric:true
            }
        });
    }
};
export default tableApi;
