<template>
  <div class="wi_chart">
    <wi-chart @magictypechanged="magictypechanged" :group="group" :options="myOptions" autoresize ref="scadaline"/>
  </div>
</template>

<script>
import Echarts from 'vue-echarts';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/tooltip';
import 'echarts';


export default {
  components: {
    'wi-chart': Echarts
  },
  data() {
    return {
      myChart: null,
      myOptions: null,
    }
  },
  props: {
    options: Object,
    group: {
      type: String,
      default: "group",
    },
  },
  watch: {
    options(newVal) {
      this.myOptions = newVal
    }
  },
  mounted() {
    this.myOptions = this.options;
    console.log(this.group,"group");

  },
  methods: {
    refreshData(data) {
      this.$refs.scadaline.mergeOptions({
        series: [{
          data: data
        }]
      });
    },
    refreshSeries(series){
      this.$refs.scadaline.mergeOptions({
        series
      });
    },
    clearChart() {
      this.$refs.scadaline.clear();
    },
    getInstance() {
      return this.$refs.scadaline
    },
    magictypechanged(item) {
      // // this.clearChart();
      // console.log(this.$refs.scadaline)
      // this.$refs.scadaline.refresh()

      //
      let options = this.$refs.scadaline.options
      let beforeType = options.series[0].type;
      if (item.currentType === "bar") {
        if (beforeType === "bar") {
          return false;
        }
        options.series.forEach(obj => {
          obj.type = "bar"
        })
        this.clearChart();
        this.myOptions = options;
      } else {
        if (beforeType === "line") {
          return false;
        }
        options.series.forEach(obj => {
          obj.type = "line"
        })
        this.clearChart();
        this.myOptions = options;
      }
    },

    // 触发时刻表z`
    dispatchTips(name) {
      console.log("aaaaaaaaaaaaaa")
      this.$refs.scadaline.dispatchAction({
        type: 'showTip',
        // 系列的 index，在 tooltip 的 trigger 为 axis 的时候可选。
        seriesIndex: 0,
        // 数据项的 index，如果不指定也可以通过 name 属性根据名称指定数据项
        dataIndex: name,
        // 可选，数据项名称，在有 dataIndex 的时候忽略
        // name: "17:37 11-29"
        // 本次显示 tooltip 的位置。只在本次 action 中生效。
        // 缺省则使用 option 中定义的 tooltip 位置。
        //position: number[] | string | Function,
      })
    },
    showTip(data) {
      console.log(data, "asdfafaafdf")
    }
  },
}
</script>

<style lang="less" scoped>
.wi_chart {
  width: 100%;
  height: 100%;
}

.echarts {
  width: 100%;
  height: 100%;
}
</style>
