<template>
  <div class="testVedio-wrap">
    <div :id="szId"></div>
    <!--    <div class="play-stop">-->
    <!--      <i class="drainage drainage24gf-playCircle" @click="realplay" v-if="isPlay"></i>-->
    <!--      <i class="drainage drainage24gf-pause2" @click="stopPlay" v-if="!isPlay"></i>-->
    <!--    </div>-->
    <div class="tool-box" v-show="isShowToolBox" @mouseenter="enterToolBox" @mouseout="outToolBox">
      <div class="tool-content">
        <i class="drainage drainage24gf-playCircle" @click="realplay" v-if="isPlay"></i>
        <i class="drainage drainage24gf-pause2" @click="stopPlay" v-if="!isPlay"></i>
        <i class="drainage drainagequanping2" @click="wholeFullScreen"></i>
      </div>
    </div>
  </div>
</template>

<script>
const MSE_IS_SUPPORT = !!window.MediaSource // 是否支持mse
import LivePlayer from "@liveqing/liveplayer";
import NVR from "@/components/NVR";

export default {
  name: "testVedio",
  components: {LivePlayer, NVR},
  props: {
    url: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      player: null,
      urls: {
        realplay: "ws://192.168.1.215:559/openUrl/CpLhJJu",  // 直播播放地址
        talk: 'wss://10.41.163.126:6014/proxy/10.41.163.126:559/EUrl/6gFx47S', // 通话地址
        playback: 'wss://10.41.163.126:6014/proxy/10.41.163.126:559/EUrl/6gFx47S'  // 回放地址
      },
      videoUrl: "ws://192.168.1.215:559/openUrl/NXchpcs",
      isPlay: true,
      isShowToolBox: true,
      szId:"player"+this.uuid(),
    }
  },
  mounted() {
    this.createPlayer();
  },
  computed: {
    mode: function () {
      // return this.tabActive === 'mse' ? 0 : 1
      return 1
    }
  },
  watch: {
    url(newVal, oldVal) {
      if (newVal) {
        // this.createPlayer();
        this.stopPlay();
        this.realplay();
      }
    }
  },
  methods: {
    createPlayer() {
      this.player = new window.JSPlugin({
        szId: this.szId,
        szBasePath: "./h5Player/",
        iMaxSplit: 1,
        iCurrentSplit: 1,
        openDebug: true,
        oStyle: {
          borderSelect: '#000',
        }
      })
      if (this.url){
        this.realplay();
      }

      let _this = this;

      // 事件回调绑定
      this.player.JS_SetWindowControlCallback({
        windowEventSelect: function (iWndIndex) {  //插件选中窗口回调
          console.log('windowSelect callback: ', iWndIndex);
        },
        pluginErrorHandler: function (iWndIndex, iErrorCode, oError) {  //插件错误回调
          console.log('pluginError callback: ', iWndIndex, iErrorCode, oError);
        },
        windowEventOver: function (iWndIndex) {  //鼠标移过回调
          //console.log(iWndIndex);
          _this.isShowToolBox = true;
        },
        windowEventOut: function (iWndIndex) {  //鼠标移出回调
          //console.log(iWndIndex);
          _this.isShowToolBox = false;
        },
        windowEventUp: function (iWndIndex) {  //鼠标mouseup事件回调
          //console.log(iWndIndex);
        },
        windowFullCcreenChange: function (bFull) {  //全屏切换回调
          console.log('fullScreen callback: ', bFull);
        },
        firstFrameDisplay: function (iWndIndex, iWidth, iHeight) {  //首帧显示回调
          console.log('firstFrame loaded callback: ', iWndIndex, iWidth, iHeight);
        },
        performanceLack: function () {  //性能不足回调
          console.log('performanceLack callback: ');
        }
      });


    },
    /* 预览&对讲 */
    realplay() {
      if (!this.url) {
        return false;
      }

      let {player, mode, urls} = this,
          index = player.currentWindowIndex,
          playURL = this.url;
      player.JS_Play(playURL, {playURL, mode}, index).then(
          () => {
            console.log('realplay success')
            this.isPlay = false;
          },
          e => {
            console.error(e)
          }
      )
    },

    /*停止预览*/
    stopPlay() {
      this.player.JS_Stop().then(
          () => {
            this.isPlay = true;
            console.log('stop realplay success')
          },
          e => {
            console.error(e)
          }
      )
    },

    /*全屏*/
    wholeFullScreen() {
      this.player.JS_FullScreenDisplay(true).then(
          () => {
            console.log(`wholeFullScreen success`)
          },
          e => {
            console.error(e)
          }
      )
    },

    /*移动进toolbox*/
    enterToolBox() {
      this.isShowToolBox = true;
    },

    outToolBox() {
      this.isShowToolBox = false;
    },
    uuid() {
      var s = [];
      var hexDigits = "0123456789abcdef";
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = "-";

      var uuid = s.join("");
      return uuid;
    }

  }
}
</script>

<style scoped lang="scss">
.testVedio-wrap {
  height: 100%;
  position: relative;

  & > div {
    height: 100%;
    width: 100%;
  }

  #player {
    height: 100%;
    width: 100%;
  }

  .play-stop {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    color: #fff;
    z-index: 200;

    & > i {
      font-size: 50px;
    }
  }

  .tool-box {
    width: 100%;
    height: 60px;
    position: absolute;
    bottom: 0px;
    z-index: 200;
    background-color: #fbfbfb8c;
    cursor: pointer;

    .drainage {
      cursor: pointer;
      font-size: 50px;
    }

    .tool-content {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
