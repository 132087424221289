<template>
  <div class="my-download-wrap">
    <el-button :type="type" @click.stop="dowload">
      <slot></slot>
    </el-button>
  </div>
</template>

<script>
import { toExcel } from "@/util/commonUtils";
export default {
  props: {
    url: {
      type: String,
      default: () => "",
    },
    name: {
      type: String,
      default: () => "下载",
    },
    type: {
      type: String,
      default: "primary",
    },
    useALabel:{
      type:Boolean,
      default:false
    },
    param:{
      type:Object,
      default:()=>{return null}
    },
    stopDownload:{
      type:Boolean,
      default:false
    },
    fileType:{
      type:String,
      default:".xls"
    }
  },
  data() {
    return {};
  },
  components: {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    dowload() {

      if (this.useALabel){

        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display:none');
        a.setAttribute('href', this.url);
        a.setAttribute('download', this.name);
        document.body.removeChild(a);
        a.click();
        return false;
      }
      if(this.$listeners['beforeDownload']){
        this.$emit("beforeDownload")
      }
      this.$nextTick(()=>{
        if (this.stopDownload){
          return false;
        }
        this.$api.commonApi
            .exportExcel(this.url,this.param)
            .then((res) => {
              toExcel(res.data, this.name,this.fileType);
            })
            .catch((e) => {
              this.$message.error("下载失败");
            });
      })

    },
  },
};
</script>

<style scoped lang="scss">
.my-download-wrap {
  display: inline-block;
}
</style>
