import PlayerItem from './PlayerItem'
import {Spinner} from './spin'
import CONSTANT from './CONSTANT'
const PlayerControl = window.PlayerControl;
/* ---------------- PlayerItem ---------------- */
class RealPlayerItem extends PlayerItem {
    /**
     * @param {*} opt.wrapperDomId 父级id
     * @param {*} opt.index 索引
     */
    constructor(opt) {
        super(opt)
        this.canvasId = `${this.domId}-livecanvas`
        this.videoId = `${this.domId}-liveVideo`
        this.initDom()
        this.defaultStatus = $('.default-status', this.$el)
        this.error = $('.error', this.$el)
        this.controller = $('.player-control', this.$el)
        this.initMouseEvent()
        /**
         * this.state 当前Player状态
         * created, ready, playing, pause, stop, closed, error
         */
        this.setStatus('created')
    }
    /**
     * 播放器模板
     */
    getTemplate() {
        let template = `
        <div id="${this.domId}" class="wsplayer-item wsplayer-item-${this.index} ${this.index === 0 ? 'selected' : 'unselected'}">
            <div class="full-content flex">
                <canvas id="${this.canvasId}" class="kind-stream-canvas" kind-channel-id="0" width="800" height="600"></canvas>
                <video id="${this.videoId}" class="kind-stream-canvas" kind-channel-id="0" muted style="display:none" width="800" height="600"></video>
            </div>
            <div class="default-status">
                <img src="./static/WSPlayer/icon/default.png" alt="">
            </div>
            <div class="player-control top-control-bar">
                <span class="stream-info"></span>
                <div class="opt-icons">
                    <div class="opt-icon audio-icon off"></div>
                    <div class="opt-icon capture-icon"></div>
                    <div class="opt-icon close-icon"></div>
                </div>
            </div>
            <div class="error">
                <div class="error-message"></div>
            </div>
        </div>
        `
        return template
    }
    /**
     * 事件监听
     */
    initMouseEvent() {
        super.initMouseEvent()
        this.hideTimer = null
        this.$el.on('mouseenter mousemove', (evt) => {
            if (this.status === 'playing') {
                this.hideTimer && clearTimeout(this.hideTimer)
                this.setDomVisible($('.player-control', $(`#${this.domId}`)), true)
            }
        })
        this.$el.on('mouseleave', (evt) => {
            this.hideTimer = setTimeout(() => {
                this.setDomVisible($('.player-control', $(`#${this.domId}`)), false)
            }, 300)
        })
    }
    /**
     * 设置状态，同时控制组件显示
     * created, playing, pause, stop, closed, error
     */
    setStatus(status, msg) {
        this.status = status
        switch (this.status) {
            case 'created':
            case 'closed':
                this.setDomVisible(this.defaultStatus, true)
                this.setDomVisible(this.error, false)
                this.setDomVisible(this.controller, false)
                this.videoElem.src = ''
                $('.audio-icon', this.$el).removeClass('on').addClass('off')
                break;
            case 'ready':
            case 'playing':
            case 'pause':
                this.setDomVisible(this.defaultStatus, false)
                this.setDomVisible(this.error, false)
                break;
            case 'error':
                this.setDomVisible(this.defaultStatus, false)
                $('.error-message', this.$el).text(CONSTANT.errorInfo[msg.errorCode] ? CONSTANT.errorInfo[msg.errorCode] : CONSTANT.errorInfo['defaultErrorMsg'])
                this.setDomVisible(this.error, true)
                break;
            default:
                break;
        }
    }
    /**
     * 初始化播放器
     * @param {*} options.rtspURL
     * @param {*} options.decodeMode 可选参数
     * @param {*} options.wsURL 可选参数
     */
    init(options) {
        // testCode
        // options.rtspURL = "rtsp://sdfasdf/"
        this.options = options
        if (this.player) {
            this.player.close()
        }
        if (this.spinner) {
            this.spinner.stop()
        }
        this.spinner = new Spinner({
            color: '#ffffff'
        }).spin(this.$el[0])
        let self = this
        this.player = new PlayerControl(Object.assign({
            wsURL: this.wsPlayer.wsURL
        }, options))
        this.setStatus('ready')
        this.player.on('ResolutionChanged', function (e) {
            console.log(e)
        });
        this.player.on('PlayStart', function (e) {
            console.log(e)
            self.setStatus('playing')
        });
        this.player.on('DecodeStart', function (e) {
            console.log('DecodeStart', e)
            self.spinner.stop()
            if (e.decodeMode === 'video') {
                self.videoElem.style.display = '';
                self.canvasElem.style.display = 'none';
            } else {
                self.videoElem.style.display = 'none';
                self.canvasElem.style.display = '';
            }
            $('.stream-info', $(`#${self.domId}`)).text(`${e.encodeMode}, ${e.width}*${e.height}`)
        });
        this.player.on('UpdateCanvas', function (e) {
            if (self.firstTime === 0) {
                self.firstTime = e.timestamp;//获取录像文件的第一帧的时间戳
            }
        });
        this.player.on('GetFrameRate', function (e) {
            console.log('GetFrameRate: ', e)
        });
        this.player.on('FrameTypeChange', function (e) {
            console.log('FrameTypeChange: ', e)
        });
        this.player.on('Error', function (e) {
            self.spinner.stop()
            console.log('Error: ' + JSON.stringify(e))
            self.setStatus('error', e)
        });
        this.player.on('MSEResolutionChanged', function (e) {
            console.log('MSEResolutionChanged: ', e)
        });
        this.player.on('audioChange', function (e) {
            console.log('audioChange: ', e)
        });
        this.player.on('IvsDraw', function (e) {
            console.log('IvsDraw: ', e)
        });
        this.player.on('WorkerReady', function () {
            console.log('WorkerReady')
            self.player.connect();
        })
        // this.player.on('FileOver', function (e) {
        //     console.log('FileOver: ', e)
        // });
        this.player.on('Waiting', function (e) {
            console.log('Waiting: ', e)
        });
        this.player.on('UpdateTime', function (e) {
            console.log('UpdateTime: ', e)
        });

        this.player.init(this.canvasElem, this.videoElem);
    }
}
export default RealPlayerItem