import axios from "../axios";
import qs from "qs";
const eventApi = {
  getAccidentInter(params){
    return axios.post('/es/custom/indicator',params)
  },
  getEvent(params) {
    /**
     *
     * @params {Number} page 第几页
     * @params {Number} size 页的数量
     * @params array[string] businessTypes  业务类型
     * @params {string} endTime 结束时间
     * @params {string} startTime 开始时间
     * @params {integer} level 报警等级
     * @params {string} name 事件名称
     * @params array[string] sort 排序
     */
    return axios.get("/schedule/plan/event/list", { params });
  },
  getEventStatistical(params) {
    /**
     *
     * @params {string} eq 匹配的条件，统计所有时该字段不填
     * @params {string} field  Available values : alertLevel, countAll, releaseAlarmStatus
     * @params {string} from
     * @params {string} to
     */
    return axios.get("/alert/event/summary", { params });
  },
  getScadaqueryId(params) {
    /**
     *
     * @params {string} eventId
     */
    return axios.get("/alert/event/" + params);
  },
  getEventStatisticalList(params) {
    /**
     *
     * @params {string} summaryName
     */
    return axios.get("/sys/ui/summary/" + params);
  },
  putStatistcal(paramas) {
    return axios.put("/sys/ui/summary", paramas);
  },
  getEventTable(paramas) {
    return axios.get("/sys/ui/table/" + paramas);
  },
  putEventTable(paramas) {
    return axios.put("/sys/ui/table", paramas);
  },
  sequentialscada(params){
    return axios.post("/ts/multiQueryV2",params,{
      headers: {
        "Content-Type": "application/json",
        },
    })
  },
  // 根据通道名称查询通道信息
  getChannelInfoByName(channelName ) {
    return axios.get(`/nvrChannel/name/channel`, {
      params:{
          channelName,
      }
    });
  },
  limittimenewestscada(params) {
    return axios.post("/ts/multiQueryLatestV2", params,{
        headers: {
        "Content-Type": "application/json",
        },
    });
  },
  getWithXjrEamRepairList(params){
    return axios.get("/patrol/v2/error/task/record/video/list/withXjrEamRepairList",{params});
  },
  manualStatuesChange(paramas){
    return axios.put("/patrol/v2/error/task/record/video/manualAbnormalStatus",paramas);
  },
  wrongTurnRepair(paramas){
    return axios.post("/patrol/v2/error/task/record/video/toRepair",paramas,{
        headers: {
        "Content-Type": "application/json",
        },
    })
  },
  turnEventReleast(paramas){
    return axios.post("/alert/event/release",paramas,{
      headers: {
      "Content-Type": "application/json",
      }
    })
  }
};
export default eventApi;
