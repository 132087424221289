<template>
  <div class="mpegts-wrap"
       v-loading="loading"
       element-loading-text="拼命加载中"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)">
    <video :id="el"  class="videoElement" controls muted="muted" autoplay="autoplay"></video>
  </div>
</template>

<script>
import {uuid} from "@/util/commonUtils"

export default {
  name: "index",
  components: {},
  props: {
    url: {
      type: String,
      default: "",
    },
    hasAudio: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      el: "videoElement" + new Date().valueOf(),
      loading: false,
    }
  },
  watch: {
    url(newVal) {
      if (newVal) {
        console.log(newVal,"播放地址")
        // 停止加载之前的视频
        this.destroy();
        this.$nextTick(() => {
          // this.videoElement.pause();
          // 加载新的视频
          this.createPlayer();
        })

      } else {
        // this.unLoad();
        this.destroy();
      }
    }
  },
  mounted() {
    console.log(this.url,"播放地址")
    this.createPlayer();
    window.vue = this;
  },
  methods: {
    // 创建播放器，并加载视频和播放
    createPlayer() {
      this.loading = true;
      var videoElement = document.getElementById(this.el);
      this.videoElement = videoElement;
      this.flvPlayer = flvjs.createPlayer({
        type: 'flv',
        // url: 'ws://zlm.yiwu.wid.waterstrategy.tech/live/test.live.flv',
        // url: 'ws://zlm.yiwu.wid.waterstrategy.tech/live/北沉淀池.live.flv',
        url: this.url,
        isLive: true,
        hasAudio: false  //大华摄像头的音频编码不是acc，是pcma，浏览器无法播放
      }, {
        enableStashBuffer: false,// Enable IO stash buffer. Set to false if you need realtime (minimal latency) for live stream playback, but may stalled if there's network jittering.
        isLive: true,
      });
      this.flvPlayer.attachMediaElement(videoElement);
      this.flvPlayer.load();
      clearTimeout(this.timer);
      this.$nextTick(() => {
        this.timer = setTimeout(() => {
          this.loading = false;
          this.flvPlayer.play();
          videoElement.play();

        }, 2500)
      })


    },

    // 停止加载视频
    unLoad() {
      if (this.flvPlayer) {
        this.flvPlayer.unLoad();
      }
    },

    // 暂停播放
    pause() {
      if (this.flvPlayer) {
        this.flvPlayer.pause();
      }
    },

    // 销毁
    destroy() {
      if (this.flvPlayer) {
        // this.flvPlayer.unLoad();
        this.flvPlayer.destroy();
      }
    }

  },

  beforeDestroy() {
    console.log(2323)
    this.destroy();
  }

}
</script>

<style scoped lang="scss">
.mpegts-wrap {
  height: 100%;
  width: 100%;

  .videoElement {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}
</style>
