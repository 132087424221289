import axios from "../axios";
export const taskMangerApi = {
  taskMangerApi: {
    // 查询列表
    getList(params) {
      return axios.get("/patrol/v2/task/list", {
        params,
        showLoading: true,
      });
    },
    // 新增任务
    add(params) {
      return axios.post("/patrol/v2/task", params, {
        showLoading: true,
      });
    },
    // 修改任务
    update(params) {
      return axios.put("/patrol/v2/task", params, {
        showLoading: true,
      });
    },
    // 删除任务
    del(id) {
      return axios.delete("/patrol/v2/task/" + id, {
        showLoading: true,
      });
    },
    // 查询任务
    check(id) {
      return axios.get("/patrol/v2/task/" + id, {
        showLoading: true,
      });
    },

    // 启用任务
    startWork(id){
      return axios.put("/patrol/v2/task/enabled/" + id, {
        showLoading: true,
      });
    },
    // 停用任务
    stopWork(id){
      return axios.put("/patrol/v2/task/disabled/" + id, {
        showLoading: true,
      });
    },




    // 根据任务类型查询任务列表
    checkTaskList(type) {
      return axios.get(`/patrol/task/name/${type}/list`, {
        showLoading: true,
      });
    },
    // 结束任务
    cancelTask(taskId) {
      return axios.put(`/patrol/task/cancel/${taskId}`, {
        showLoading: true,
      });
    },
    // 根据任务记录查询该任务所有节点内容
    getDetailTaskContent(recordId) {
      return axios.get(`/patrol/v2/task/record/video/${recordId}/group/region/channel`, {
        showLoading: true,
      });
    },
    // 修改任务记录内容
    modifyTask(params) {
      return axios.put(`/patrol/task/record/node/content`, params, {
        showLoading: true,
      });
    },
    // 根据nvrdevicename查询出通道id
    checkChannelId(params) {
      return axios.get(`/nvrDevice/name/channel`, {
        showLoading: true,
        params,
      });
    },
    // 统计数据查询
    getStaticData(params) {
      return axios.get(`/patrol/v2/task/record/statistics`, {
        showLoading: true,
        params,
      });
    },
    // 根据通道名称查询通道信息
    getChannelInfoByName(channelName ) {
      return axios.get(`/nvrChannel/name/channel`, {
        showLoading: true,
        params:{
            channelName,
        }
      });
    },






    /*任务记录*/
    getTaskRecord(params){
      return axios.get("/patrol/v2/task/record/list", {
        params,
        showLoading: true,
      });
    },


    /*排班管理*/
    getTaskRecordShift(params){
      return axios.get("/schedule/plan/patrol/list", {
        params,
        showLoading: true,
      });
    },


    /*修改视频任务-异常正常状态*/
    editTaskContentStatus(params){
      return axios.put("/patrol/v2/task/record/list",params, {
        showLoading: true,
      });
    },

    /*查询任务记录详细信息*/
    getTaskRecordDetail(recordId){
      return axios.get(`/patrol/v2/task/record/video/${recordId}`, {
        showLoading: true,
      });
    },



    // 获取人员信息
    getUserInfoList(){
      return axios.get("/userInfo/list",{
        params:{
          page:0,
          size:9999999
        }
      })
    },

    //查询人员岗位
    getPostList(){
      return axios.get('/patrol/v2/task/worker/userInfo/post/list')
    }


  },
};
