<template>
  <el-dialog :title="title" :visible.sync="Visible" @close="closeDialog()">
    <div class="modal-content">
      <div class="modal-body">
        <form class="form-inline" autocomplete="off" spellcheck="false">
          <div class="form-group form-group-sm">
            <label style="marginRight:10px">搜索:</label>
            <el-input
            style="width:30%"
              type="text"
              class="form-control"
              placeholder="关键字"
              v-model.trim="q"
              @keydown.enter.prevent
              ref="q"
            />
          </div>
        </form>
        <br />
        <el-table
          :data="channels"
          stripe
          @sort-change="sortChange"
          :max-height="500"
          @row-click="rowClick"
          :row-style="rowStyle"
          ref="channelTable"
          v-loading="loading"
          element-loading-text="加载中..."
        >
          <el-table-column
            prop="DeviceID"
            label="设备国标编号"
            min-width="200"
            show-overflow-tooltip
            sortable="custom"
            :fixed="true"
          ></el-table-column>
          <el-table-column
            prop="ID"
            label="通道国标编号"
            min-width="200"
            show-overflow-tooltip
            sortable="custom"
            :fixed="true"
          ></el-table-column>
          <el-table-column
            prop="Name"
            label="通道名称"
            min-width="120"
            :formatter="formatName"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column prop="Status" label="在线状态" min-width="100">
            <template slot-scope="props">
              <span v-if="props.row.SubCount > 0">-</span>
              <span v-else-if="props.row.Status == 'ON'" class="text-success"
                >在线</span
              >
              <span v-else>离线</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="SubCount"
            label="子节点数"
            min-width="100"
            sortable="custom"
          ></el-table-column>
          <el-table-column
            prop="Manufacturer"
            label="厂家"
            min-width="120"
            :formatter="formatManufacturer"
            show-overflow-tooltip
          ></el-table-column>
        </el-table>
        <el-pagination
          v-if="total > 0"
          layout="total,prev,pager,next"
          :pager-count="5"
          class="pull-right"
          :total="total"
          :page-size.sync="pageSize"
          :current-page.sync="currentPage"
        ></el-pagination>
        <div class="clearfix"></div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog()">取 消</el-button>
      <el-button type="primary" @click="closeDialog()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import _ from "lodash";
import $ from "jquery";
import "jquery-ui/ui/widgets/draggable";

export default {
  props: {
    title: {
      default: "",
    },
    size: {
      type: String,
      default: "modal-lg",
    },
    fade: {
      type: Boolean,
      default: false,
    },
    dialogVisible: {
      default: false,
    },
  },
  data() {
    return {
      Visible: false,
      q: "",
      total: 0,
      pageSize: 10,
      currentPage: 1,
      sort: "",
      order: "",
      loading: false,
      channels: [],
      index: 0,
    };
  },
  watch: {
    q: function (newVal, oldVal) {
      this.doDelaySearch();
    },
    currentPage: function (newVal, oldVal) {
      this.doSearch(newVal);
    },
    pageSize: function (newVal, oldVal) {
      this.doSearch();
    },
    dialogVisible: function (newVal, oldVal) {
      this.Visible = this.dialogVisible;
    },
  },

  mounted() {
    $(this.$el).find(".modal-content").draggable({
      handle: ".modal-header",
      cancel: ".modal-title span",
      addClasses: false,
      containment: "document",
      delay: 100,
      opacity: 0.5,
    });
    $(this.$el)
      .on("shown.bs.modal", () => {
        this.$emit("show");
      })
      .on("hidden.bs.modal", () => {
        this.$emit("hide");
      });
  },
  methods: {
    closeDialog() {
      this.Visible = false;
      this.$emit("closeDialog");
    },
    sortChange(data) {
      this.sort = data.prop;
      this.order = data.order == "ascending" ? "asc" : "desc";
      this.getChannels();
    },
    doSearch(page = 1) {
      this.currentPage = page;
      this.getChannels();
    },
    doDelaySearch: _.debounce(function () {
      this.doSearch();
    }, 500),
    formatName(row, col, cell) {
      return row.CustomName || row.Name || "-";
    },
    formatManufacturer(row, col, cell) {
      if (cell) return cell;
      return "-";
    },
    //发送ajax获取频道信息
    getChannels() {
      console.log("发送ajax获取频道信息");
    },
    rowClick(row, event, column) {
      if (row.Status !== "ON") return;
      this.$emit("selected", this.index, row);
      this.hide();
    },
    rowStyle({ row, rowIndex }) {
      if (row.Status === "ON") {
        return "cursor:pointer";
      }
      return "";
    },
    reset() {
      this.index = 0;
      this.channels = [];
      this.q = "";
      this.currentPage = 1;
      this.pageSize = 10;
    },
    show(index) {
      console.log(1111);
      this.index = index;
      $(this.$el).modal("show");
      this.getChannels();
    },
    hide() {
      console.log(2222);
      $(this.$el).modal("hide");
    },
  },
};
</script>

<style lang="less" scoped>
label.el-switch {
  margin-bottom: -10px;
}
</style>
