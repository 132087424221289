let drainageApi = {};
drainageApi.csvJSON = function(csv){
    var lines = csv.split("\n");
    var result = [];
    var headers = lines[0].split(",");
    for (var i = 1; i < lines.length; i++) {
        var obj = {};
        if (lines[i]) {
        var currentline = lines[i].split(",");

        currentline[0] = currentline[0].slice(1, -1);
        currentline[0] = new Date(currentline[0]);
        currentline[1] = (Number(currentline[1])== undefined?null:Number(currentline[1]));
        for (var j = 0; j < headers.length; j++) {
            obj[headers[j]] = currentline[j];
        }

        result.push(obj);
        } else {
        continue;
        }
    }
    return result;
}
drainageApi.Uint8ArrayToString = function(fileData) {
    var dataString = "";
    for (var i = 0; i < fileData.length; i++) {
      dataString += String.fromCharCode(fileData[i]);
    }
    return dataString
},
drainageApi.getLayerById = function(mymap, layerId) {
    var mylayer = null;
    var layers = mymap.getLayers();
    for(var i=0;i<layers.getLength();i++){
        var layer = layers.item(i);
        if(layer.get("id")==layerId){
            mylayer = layer;
            break;
        }
    }
    return mylayer;
},
drainageApi.install = function(Vue,option){
    Vue.prototype.$drainageApi = drainageApi
    Vue.drainageApi = drainageApi
}
export default drainageApi;
