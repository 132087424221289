<template>
  <div class="parame-config-wrap">
    <i
      class="drainage drainagexitong-gongnengpeizhi configuration"
      @click="configurationPopOne = !configurationPopOne"
    ></i>
    <transition name="el-zoom-in-top">
      <div class="insteresttransfer_container" v-if="configurationPopOne">
        <el-transfer
          v-model="selectInsterestCy"
          :data="allSummaryData_"
          :titles="['可选项', '已选项']"
        ></el-transfer>
        <el-button
          type="primary"
          class="submit_btn"
          @click="submitInsterestIndicators"
          >确定</el-button
        >
      </div>
    </transition>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  props: {
    selectInsterest: {
      type: Array,
      default: () => [],
    },
    allSummaryData_: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      configurationPopOne: false,
      selectInsterestCy: [],
    };
  },
  components: {},
  mounted() {
    this.selectInsterestCy = this._.cloneDeep(this.selectInsterest);
  },
  computed: {},
  watch: {},
  methods: {
    submitInsterestIndicators() {
      if (this.$listeners["submit"]) {
        this.$emit("submit", this.selectInsterestCy);
      }
    },
  },
};
</script>

<style scoped lang="less">
.parame-config-wrap {
  height: 100%;
  position: relative;
  .insteresttransfer_container {
    width: 28vw;
    position: absolute;
    right: 30px;
    top: 10px;
    z-index: 10;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    display: flex;
    // align-items:center;
    .submit_btn {
      width: 12%;
      position: absolute;
      left: 44%;
      bottom: 30%;
    }
    /deep/ .el-transfer {
      display: flex;
      // align-items: center;
      width: 100%;
    }
    /deep/ .el-transfer-panel {
      width: 44%;
    }
    /deep/ .el-transfer__buttons {
      width: 12%;
      padding: 0;
      display: flex;
      flex-direction: column;
      margin-top: 12%;
    }
    /deep/ .el-button + .el-button {
      margin-left: 0px;
    }
  }
}
</style>
