import axios from "../axios";
import qs from "qs";
const gislegendapi = {
    getlegendgroupInter(params){
        return axios.get('/gis/legend/group/list',{params})
    },
    addlegendgroupInter(params){
        return axios.post('/gis/legend/group',params)
    },
    addcategoryInter(params){
        return axios.post('/gis/legend/category',params)
    },
    getcategoryInter(params){
        return axios.get('/gis/legend/category/list',{params})
    },
    deletecategoryInter(categoryId){
        return axios.delete('/gis/legend/category/'+categoryId)
    },
    addLegendInter(params){
        return axios.post('/gis/legend',params)
    },
    getLegendListInter(params){
        return axios.get('/gis/legend/list',{params})
    },
    editCategoryInter(params){
        return axios.put('/gis/legend/category',params)
    },
    deleteLegendInter(id){
        return axios.delete('/gis/legend/'+id)
    }
}
export default gislegendapi;