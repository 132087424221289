
/*
* 无人巡检store
*
* */


const state ={
    pollingList:[], // 巡检数据
    currentUnitIndex:0, // 当前单元位置，区域位置
    cameraIndex:0, // 当前单元位置下的摄像头的位置
    contentIndex:0, // 当前摄像头下的巡检项的位置
    // nextDisabled:false, // 下一步是否禁用
    manualExecute:true, // 手动执行 true 为手动，false 为自动


    unitSelected:0, // 选中的单元位置
}

const mutations={
    SET_POLLINGLIST(state,data){
        state.pollingList = data;
    },
    SET_UNITINDEX(state,data){
        state.currentUnitIndex = data;
    },
    SET_cameraIndex(state,data){
        state.cameraIndex = data;
    },
    SET_contentIndex(state,data){
        state.contentIndex = data;
    },
    // SET_nextDisabled(state,data){
    //     state.nextDisabled = data;
    // },
    SET_manualExecute(state,data){
        state.manualExecute = data;
    },

    SET_unitSelected(state,data){
        state.unitSelected = data;
    },

}



const actions = {};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
