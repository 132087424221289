<template>
  <div class="earth-sdk-wrap" :id="eleId">
    <div class="home" @click="flyHome">
      <i class="drainage drainagerhome-fill"></i>
    </div>
    <!--    <div class="featurename">{{featureName}}</div>-->
  </div>
</template>

<script>
const uuid = () => {
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  var uuid = s.join("");
  return uuid;
}
export default {
  name: "earthSDKUI",
  props: {
    modelurl: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "模型"
    },
    displayTool: {
      type: Boolean,
      default: true,  // 模型显示工具
    },
    xbsjUseOriginTransform:{
      type:Boolean,
      default:true  // 默认true 按模型原有位置加载
    }
  },
  data() {
    return {
      _earth: undefined, // 注意：Earth和Cesium的相关变量放在vue中，必须使用下划线作为前缀！
      _viewer: undefined, //
      _sceneTree: undefined,
      _tileset: undefined,
      featureName: "",
      _earthUI: undefined,
      eleId: "main",
    }
  },
  watch: {
    modelurl(value) {
      if (this._tileset) {
        this._tileset.url = value;
        this._tileset.name = this.name;
      }

    }
  },
  mounted() {
    let _this = this;
    this.createUI();


  },
  methods: {
    getFeatureName() {
      let that = this;
      this._viewer.screenSpaceEventHandler.setInputAction(function onLeftClick(evt) {
        // 获取点击的feature
        const feature = that._viewer.scene.pick(evt.position);
        that.featureName = feature.getProperty("name")
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    },
    createUI() {

      /*解决XbsjEarthUI 不能在一个项目里create多个*/
      if (window.XbsjEarthUICopy) {
        XbsjEarthUICopy.create(this.eleId).then(earthUI => {
          this._earthUI = earthUI;
          window.earthUI = earthUI;
          //隐藏主工具栏
          earthUI.controls.mainBar.show = false;
          //隐藏图层管理
          earthUI.tools.sceneTree.show = false;
          // earthUI.tools.EntityMore.show = true;
          if (this.displayTool) {
            earthUI.tools.modelTree.show = true;
            earthUI.earth.interaction.picking.enabled = true;
            
          } else {
            earthUI.tools.modelTree.show = false;
            earthUI.earth.interaction.picking.enabled = false;
            // earthUI.tools.EntityMore.show = true;
          }

          console.log(earthUI.tools)
          // only for Debug
          window.uia = earthUI;
          // _this.startUp(earthUI.earth)
          earthUI.earth.sceneTree.root = {
            "expand": true,
            "title": "项目",
            "children": [
              {
                "expand": true,
                "title": "项目",
                "children": [
                  {
                    "czmObject": {
                      "xbsjType": "Tileset",
                      "name": this.name,
                      "url": this.modelurl,
                      // url:"test/tileset.json",
                      "xbsjUseOriginTransform": this.xbsjUseOriginTransform, // 按照模型原有的位置加载
                      "skipLevelOfDetail": false,
                      "xbsjPosition": [
                          2.1267872843478,
                          0.5138675713654908,
                          -15.68
                      ],
                      "xbsjRotation": [
                          5.899212871740834,
                          0,
                          0
                      ],
                    },
                    "enabled": true
                  }
                ]
              },{
                "czmObject": {
                  "name": "高德地图",
                  "xbsjType": "Imagery",
                  "xbsjImageryProvider": {
                    "XbsjImageryProvider": {
                      "url": "//webst02.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}",
                      "srcCoordType": "GCJ02",
                      "dstCoordType": "WGS84",
                      "maximumLevel": 18,                         
                    },
                  }
                }
              }]
          };

          // earthUI.earth.cameraViewManager = {
          //   "lastView": {
          //     "position": [
          //       1.9017075410506683,
          //       0.5972352467498696,
          //       465.5130429801045
          //     ],
          //     "rotation": [
          //       5.8887642878142685,
          //       -0.8581361944659949,
          //       6.281345779298704
          //     ],
          //     "fov": 1.0471975511965976,
          //     "near": 0.1,
          //     "far": 10000000000
          //   }
          // }

          window.uia = earthUI;
          this.startUp(earthUI.earth)
          this.setDefaultViewer(this._viewer)
          // this.getFeatureName();
        })
      } else {
        window.XbsjEarthUICopy = this._.cloneDeep(XbsjEarthUI)
        XbsjEarthUI.create(this.eleId).then(earthUI => {
          this._earthUI = earthUI;
          window.earthUI = earthUI;
          //隐藏主工具栏
          earthUI.controls.mainBar.show = false;
          //隐藏图层管理
          earthUI.tools.sceneTree.show = false;
          // earthUI.tools.EntityMore.show = true;
          if (this.displayTool) {
            earthUI.tools.modelTree.show = true;
            earthUI.earth.interaction.picking.enabled = true;
          } else {
            earthUI.tools.modelTree.show = false;
            earthUI.earth.interaction.picking.enabled = false;
            // earthUI.tools.EntityMore.show = true;
          }

          console.log(earthUI.tools)
          // only for Debug
          window.uia = earthUI;
          // _this.startUp(earthUI.earth)
          earthUI.earth.sceneTree.root = {
            "expand": true,
            "title": "项目",
            "children": [
              {
                "expand": true,
                "title": "项目",
                "children": [
                  {
                    "czmObject": {
                      "xbsjType": "Tileset",
                      "name": this.name,
                      "url": this.modelurl,
                      // url:"test/tileset.json",
                      // "xbsjPosition": [
                      //   1.9016974701882112,
                      //   0.5972325152147303,
                      //   425.8641913624607
                      // ],
                      "xbsjUseOriginTransform": this.xbsjUseOriginTransform, // 按照模型原有的位置加载
                      "skipLevelOfDetail": false,
                     "xbsjPosition": [
                          2.1267872843478,
                          0.5138675713654908,
                          -15.68
                      ],
                      "xbsjRotation": [
                          5.899212871740834,
                          0,
                          0
                      ],
                    },
                    "enabled": true
                  }
                ]
              },{
                "czmObject": {
                  "name": "高德地图",
                  "xbsjType": "Imagery",
                  "xbsjImageryProvider": {
                    "XbsjImageryProvider": {
                      "url": "//webst02.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}",
                      "srcCoordType": "GCJ02",
                      "dstCoordType": "WGS84",
                      "maximumLevel": 18,                         
                    },
                  }
                }
              }]
          };

          // earthUI.earth.cameraViewManager = {
          //   "lastView": {
          //     "position": [
          //       1.9017075410506683,
          //       0.5972352467498696,
          //       465.5130429801045
          //     ],
          //     "rotation": [
          //       5.8887642878142685,
          //       -0.8581361944659949,
          //       6.281345779298704
          //     ],
          //     "fov": 1.0471975511965976,
          //     "near": 0.1,
          //     "far": 10000000000
          //   }
          // }

          window.uia = earthUI;
          this.startUp(earthUI.earth)
          this.setDefaultViewer(this._viewer)
          // this.getFeatureName();
        })
      }


    },

    startUp(earth) {
      if (!this.displayTool) {
        earth.interaction.picking.enabled = false;
        window.uia._statusBar.show = false; // 影藏模型状态栏
      } else {
        earth.interaction.picking.enabled = true;
      }

      let viewer = earth.czm.viewer;

      // earth.sceneTree.root = {
      //   "expand": true,
      //   "title": "项目",
      //   "children": [{
      //     "expand": true,
      //     "title": "项目",
      //     "children": [{
      //       "czmObject": {
      //         "xbsjType": "Tileset",
      //         "name": "三维瓦片1x",
      //         "url": this.modelurl,
      //         // "xbsjPosition": [
      //         //   1.9016974701882112,
      //         //   0.5972325152147303,
      //         //   425.8641913624607
      //         // ],
      //         "skipLevelOfDetail": false
      //       },
      //       "enabled": true
      //     }]
      //   }]
      // };

      // // 路径和上述json配置保持一致
      const tileset = earth.sceneTree.root.children[0].children[0].czmObject;

      // tileset.onclick = (pick) => {
      //   console.log(pick.getProperty("id"), 111111)
      //   // this.featureName = pick.getProperty("name")
      // }
      //
      // // 1.2.4 数据双向绑定
      // // disposers 用来收集资源销毁函数，并在析构时自动调用！
      this._disposers = [];

      // // 监听模型的变化，当模型加载完成时，执行
      this._disposers.push(XE.MVVM.watch(tileset, 'ready', ready => {
        if (ready) {
          // tileset.flyTo();
          if (this.$listeners['ready']) {
            this.$emit('ready', {
              tileset, // 模型
              viewer,   // 视图系统
              earth,   // 整个地球系统
              sceneTree: earth.sceneTree, // 场景树
              camera: viewer.camera, // 视图相机系统
              earthUI: this._earthUI
            })
          }


        }
      }));

      // // 1.2.5 变量记录
      this._earth = earth;
      this._viewer = viewer;
      this._sceneTree = earth.sceneTree
      this._tileset = tileset

      // only for Debug
      window.earth = earth;
      window.tileset = tileset;
    },
    flyHome() {
      tileset.flyTo();
    },
    // viewer设置
    setDefaultViewer(viewer) {
      viewer.scene.sun.show = false;
      viewer.scene.moon.show = false;
      viewer.scene.skyBox.show = false;

      // viewer.scene.undergroundMode = true;
      // viewer.scene.globe.show = false;
      // viewer.scene.screenSpaceCameraController.minimumZoomDistance = -200;//相机的高度的最小值
      // viewer.scene.screenSpaceCameraController.maximumZoomDistance = 20000;  //相机高度的最大值
      viewer.scene.skyBox = new Cesium.GroundSkyBox({//SkyBox--GroundSkyBox
        sources: {
          // positiveX : "sky/1/posx.png",
          // negativeX : "sky/1/negx.png",
          // positiveY : "sky/1/posz.png",
          // negativeY : "sky/1/negz.png",
          // positiveZ : "sky/1/posy.png",//
          // negativeZ : "sky/1/negy.png"//
          positiveX: "sky/9/negz.png",
          negativeX: "sky/9/negx.png",
          positiveY: "sky/9/posz.png",
          negativeY: "sky/9/posx.png",
          positiveZ: "sky/9/posy.png",//
          negativeZ: "sky/9/negy.png"//



        },
        show: true
      });
    },
  },
  // 1.2 资源销毁
  beforeDestroy() {
    // vue程序销毁时，需要清理相关资源
    // this._disposers.forEach(d => d());
    // this._disposers.length = 0;
    // this._earth = this._earth && this._earth.destroy();
    console.log(XbsjEarthUI, 2232)
    // XbsjEarthUI = undefined;
    // new XbsjEarthUI; // 销毁ui
  },
}
</script>

<style lang="scss">
.xbsj-model-close {
  display: none !important;
}

</style>
<style scoped lang="scss">
.earth-sdk-wrap {
  height: 100%;



}

.home {
  position: absolute;
  right: 15px;
  bottom: 15px;
  width: 30px;
  height: 30px;
  border: 1px solid #aaa;
  background: rgba(0, 0, 0, .2);
  display: flex;
  align-content: center;
  align-items: center;
  z-index: 1;
  border-radius: 5px;
}

.drainagerhome-fill {
  color: #fff;
  margin: auto;
}

.featurename {
  position: absolute;
  bottom: 40px;
  right: 10px;
  z-index: 1;
  color: #fff;
  font-size: 14px;
}
</style>
