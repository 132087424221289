<template>
  <div class="my-card-wrap">
    <slot></slot>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style scoped lang="scss">
.my-card-wrap {
  height: 100%;
  width: 100%;
  background: #fff;
  margin-top: 0.5em;
  border-radius: 10px;
  padding: 10px;
}
</style>
