<template>
  <div
    class="right-main"
    :style="isCollapse ? `width:calc(100% - 84px)` : `width:calc(100% - 289px)`"
  >
    <right-header></right-header>
    <!-- <div class="content" style="height:600px"> -->
    <div class="content" style="height: 100%">
      <!-- 二级路由出口 -->
      <el-scrollbar wrap-class="scrollbar-wrapper" style="height: 100%">
        <!-- 滚动条包裹部分 -->
        <div style="height: 100%; max-height: 100%">
          <transition name="fade-transform" mode="out-in">
            <!-- 二级路由 -->
            <router-view class="container"></router-view>
          </transition>
          <!-- <router-view class="container"></router-view> -->
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import RightHeader from "./RightHeader.vue";
import { mapState } from "vuex";
export default {
  components: {
    RightHeader,
  },
  computed: {
    ...mapState({
      isCollapse: (state) => state.app.menuStatus,
    }),
  },
};
</script>

<style lang="less" scoped>
.right-main {
  background: #f1f5f9;
  display: flex;
  flex-direction: column;
  padding: 10px;
  .content {
    max-height: 100%;
    overflow: auto;
    /deep/.el-scrollbar__view {
      height: 100%;
    }
    /deep/.scrollbar-wrapper {
      overflow-x: hidden;
    }
    .container {
      background: #fff;
      border-radius: 6px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
