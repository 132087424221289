<template>
  <div class="PTZ-wrap">


<!-- 海康云台控制   -->
    <div class="PTZ-box" v-if="type==2">
      <!-- 上下左右控制 -->

      <i
        @mousedown="onControl('up')"
        @mouseup="onStop('up')"
        class="arrow up el-icon-caret-top"
      ></i>

      <i
        @mousedown="onControl('down')"
        @mouseup="onStop('down')"
        class="arrow down el-icon-caret-bottom"
      ></i>

      <i
        @mousedown="onControl('left')"
        @mouseup="onStop('left')"
        class="arrow left el-icon-caret-left"
      ></i>

      <i
        @mousedown="onControl('right')"
        @mouseup="onStop('right')"
        class="arrow right el-icon-caret-right"
      ></i>

      <!-- 中心控制 -->
      <!-- <div class="center-box" @click="playOrPause">
        <i v-if="play" class="el-icon-video-play"></i>
        <i v-else class="el-icon-video-pause"></i>
      </div> -->
    </div>

<!-- 大华ICC云台控制   -->
    <div class="PTZ-box" v-if="type==3">
      <!-- 上下左右控制 -->

      <i
          @mousedown="onControl(1)"
          @mouseup="onStop(1)"
          class="arrow up el-icon-caret-top"
      ></i>

      <i
          @mousedown="onControl(2)"
          @mouseup="onStop(2)"
          class="arrow down el-icon-caret-bottom"
      ></i>

      <i
          @mousedown="onControl(3)"
          @mouseup="onStop(3)"
          class="arrow left el-icon-caret-left"
      ></i>

      <i
          @mousedown="onControl(4)"
          @mouseup="onStop(4)"
          class="arrow right el-icon-caret-right"
      ></i>

      <!-- 中心控制 -->
      <!-- <div class="center-box" @click="playOrPause">
        <i v-if="play" class="el-icon-video-play"></i>
        <i v-else class="el-icon-video-pause"></i>
      </div> -->
    </div>


    <div class="zoom-wrap" v-if="type==2">
      <i
        @mousedown="onControl('ZOOM_IN')"
        @mouseup="onStop('ZOOM_IN')"
        class="el-icon-plus"
        style="margin-right: 20px"
      ></i>
      <i
        @mousedown="onControl('ZOOM_OUT')"
        @mouseup="onStop('ZOOM_OUT')"
        class="el-icon-minus"
      ></i>
    </div>

    <div class="zoom-wrap" v-if="type==3">
      <i
          @mousedown="IccZoomIn(1)"
          @mouseup="IccZoomOut(1)"
          class="el-icon-plus"
          style="margin-right: 20px"
      ></i>
      <i
          @mousedown="IccZoomIn(2)"
          @mouseup="IccZoomOut(2)"
          class="el-icon-minus"
      ></i>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  // inject: ["videoData", "type"],
  props:{
    type:{
      type:Number,
      default:2
    },
    videoData:{
      type:Object,
      default:()=>{}
    }
  },
  data() {
    return {
      play: true,
    };
  },
  components: {},
  mounted() {
    console.log(this.videoData, this.type,"aaaaaaaasadfasd")
  },
  computed: {},
  watch: {},
  methods: {
    // 暂停或开始
    playOrPause() {
      this.play = !this.play;
    },
    // 控制方向开始
    onControl(arrow) {
      if (this.type == 2) {
        this.$api.PTZ.HKControl(this.buildHK(this.videoData, arrow, 0)).then(res=>{
          this.passEvent()
        });
      } else if (this.type == 3) {
        this.$api.PTZ.DHControl(this.buildICC(this.videoData,1, arrow)).then(res=>{
          this.passEvent()
        });
      }
    },
    // 控制方向停止
    onStop(arrow) {
      if (this.type == 2) {
        this.$api.PTZ.HKControl(this.buildHK(this.videoData, arrow, 1)).then(res=>{
          this.passEvent()
        });
      } else if (this.type == 3) {
        this.$api.PTZ.DHControl(this.buildICC(this.videoData,0, arrow)).then(res=>{
          this.passEvent()
        });
      }
    },

    // 大华icc平台的放大
    IccZoomIn(direct){
      this.$api.PTZ.DHControlDistance({
        "command": 1,
        "direct": direct,
        "extend": "",
        "operateType": 1,
        "outChannelId": this.videoData.outChannelId,
        "platformId": this.videoData.platfromId,
        "step": 10
      })
    },
    // 大华icc平台的停止
    IccZoomOut(direct){
      this.$api.PTZ.DHControlDistance({
        "command": 0,
        "direct": direct,
        "extend": "",
        "operateType": 1,
        "outChannelId": this.videoData.outChannelId,
        "platformId": this.videoData.platfromId,
        "step": 10
      })
    },


    //  build 大华 request params
    buildICC(data, command, arrow = 1) {
      return {
        "command": command,  // 0 停止 1开启
        "direct": arrow,
        "extend": "",
        "outChannelId": data.outChannelId,
        "platformId": data.platfromId,
        "stepX": 10,
        "stepY": 10
      };

    },
    //  build haikang request params
    // arrow 1 开始，0 停止
    buildHK(data, action, arrow = 1) {
      return {
        action: arrow,
        cameraIndexCode: data.outChannelId, // outChannelId
        command: action,
        platformId: data.platfromId,
      };
    },

    /*回到预置点*/
    goPreset(presetIndex){
      if (this.type == 2) {
        this.$api.PTZ.HKControl({
          action: 1,
          cameraIndexCode: this.videoData.outChannelId, // outChannelId
          command: "GOTO_PRESET",
          platformId: this.videoData.platfromId,
          presetIndex
        }).then(res=>{
          this.passEvent()
        })
      } else {
      }
    },




    /*传出事件*/
    passEvent(){
      if(this.$listeners["ifChangePreset"]){
        this.$emit("ifChangePreset",true)
      }
    }
  },
};
</script>

<style scoped lang="scss">
.PTZ-wrap {
  width: 120px;
  height: 120px;
  background: #66667330;
  // border-radius: 10px;
  padding: 10px 10px 20px 10px;
  .PTZ-box {
    width: 100%;
    height: 100%;
    background: #9398ad;
    border-radius: 50%;
    box-shadow: 0px 0px 4px #afaaaa;
    position: relative;
    .arrow {
      position: absolute;

      font-size: 2rem;
      color: rgb(223, 213, 213);
      cursor: pointer;
      display: inline-block;
      &:hover {
        color: rgb(0, 174, 255);
      }
      &:active {
        color: rgb(0, 225, 255);
      }
    }

    .up {
      top: 0%;
      left: 50%;
      transform: translate(-50%, 0%);
    }
    .down {
      bottom: 0%;
      left: 50%;
      transform: translate(-50%, 0%);
    }
    .left {
      top: 50%;
      left: 0%;
      transform: translate(0%, -50%);
    }
    .right {
      top: 50%;
      right: 0%;
      transform: translate(0%, -50%);
    }
    .center-box {
      width: 20%;
      height: 20%;
      // background: #424966;

      // box-shadow: 0px 0px 4px #888888;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      color: rgb(109, 103, 103);
      cursor: pointer;
      &:hover {
        color: rgb(0, 174, 255);
      }
      &:active {
        color: rgb(0, 225, 255);
      }
    }
  }
  .zoom-wrap {
    position: absolute;
    width: 100%;
    // bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    color: rgb(102, 94, 94);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      &:hover {
        color: rgb(0, 174, 255);
      }
      &:active {
        color: rgb(0, 225, 255);
      }
    }
  }
}
</style>
<style lang="scss">
</style>
