import CONSTANT from './CONSTANT'
import RealPlayerItem from './RealPlayer'
import RecordPlayerItem from './RecordPlayer'

/* ---------------- WSPlayer ---------------- */
const defaultConfig = {
    num: 4,
    showControl: true,
    type: 'real'
}
class WSPlayer {
    static version = "1.0.1"
    /**
     * 构造函数
     * @param {String} options.el 必传 播放器domId
     * @param {String} options.type  必传 类型 real | record
     * @param {Sting} options.serverIp 必传 服务器IP
     * @param {number} options.num 播放器数量 default 4
     * @param {boolean} options.showControl 显示播放器控制栏 default true
     */
    constructor(options) {
        if (!options.el || !options.type || !options.serverIp) {
            console.error(`el, type, serverIp 为必传参数，请校验入参`)
            return false
        }
        let isHttps = location.protocol == 'https:'
        // 协议判断
        this.protocol = isHttps ? 'wss' : 'ws'
        // 服务器IP
        this.serverIp = options.serverIp ? options.serverIp : location.hostname
        this.options = Object.assign({}, defaultConfig, options)
        this.$el = $('#' + options.el)
        this.width = this.$el.attr('width')
        this.height = this.$el.attr('height')
        this.$el.height(`${this.height}px`)
        this.$el.width(`${this.width}px`)
        this.$el.addClass(`ws-player`)
        // 添加player-wrapper
        this.$el.append(`<div class="player-wrapper"></div>`)
        this.$wrapper = $('.player-wrapper', this.$el)
        this.playerList = []
        $(this.$el).attr('inited', true)
        this.eventListner = {
            selectChange: null,
        }
        switch (options.type) {
            case 'real':
                this.wsPort = CONSTANT.websocketPorts.realmonitor
                if (this.options.showControl) {
                    this.__addRealControl()
                } else {
                    this.$wrapper.addClass('nocontrol')
                }
                for (let i of [0, 1, 2, 3]) {
                    this.playerList.push(new RealPlayerItem({
                        wrapperDomId: options.el,
                        index: i,
                        wsPlayer: this
                    }))
                }
                break;
            case 'record':
                for (let i of [0, 1, 2, 3]) {
                    this.playerList.push(new RecordPlayerItem({
                        wrapperDomId: options.el,
                        index: i,
                        wsPlayer: this
                    }))
                }
                this.wsPort = CONSTANT.websocketPorts.playback
                if (this.options.showControl) {
                    this.__addRecordControl()
                } else {
                    this.$wrapper.addClass('nocontrol')
                }
                break;
            default:
                break;
        }
        // 设置服务器websocektUrl地址
        // this.wsURL = `wss://${this.serverIp}/${this.wsPort}`
        this.wsURL = `${this.protocol}://${this.serverIp}/${this.wsPort}`  // 代码配置websocket 反代
        this.setSelectIndex(0)
        this.setPlayerNum(options.num)

    }

    /**
     * 添加事件监听
     * @param {String} eventType
     * @param {Function} callback
     */
    addListener(eventType, callback) {
        if (typeof eventType !== 'string' || !eventType) {
            console.warn("添加的监听需要为字符串！")
            return
        }
        this.eventListner[eventType] = callback
    }
    /**
     * 播放实时视频
     * @param {*} options.rtspURL String | array
     * @param {*} options.decodeMode 可选参数 video | canvas
     * @param {*} options.wsURL 可选参数
     * @param {*} options.channelId 可选参数, 用来标记当前播放的视频通道
     */
    playReal(opt) {
        if (!opt.rtspURL) {
            console.error("播放实时视频需要传入rtspURL")
            return
        }
        // 多个rtsp地址时，按照播放器顺序，选择播放器进行播放
        if (opt.rtspURL instanceof Array) {
            opt.rtspURL.forEach((item, i) => {
                if (i < 4) {
                    this.playReal(Object.assign({}, opt, {
                        rtspURL: item
                    }))
                }
            })
        } else {
            opt.wsURL = this.__getWSUrl(opt.rtspURL)
            let player = this.playerList[this.selectIndex]
            if (this.showNum > 1) {
                this.setSelectIndex((this.selectIndex + 1) % this.showNum)
            }
            player.init(opt)
        }
    }
    /**
     * 播放录像
     * @param {String} options.decodeMode 可选参数 video | canvas
     * @param {String} options.wsURL 可选参数
     * @param {Function} options.recordSource 2=设备，3=中心
     * recordSource == 2 设备录像，按照时间方式播放
     * @param {String} options.rtspURL String
     * @param {Number | String} options.startTime 开始时间 时间戳或者'2021-09-18 15:40:00'格式的时间字符串
     * @param {Number | String} options.endTime 结束时间 时间戳或者'2021-09-18 15:40:00'格式的时间字符串
     * @param {Function} options.reload 重新拉流的回调函数，用于时间回放，返回promise
     * reload(newStarTime, endTime).then(newRtspUrl => { play continue})
     * recordSource == 3 中心录像，按照文件方式播放
     * @param {Function} options.RecordFiles 文件列表
     * @param {Function} options.getRtsp 文件列表
     * getRtsp(file).then(newRtspUrl => { play continue})
     */
    playRecord(opt) {
        let player = this.playerList[this.selectIndex]
        if (this.showNum > 1) {
            this.setSelectIndex((this.selectIndex + 1) % this.showNum)
        }
        opt.wsURL = this.__getWSUrl(opt.rtspURL)
        player.init(opt)
    }

    /**
     * 播放
     */
    play() {
        let player = this.playerList[this.selectIndex]
        player.play()
    }
    /**
     * 暂停播放
     */
    pause() {
        let player = this.playerList[this.selectIndex]
        player.pause()
    }
    /**
     * 倍速播放
     * @param {Number} speed 倍速
     */
    playSpeed(speed) {
        if (this.options.type === 'real') {
            console.warn('实时预览不支持倍速播放')
            return
        }
        let player = this.playerList[this.selectIndex]
        player.playSpeed(speed)
    }
    /**
     * 时间跳转
     * @param {*} time
     */
    playByTime(time) {
        if (this.options.type === 'real') {
            console.warn('实时预览不支持时间设置')
            return
        }
        let player = this.playerList[this.selectIndex]
        player.playByTime(time)
    }
    /**
     * 设置选中的播放器
     * @param {*} index
     */
    setSelectIndex(index) {
        if (this.selectIndex === index) {
            return
        }
        this.selectIndex = index
        this.__triggerEvent('selectChange', [this.selectIndex, this.playerList[this.selectIndex].options])
        this.playerList.forEach((item, i) => {
            if (i === index) {
                item.$el.removeClass('unselected').addClass('selected')
            } else {
                item.$el.removeClass('selected').addClass('unselected')
            }
        })
    }
    /**
     * 控制视频播放器数量
     * @param {*} number
     * @param {*} index
     */
    setPlayerNum(number, index) {
        if (this.showNum === number) {
            return
        }
        console.log("WSPlayer setPlayerNum", number, index)
        this.showNum = number
        switch (number) {
            case 1:
                this.$el.addClass('fullplayer')
                break;
            default:
                this.$el.removeClass('fullplayer')
                break;
        }
    }
    // 关闭所有播放器
    close() {
        this.playerList.forEach(item => {

            item.close()
        })
    }

    /* ----------------------- 内部方法 -----------------------*/
    /**
     * 添加实时播放控制栏
     */
    __addRealControl() {
        this.$el.append(`
            <div class="ws-control">
                <div class="flex">
                    <div class="ws-ctrl-icon one-screen-icon"></div>
                    <div class="ws-ctrl-icon four-screen-icon"></div>
                </div>
            </div>
        `)

        $('.one-screen-icon', this.$el).click(() => {
            this.setPlayerNum(1, this.selectIndex)
        })
        $('.four-screen-icon', this.$el).click(() => {
            this.setPlayerNum(4)
        })
    }
    /**
     * 添加录像回放控制栏
     */
    __addRecordControl() {
        this.$el.append(`
            <div class="ws-control">
                <div class="flex">
                    <div class="ws-ctrl-icon one-screen-icon"></div>
                    <div class="ws-ctrl-icon four-screen-icon"></div>
                </div>
            </div>
        `)

        $('.one-screen-icon', this.$el).click(() => {
            this.setPlayerNum(1, this.selectIndex)
        })
        $('.four-screen-icon', this.$el).click(() => {
            this.setPlayerNum(4)
        })
    }

    /**
     * 根据RTSP地址获取wsUrl
     * @param {String} rtspUrl
     */
    __getWSUrl(rtspUrl) {
        let ip = rtspUrl.match(/\d{1,3}(\.\d{1,3}){3}/g)[0]
        if (!ip) {
            ip = rtspUrl.split('//')[1].split(':')[0]
        }
        return `${this.wsURL}?serverIp=${ip}`
    }
    /**
     * 事件触发
     * @param {String} eventType
     * @param {Args}  参数
     */
    __triggerEvent(eventType, args) {
        if (this.eventListner[eventType] && typeof this.eventListner[eventType] === 'function') {
            this.eventListner[eventType].call(this, ...args)
        }
    }
}

export {
    WSPlayer
}

export default WSPlayer
