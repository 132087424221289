import userApi from "./user/user.api";
import loginApi from "./login/login.api";
import roleApi from "./role/role.api";
import gatewayApi from "./gateway/gateway.api";
import codecApi from "./codec/codec.api";
import metricApi from "./metric/metric.api";
import tableApi from "./metricTable/metricTable.api";
import tsApi from "./ts/ts.api";
import processorApi from "./processor/processor.api";
import metriGroupcApi from "./metricGroup/metricGroup.api";
import configApi from "./config/config.api";
import fileApi from "./file/file.api";
import eventCenter from "./eventcenter/eventcenter.api";
import video from "./videomonitting/video.api";
import GISCollection from "./giscollection/giscollection.api";
import { inspectionContentApi } from "./portal/inspectionContent";
import { commonApi } from "./common";
import { inspectionNodeApi } from "./portal/inspectionPoint";
import { terminalManagerApi } from "./portal/termimalManger";
import uploadFile from "./uploadfile/upload.api";
import { rangeMangeApi } from "./portal/rangeMange";
import { taskMangerApi } from "./portal/taskManger";
import { orgApi } from "./org/index";
import { videoSetting } from "./videoSetting";
import { crafTwins } from "./craftTwins";
import { digitaltwin } from "./digitaltwin";
import { deviceMangerApi } from "./portal/deviceManger";
import onlinemodelApi from "./onlinemodel/onlinemodel.api";
import mapmanagerApi from "./mapmanager/mapmanager.api";
import gislegendapi from "./gislegend/gislegend.api";
import { PTZ } from "./PTZ";
import {controlCenterConfig} from "@/apis/controlCenterConfig";
import {controlCenter} from "@/apis/controlCenter";
import customiseconfigApi from "./customiseconfig/customiseconfig.api";
import lrApi from "./lrapi";
import locationApi from "./loaction";
import reportApi from "./report";
import digitalLayerApi from "./digitalLayer/digitalLayer.api";
export default {
  ...userApi,
  ...loginApi,
  ...roleApi,
  ...gatewayApi,
  ...codecApi,
  ...metricApi,
  ...tableApi,
  ...tsApi,
  ...processorApi,
  ...metriGroupcApi,
  ...configApi,
  ...fileApi,
  ...eventCenter,
  ...video,
  ...GISCollection,
  ...inspectionContentApi,
  ...commonApi,
  ...inspectionNodeApi,
  ...terminalManagerApi,
  ...uploadFile,
  ...rangeMangeApi,
  ...taskMangerApi,
  ...orgApi,
  ...videoSetting,
  ...crafTwins,
  ...digitaltwin,
  ...deviceMangerApi,
  ...onlinemodelApi,
  ...mapmanagerApi,
  ...PTZ,
  ...gislegendapi,
  ...controlCenterConfig,
  ...controlCenter,
  ...customiseconfigApi,
  ...lrApi,
  ...locationApi,
  ...reportApi,
  ...digitalLayerApi
};
