import axios from "../axios";
import qs from "qs";
const metriGroupcApi = {
  //! 查询指标组列表
  getGroupList(params) {
    /**
     * page 第几页
     * size 页的数量
     */
    return axios.get("/metric/metricGroup/list", {
      params,
      showLoading: '.metric-group',
    });
  },
  //! 修改指标组
  editGroup(params) {
    /**
         *  "metricGroupId": "string", 指标组id
            "metricInfoIds": [ 指标id数组
                "string"
            ],
            "name": "string" 指标组名字
         */
    return axios.put("/metric/metricGroup", params, {
      headers: {
        "Content-Type": "application/json",
      },
      showLoading: true,
    });
  },
  //! 新增指标组
  creGroup(params) {
      /**
       * {
            "metricInfoIds": [ 指标id数组
                "string"
            ],
            "name": "string"  指标组名字
            }
       */
    return axios.post("/metric/metricGroup", params, {
        headers: {
          "Content-Type": "application/json",
        },
        showLoading: true,
      });
  },
  //! 查询指标组
  serchGroup(params){
      /**
       * metricGroupId指标组id
       */
      return axios.get('/metric/metricGroup/'+params,{
        showLoading: true,
      })
  },
  //! 删除指标组
  delGroup(params){
       /**
       * metricGroupId指标组id
       */
      return axios.delete('/metric/metricGroup/'+params,{
        showLoading: true,
      })
  },
  addAlertWatcherInter(params){
    return axios.post('/alert/watcher',params) 
  }
};
export default metriGroupcApi;
