import axios from "../axios";
import qs from "qs";
const roleApi = {
  getRoleList(params) {
    /**
     * 分页查询角色信息
     * @parmas {Number} page 第几页 必填
     * @parmas {Number} size 页的数量 必填
     * @parmas {array} status 状态
     * @parmas {string} name 角色名称
     */
    return axios.get("/role/list", {
      params,
      showLoading: true,
    });
  },
  createRole(params) {
    /**
     * 新增角色
     * @parmas {string} name 角色名称
     * @parmas {string} mark 标识
     * @parmas {string} desc 描述
     * @parmas {Number} status 状态 默认为1
     * @parmas {Arrey} permissions 角色
     */
    return axios.post("/role", params, {
      headers: {
        "Content-Type": "application/json",
      },
      showLoading: true,
    });
  },
  searchByRoleId(params) {
    /**
     * 根据id查询角色
     * @params {string} oleId 角色id
     */
    return axios.get("/role/" + params, { showLoading: true });
  },
  changeRoleStatus(params) {
    /**
     * 改变角色状态
     * @params {String} roleId
     * @params {Number} status
     */
    return axios.put("/role/status", params, {
      headers: {
        "Content-Type": "application/json",
      },
      showLoading: true,
    });
  },
  roleBindUser(params) {
    return axios.post("/role/bindUser", params, {
      headers: {
        "Content-Type": "application/json",
      },
      showLoading: true,
    });
  },
  modifyRoleinfo(params) {
    return axios.put("/role", params, {
      headers: {
        "Content-Type": "application/json",
      },
      showLoading: true,
    });
  },
  findUserbyRole(roleId) {
    /**
     * 根据角色id，查询所绑定的用户
     * {
        "page": 0,
        "roleId": "string",
        "size": 0
      }
     */
    return axios.get(
      `/role/${roleId}/user`,
      {
        params: {
          page: 0,
          size: 1000000,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        showLoading: true,
      }
    );
  },
  unbindUser(params) {
    /**
     * 角色解绑用户
     * {
        "roleId": "string",
        "userIds": [
          "string"
        ]
      }
     */
    return axios.post("/role/unbindUser", params, {
      headers: {
        "Content-Type": "application/json",
      },
      showLoading: true,
    });
  },
  getListRes() {
    /**
     * 获取资源类型与资源操作
     */
    return axios.get("/role/listResource", {
      showLoading: true,
    });
  },
  // 删除角色
  delRole(roleId) {
    return axios.delete("/role/" + roleId, {
      showLoading: true,
    });
  },
  // 查询角色详细信息
  getRoleInfo(roleId) {
    return axios.get("/role/" + roleId, {
      showLoading: true,
      // params: {
      //   page: 0,
      //   size: 1000000,
      // },
    });
  },

  // 获取所有菜单配置 /customize/sysMenu
  getCustomizeSysMenu(){
    return axios.get("/customize/sysMenu" , {
      showLoading: true,
      // params: {
      //   page: 0,
      //   size: 1000000,
      // },
    });
  }
};
export default roleApi;
