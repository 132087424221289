<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import mqtt from "mqtt";
import {validateIP} from "@/util/commonUtils"

export default {
  data() {
    return {
      client: null
    };
  },
  computed: {
    token() {
      return this.$store.state.user.token;
    }
  },
  watch: {
    token(newV, oldV) {
      if (newV) {
        this.client.end();
        this.initSocket();
      }
    },
    /*监听路由变化
    * 当回到登录页面时，需关闭mqtt连接
    * */
    $route(to, from) {
      console.log(to.path);
      let path = to.path
      if (path == "/login" && this.client) {
        this.client.end();
      }
    }
  },
  mounted() {
    this.initSocket();
  },
  methods: {
    initSocket() {
      var mqtt = require("mqtt");
      // const host = process.env.VUE_APP_TARGET + "/ws/mqtt";
      // const host = process.env.VUE_APP_TARGET_RENHUAI + "/ws/mqtt";
      // const host = "ws://10.13.2.229:8083/mqtt";

      // 获取当前访问网址的协议
      let protocol = document.location.protocol; // 'https:' or 'http:'
      let hostCurrent = window.location.host;  // 带端口
      let host = "/ws/mqtt"; // 打包正式环境
      console.log(process.env)
      if (process.env.NODE_ENV === "development") {
        // 开发环境
        host = `ws://midend-devel.yiwu.wid.waterstrategy.tech/ws/mqtt`
        // host = "http://wid.test.waterstrategy.cn/ws/mqtt"
      } else {
        // 正式环境
        if (protocol === "https:") {
          host = `wss://${hostCurrent}/ws/mqtt`
        } else {
          host = `ws://${hostCurrent}/ws/mqtt`
        }
      }


      const options = {
        keepalive: 60,
        clientId: "u:" + this.$store.state.user.userId + '_pcweb_' + new Date().getTime(),
        protocolId: "MQTT",
        protocolVersion: 4,
        clean: true,
        reconnectPeriod: 2000,
        connectTimeout: 10 * 1000,
        username: "u:" + this.$store.state.user.userId,
        password: this.$store.state.user.token,
        // will: {
        //   topic: "WillMsg",
        //   payload: "Connection Closed abnormally..!",
        //   qos: 0,
        //   retain: false,
        // },
      };
      this.client = mqtt.connect(host, options);
      console.log({
        clientId: options.clientId,
        username: options.username,
        password: options.password,
        host: host
      })
      this.client.on("error", (err) => {
        console.log(err, 'mqttErr')
        this.client.end();
      });
      this.client.on("connect", () => {
        console.log('mqtt client connect')
      });
      this.client.on("message", (topic, data, packet) => {
        this.$store.commit("app/setMqttMessage", {topic, data});
      });
      this.$store.commit("app/setClient", this.client);
    },
  },
};
</script>

<style lang="less">
#app {
  height: 100%;
}

::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.5rem;
  background: hsla(0, 0%, 100%, 0.6);
}

::-webkit-scrollbar-track {
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: rgba(95, 95, 95, 0.4);
  transition: all 0.2s;
  border-radius: 0.5rem;

  &:hover {
    width: 0.5rem !important;
    background-color: rgba(95, 95, 95, 0.7);
  }
}
</style>



