<template>
  <!--  <div class="header" :style="{ background: activeColor }">-->
  <div class="header">
    <div
        class="logo-MControl"
        :style="isCollapse ? 'width:64px' : 'width:259px'"
    >
      <img
          :src="isCollapse ? logoUrl2 : logoUrl"
          class="logo"
          :style="{paddingLeft:'10px',width:isCollapse?'75%':'90%'}"
      />
    </div>
    <div
        class="header-right"
        :style="
        isCollapse ? `width:calc(100% - 64px)` : `width:calc(100% - 259px)`
      "
    >
      <div
          class="menu-control"
          :style="isCollapse ? ' left: 70px' : ' left: 270px'"
      >
        <i
            @click="changeMenu()"
            style="cursor: pointer"
            :class="!isCollapse ? 'el-icon-s-fold' : 'el-icon-s-unfold'"
        ></i>
        <!-- <el-cascader
          v-model="value"
          :options="orgList"
          :props="prop"
          :show-all-levels="false"
          @change="changeOrg"
        ></el-cascader> -->
        <el-select @change="changeOrg" v-model="value" placeholder="请选择">
          <el-option
              v-for="item in orgList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
          >
          </el-option>
        </el-select>
      </div>
<!--      <div class="_scoller-wrap">-->
<!--        <i class="drainage drainagebobao warnicon"/>-->
<!--        <scoller/>-->
<!--      </div>-->
      <div class="welcomeInfo">
        <div class="current-duty">
          <span>当前值班：</span>
          <span>{{dutypeople}}</span>
        </div>
        <toolpage></toolpage>
        <!-- <div class="safety-days">
          <span>安全生产天数：</span>
          <span>
            <span style="color:#07FF00">1030</span>天
          </span>
        </div> -->
        <el-divider direction="vertical" style="margin-right: 36px"></el-divider>
        <el-badge :value="1" class="to-do">
          <span style="font-size:16px;font-weight:500;">待办</span>
        </el-badge>
        <i class="el-icon-message-solid"></i>
        <el-badge :value="3" class="badge-item">
          <span style="font-size:16px;font-weight:500;">消息</span>
        </el-badge>

        <el-avatar :size="26" class="el-icon-user-solid"></el-avatar>
        <el-dropdown @command="handleCommand">
          <div class="el-dropdown-link" style="cursor: pointer;display:flex"
          ><span>
            {{ username }}
          </span>
            <i class="el-icon-caret-bottom el-icon--right"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="resetpassword"
            >修改密码
            </el-dropdown-item
            >
            <el-dropdown-item command="logout">门户</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <!--   跳转到kpi     -->
<!--        <i style="margin:0 6px;cursor: pointer" title="kpi" @click="jumpToPath" class="drainage drainageKPIfenxi"></i>-->
        <!-- 头像 -->
        <!-- <el-avatar size="small"></el-avatar> -->
      </div>
    </div>

    <el-dialog title="修改密码" :visible.sync="passwordreset" width="30%">
      <el-form
          ref="elForm"
          :model="formData"
          :rules="rules"
          size="medium"
          label-width="100px"
      >
        <el-form-item label="原密码" prop="oldPwd">
          <el-input
              v-model="formData.oldPwd"
              placeholder="请输入原密码"
              clearable
              show-password
              :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPwd">
          <el-input
              v-model="formData.newPwd"
              placeholder="请输入新密码"
              clearable
              show-password
              :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="newPwd1">
          <el-input
              v-model="formData.newPwd1"
              placeholder="请输入确认密码"
              clearable
              show-password
              :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="passwordreset = false">取消</el-button>
        <el-button type="primary" @click="handleEditpassword">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import toolpage from "./toolpage";
import local from "@/util/local";
import {mapState} from "vuex";
import {listToTree,UseJump} from "@/util/commonUtils.js";
import router from "@/router";
import DutySituationApi from "@/apis/dutySituationApi";
import scoller from "./scoller.vue";
const dutySituationApi = new DutySituationApi()
import moment from 'moment';
const imgPrefix = process.env.VUE_APP_LOGO_URP; // LOGO 前缀
const {jumpToKpi,jumpToPath} = UseJump();
export default {
  components:{
    toolpage,
    scoller
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入新密码"));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.formData.newPwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      value: "",
      prop: {
        value: "orgId",
        label: "orgName",
        expandTrigger: "hover",
        checkStrictly: false,
      },
      passwordreset: false,

      formData: {
        oldPwd: undefined,
        newPwd: undefined,
        newPwd1: undefined,
      },
      rules: {
        oldPwd: [
          {
            required: true,
            message: "请输入原密码",
            trigger: "blur",
          },
        ],
        newPwd: [{required: true, validator: validatePass, trigger: "blur"}],
        newPwd1: [
          {required: true, validator: validatePass2, trigger: "blur"},
        ],
      },
      dutypeople:''
    };
  },
  computed: {
    ...mapState({
      activeColor: (state) => state.app.theme,
      isCollapse: (state) => state.app.menuStatus,
      username: (state) => state.user.userName,
      orgList: (state) => {
        return state.user.orgList;
      },
      orgId: (state) => state.user.orgId,
      orgPermissionMap: (state) => state.user.orgPermissionMap,
      addRoutes: (state) => state.permission.addRoutes,
      logoPath: (state) => state.app.logoFilePath,
      systemDesc: (state) => state.app.systemDesc,
      logoUrl2: (state) => {
        return imgPrefix + state.app.coreFoldFilePath;
      },
      logoUrl: (state) => {
        return imgPrefix + state.app.coreOpenFilePath;
      },
    }),
  },
  watch: {
    orgId(val) {
      this.value = val;
    },
    $route: {
      handler: function(val, oldVal){
        this.getList();
      },
      // 深度观察监听
      deep: true
    }
  },
  mounted() {
    this.value = this.orgId;
    this.getList();
  },
  methods: {
    changeMenu() {
      this.$nextTick(() => {
        this.$store.commit("app/changeMenuStatus");
      });
    },
    //? 点击下拉菜单
    handleCommand(command) {
      if (command === "logout") {
        // local.clear();
        this.$message({type: "success", message: "退出成功，欢迎下次再来"});
        setTimeout(()=>{
          // window.location.replace(`${this.$store.state.app.unifiedLoginUrl}?return=http://${window.location.host}/#/login&opt=clear`);
          // window.location.replace(`${this.$store.state.app.unifiedLoginUrl}?token=${this.$store.state.user.token}`);
          // window.location.replace(`http://localhost:8001?token=${this.$store.state.user.token}`)
          console.log(`${this.$store.state.app.unifiedLoginUrl}/#/sub-system?token=${this.$store.state.user.token}`);
          window.open(`${this.$store.state.app.unifiedLoginUrl}/#/sub-system`,"门户"); // 跳转到浏览器指定门户tab页
          window.close();
          this.$store.commit("user/SET_USERID", "");
          this.$store.commit("user/SET_TOKEN", "");
          this.$store.commit("user/SET_REALNAME", "");
          this.$store.commit("user/SET_ROLES", "");
          this.$store.commit("user/SET_USERNAME", "");
          this.$store.commit("permission/SET_ROUTES", []);
          this.$store.commit("user/SET_PERMISSIONS", []);
          this.$store.commit("user/SET_MENU", []);
          this.$store.commit("user/SET_ORGLIST", []);
          this.$store.commit("user/SET_ORGID", undefined);
          this.$store.commit("user/SET_PERMISSIONMAP", {});
        },500)

        // this.$router.push("/login");
      } else if (command == "resetpassword") {
        this.passwordreset = true;
      }
    },
    handleEditpassword() {
      this.$refs["elForm"].validate((valid) => {
        if (!valid) return;
        this.$api
            .userUpdatePassword({
              newPassword: this.formData.newPwd,
              oldPassword: this.formData.oldPwd,
            })
            .then((res) => {
              this.$message.success("密码修改成功");
              this.passwordreset = false;
            })
            .catch((err) => {
              this.$message.error(err.data.message);
            });
      });
    },
    // 更改组织
    changeOrg(value) {
      this.$store.commit("user/SET_ORGID", value);
      // 保存当前的权限
      this.$store.commit("user/SET_ROLES", this.orgPermissionMap[value]);
      // 保存权限
      this.$store.commit("user/SET_PERMISSIONS", this.orgPermissionMap[value]);

      // 切换菜单
      this.getMenu();
    },
    // 重新根据权限更改菜单
    async getMenu() {
      const accessRoutes = await this.$store.dispatch(
          "permission/generateRoutes"
      );
      // 动态的生成路由
      router.addRoutes(accessRoutes);
      let routes = this.$store.state.permission.routes;
      let currentUrl = this.$route.path;
      this.timer = setTimeout(() => {
        this.$store.commit("user/SET_MENU", routes);
        if (currentUrl != "/dashboard") {
          this.$router.replace({path: "/dashboard"});
        }
      }, 0);
    },

    jumpToPath(){
      jumpToKpi(this.$store.state.app.siteUrls['kpi-dashboard'],this.$store.state.app.unifiedLoginUrl)
      // jumpToKpi("http://localhost:8888",this.$store.state.app.unifiedLoginUrl)
    },
    // getList(){
    //   dutySituationApi.getDutyRecordList({
    //     page: 0,
    //     size: 99,
    //     startTime:moment(this.recordingDate).startOf('day').toISOString(),
    //     endTime:moment(this.recordingDate).endOf('day').toISOString(),
    //   }).then(res=>{
    //     this.dutypeople="";
    //     res.data.content[1].scheduleUserInfoDtos.forEach((val,index)=>{
    //       if(index==0){
    //         this.dutypeople+=val.name
    //       }else{
    //         this.dutypeople+="/"+val.name
    //       }
    //     })
    //   })
    // },
    getList() {
      dutySituationApi.getScheduleDetailInfo().then(res => {
        this.dutypeople="";
        !!res.data.userInfoDtos&&res.data.userInfoDtos.forEach((val,index)=>{
          if(index==0){
            this.dutypeople+=val.name
          }else{
            this.dutypeople+="/"+val.name
          }
        })
      })
    },
  },
  created() {
  },
  beforeDestroy() {
    clearTimeout(this.timer);
    this.timer = null;
  },
};
</script>

<style lang="less" scoped>
.header {
  color: #fff;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //background-color: linear-gradient(90deg, #2791FF 0%, #0A5CAF 100%) !important;
  //background-color: #2791FF;
  //background-image: url('../../../assets/imgs/header-bg.png') !important;
  //background-size: contain;
  //background-repeat: no-repeat;

  background: url('../../../assets/imgs/header-bg.png')  no-repeat center center,linear-gradient(90deg, #2791FF 0%, #0A5CAF 100%);
  background-size: auto 100%;
  i {
    font-size: 1.5em;
  }

  .logo-MControl {
    // width: 200px;
    display: flex;
    align-items: center;
    height: 100%;
    overflow: hidden;
    border-right: 1px solid #fff;
    transition: 0.5s;

    .logo {
      width: 90%;
      // padding-left: 1.5em;
      //height: 100%;
    }

    transition: 0.5s;
  }

  .welcomeInfo {
    transition: 0.5s;
    display: inline-flex;
    align-items: center;

    .el-icon-bell {
      padding-right: 5px;
    }

    .el-avatar {
      margin: 0 10px;
    }

    .el-dropdown {
      color: #fff;
    }
  }
  // 滚动条
  ._scoller-wrap {
    z-index: 2;
    width: 12%;
    height: 46px;
    padding: 0 4%;
    position: absolute;
    top: 9px;
    right: 49%;
    border-radius: 5px;
    z-index: 1;
    background: linear-gradient(270deg, rgba(2, 119, 255, 0) 0%, rgba(0, 156, 255, 0.4) 50%, rgba(2, 119, 255, 0) 100%);
    display: flex;
    align-items: center;
    justify-content: center;

    .warnicon {
      color: #f3bd0ad0;
      font-size: 20px;
      margin-right: 8px;
    }
  }

  .menu-control {
    display: flex;
    align-items: center;
    // display: inline-block;
    i {
      padding-right: 0.5em;
    }

    // left: 300px;
  }

  .dialog-footer {
    padding-right: 10%;
  }

  .header-right {
    display: flex;
    align-content: center;
    transition: 0.5s;
    justify-content: space-between;
    align-content: center;
    padding: 10px;
  }

  .badge-item {
    //margin-top: 10px;
    margin-right: 40px;
    margin-left: 10px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .current-duty {
    height: 100%;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 56px;
  }

  .safety-days {
    height: 100%;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 36px;
  }

  .to-do {
    height: 100%;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 36px;
    margin-right: 36px;
  }


}
</style>
