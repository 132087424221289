import axios from "../axios";
import qs from "qs";

const metricApi = {
  // metricList(params,target) {
  //   /**
  //    * ? 分页查询指标信息
  //    * @parmas {Number} page
  //    * @parmas {Number} size
  //    */
  //   return axios.get("/metric/list", { params, showLoading:target});
  // },
  getMetricInfoById(metricId){
    return axios.get('metric/' + metricId)
  },
  indicator(params) {
    return axios.get("/metric/list", {params});
  },
  editMetric(params) {
    /**
     * ? 修改指标
     */
    return axios.put("/metric", params, {
      headers: {
        "Content-Type": "application/json",
      },
      showLoading: true,
    });
  },
  delMetric(params) {
    /**
     *? 删除指标
     */
    return axios.delete('/metric/' + params, {
      showLoading: true,
    })
  },
  fetchRelPrs(id, params) {
    /**
     * 根据指标,查询其关联的加工器的信息
     * metricId
     * relationTypes 指标是作为输入还是输出
     */
    return axios.get('/metric/' + id + '/relatedProcessor?relationTypes=output', {
      params
    })
  },
  metricType() {
    return axios.get('/unit/list/type')
  },
  //指标关联的watcher（报警规则）
  getIndictorAlertInter(metricId) {
    return axios.get('/metric/' + metricId + '/relatedAlertWatcher')
  },
  deleteAlertInter(id) {
    return axios.delete('/alert/watcher/' + id)
  },
  controlFrameInter(params) {
    return axios.post("/metric/controlFrame", params)
  },
  // 查询指标相关联的加工器
  getRelatedMath(metricId) {
    return axios.get('/metric/' + metricId + '/relatedProcessor', {
      params:{
        relationTypes:'output'
      },
      showLoading: true,
    })
  },
  // 校验测点的正确性
  testMathApi(params){
    return axios.post("/metric/processor/script/test", params, {
      headers: {
        "node-Type": "application/json",
      },
      showLoading: true,
    });
  },
  /*根据加工器的信息*/
  getProcessorInfo(processorId){
    return axios.get(`/metric/processor/${processorId}`, {
      showLoading: true,
    })
  },
  /*修改指标加工器*/
  editProcessor(metricProcessorId,params) {
    return axios.put(`/metric/processor/${metricProcessorId}`, params, {
      headers: {
        "Content-Type": "application/json",
      },
      showLoading: true,
    });
  },

  // 单独编辑禁止scada写入
  forbidWriteScada(params){
    return axios.put("/metric/updateForbidWrite", params, {
      headers: {
        "Content-Type": "application/json",
      },
      showLoading: true,
    });
  }



};
export default metricApi;
