
const CONSTANT = {
    websocketPorts: {
        realmonitor: "realmonitor-websocket",
        playback: "playback-websocket",
        // realmonitor: {
        //     ws: 9100,
        //     wss: 9102,
        //     nginxProxy: "realmonitor-websocket",
        //     proxyPort: 9800
        // },
        // playback: {
        //     ws: 9320,
        //     wss: 9322,
        //     nginxProxy: "playback-websocket",
        //     proxyPort: 9900
        // }
    },
    errorInfo: {
        101: "播放延时大于8s",
        201: "当前音频无法播放",
        202: "websocket连接错误",
        203: "文件播放完成",
        404: "RTSP文件未找到",
        457: "时间设置错误",
        503: "SETUP服务不可用",
        504: "对讲服务不可用",
        defaultErrorMsg: "播放失败，请检查配置"
    }
}

export default CONSTANT