import axios from "../axios";
import qs from "qs";
const configApi = {
  getConfig() {
    // 获取配置
    return axios.get("/sys/config");
  },

  upConfig(params)     {
    //   更新配置
    return axios.put("/sys/config", params, { showLoading: true });
  },

  scadaQueryConfig(){
    // 获取时序数据查询配置
    return axios.get('/sys/tsQueryConfig');
  },

  putScadaConfig(params){
    return axios.put('/sys/tsQueryConfig' , params, { showLoading: true })
  }
};
export default configApi;
