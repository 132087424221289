import axios from "../axios";
import qs from "qs";
const gisCollectionApi = {
    getGisCollectionListInter(params) {
      /**
       * 图层列表
       * @parmas {integer} page 
       * @parmas {integer} size 
       * @parmas {array[string]} sort
       */
      return axios.get("/gis/layer/list", {params}, { showLoading: true });
    },
    addLayerInter(params){
      return axios.post('/gis/layer/import',params)
    },
    getCrsInter(){
      return axios.get('/gis/crs/defaults');
    },
    editLayerInter(id,params){
      return axios.put('/gis/layer/import',params)
    },
    deleteLayerInter(params){
      return axios.delete('/gis/layer/'+params)
    },
    //获取图层的geojson
    getLayerGeojson(id){
      return axios.get("/gis/layer/"+id+"/geojson")
    }
  };
  export default gisCollectionApi;