<template>
  <Card class="my-layout-wrap">
    <div slot="header" class="card-header">
      <span class="control-header">{{ $route.meta.title }}</span>
      <slot name="header" style="display: inline-block"></slot>
    </div>
    <div slot="content" class="my-layout-content">
      <slot name="content"></slot>
    </div>
  </Card>
</template>

<script>
import Card from "@/components/Card.vue";
export default {
  data() {
    return {};
  },
  components: { Card },
  mounted() {},
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style scoped lang="scss">
.my-layout-wrap {
  height: calc(100% - 2px);
  .my-layout-content {
    // height: calc(100% - 58px);
    // overflow: auto;
  }
}
</style>
<style lang="scss">
.my-layout-wrap {
  .el-card__body {
    // height: calc(100% - 60px);
    overflow: auto;
  }
}
</style>
