import axios from "../axios";
import qs from "qs";

const mapmanagerApi = {
    getGisMapListInter(params){
        return axios.get("/gis/map/list",{ params })
    },
    addGisMapInter(params){
        return axios.post("/gis/map",params)
    },
    deleteMapInter(id){
        return axios.delete("/gis/map/" + id)
    },
    getGisMapDetailInter(id){
        return axios.get("/gis/map/"+id)
    },
    editeGisMapInter(params){
        return axios.put("/gis/map",params)
    }
}

export default mapmanagerApi