import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";
import vuejsStorage from "vuejs-storage";
Vue.use(Vuex);
// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context("./modules", true, /\.js$/);

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});
const store = new Vuex.Store({
  modules,
  getters,
  plugins: [
    vuejsStorage({
      keys: [
        "app.menuStatus",
        "app.theme",
        "app.systemLogo",
        "app.systemName",
        "app.logoFilePath",
        "app.coreFoldFilePath",
        "app.coreOpenFilePath",
        "app.copyrightLogo",
        "app.bottomFilePath",
        "app.systemDesc",
        "app.unifiedLoginUrl",
        "user.userId",
        "user.token",
        "user.realName",
        "user.roles",
        "user.userName",
        "user.menu",
        "permission.routes",
        "user.permissions",
        "user.orgList",
        "user.orgId",
        "user.orgPermissionMap",
        "user.refreshTokenTime",
        "user.tokenId",
        "app.siteUrls",
          "app.xjrUrl",
          "app.patrolLineModelUrl",
          "app.ownerName"
      ],
      namespace: "vuexStorage",
      driver: vuejsStorage.drivers.sessionStorage, //keep in sessionStorage
    }),
    vuejsStorage({
      keys: ["app.theme"],
      namespace: "setting",
      driver: vuejsStorage.drivers.localStorage, //keep in sessionStorage
    }),
  ],
});

export default store;
