<template>
  <div class="my-draggble-table">
    <el-table
      :class="className"
      :empty-text="emptyText"
      :data="tableData"
      row-key="id"
      ref="tb"
      :height="maxHeight"
      highlight-current-row
      @row-click="rowClick"
      @selection-change="handleSelectionChange"
      @select="selectCheck"
    >
      >
      <slot></slot>
    </el-table>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import Sortable from "sortablejs";
export default {
  props: {
    className: {
      type: String,
      default: "drage-table",
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    emptyText: {
      type: String,
      default: "暂无数据",
    },
    maxHeight: {
      type: String,
      default: '600',
    },
    multSelect:{
      type:Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  components: {},
  mounted() {
    this.$nextTick(() => {
      this.dragEt();
    });
  },
  computed: {},
  watch: {},
  methods: {
    // 拖动
    dragEt() {
      // 首先获取需要拖拽的dom节点
      const el1 = document
        .querySelectorAll(`.${this.className} .el-table__body-wrapper`)[0]
        .querySelectorAll("table > tbody")[0];
      let _this = this;
      Sortable.create(el1, {
        disabled: _this.disabled, // 是否开启拖拽
        ghostClass: "ghost", //拖拽样式
        animation: 150, // 拖拽延时，效果更好看
        // group: {
        //   // 是否开启跨表拖拽
        //   pull: false,
        //   put: false,
        // },
        onEnd({ newIndex, oldIndex }) {
          // const currRow = _this.tableData.splice(oldIndex, 1)[0];
          const currRow = _this.tableData[oldIndex];
          if (_this.$listeners["dragEvent"]) {
            // 如果提供执行
            _this.$emit("dragEvent", newIndex, currRow, oldIndex);
          }
        },
      });
    },
    rowClick(row, column, event){
      if (this.$listeners["rowClick"]){
        this.$emit("rowClick",row, column, event)
      }
    },
    // 单选框选中数据
    handleSelectionChange(selection) {
      if (this.multSelect){
        return false;
      }
      if (selection.length > 1) {
        this.$refs.tb.clearSelection();
        this.$refs.tb.toggleRowSelection(selection.pop());
      }
    },
    selectCheck(row){
      this.$listeners["selectRow"] && this.$emit("selectRow",row)
    }

  },
};
</script>

<style  lang="scss">
.my-draggble-table {
  //height: 100%;
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  .el-table__body tr.current-row>td{
    //background-color: var(--themeColor) !important ;
    //color: #fff!important;
  }
  .el-table__fixed-header-wrapper{
    .el-checkbox__inner{
      display:none;
    }
  }
}
</style>
