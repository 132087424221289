<template>
  <div class="square-wrap">
    <div class="square-video">
      <LivePlayer
        :videoUrl="videoInfo.videoUrl"
        fluent
        autoplay
        live
        aspect="fullscreen"
        stretch
      ></LivePlayer>
    </div>
    <div class="square-info">
      <span>{{ videoInfo.title }}</span>
      <span v-if="videoInfo.status"> <i class="el-icon-success"></i> 在线</span>
      <span v-else> <i class="el-icon-error"></i> 离线</span>
    </div>
  </div>
</template>

<script>
import LivePlayer from "@liveqing/liveplayer";
export default {
  props: ["videoInfo"],
  components: {
    LivePlayer,
  },
};
</script>

<style lang="less" scoped>
.square-wrap {
  height: 100%;
  width: 100%;
  padding: 5px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  .square-video {
    height: 93%;
    position: relative;
  }
  .square-info {
    display: flex;
    justify-content: space-between;
  }
}
</style>