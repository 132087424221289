// 过滤掉不是菜单的路由
function calcMenus(accessRoutes) {
  let menus = accessRoutes.filter((route) => {
    if (route.meta && route.meta.hasOwnProperty("isMenu")) {
      return false;
    } else {
      return true;
    }
  });
  return menus;
}

const state = {
  userId: undefined,
  token: undefined,
  realName: undefined,
  roles: undefined,
  userName: undefined,
  menu: [],
  permissions: [],
  orgList: [],
  orgId: undefined,
  orgPermissionMap: {},
  refreshTokenTime:"",
  tokenId:"",

};

const mutations = {
  SET_USERID(state, data) {
    state.userId = data;
  },
  SET_REALNAME(state, data) {
    state.realName = data;
  },
  SET_ROLES(state, data) {
    state.roles = data;
  },
  SET_USERNAME(state, data) {
    state.userName = data;
  },
  SET_TOKEN(state, data) {
    state.token = data;
  },
  SET_MENU(state, data) {
    state.menu = calcMenus(data);
  },
  SET_PERMISSIONS(state, data) {
    state.permissions = data;
  },
  SET_ORGLIST(state, data) {
    state.orgList = data;
  },
  SET_ORGID(state, data) {
    state.orgId = data;
  },
  SET_PERMISSIONMAP(state, data) {
    state.orgPermissionMap = data;
  },
  SET_REFRESHTOKENTIME(state,data){
    state.refreshTokenTime = data
  },
  SET_TOKENID(state,data){
    state.tokenId = data
  },

};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
