import axios from "../axios";
import qs from "qs";
import $ from "jquery"
import store from "@/store";
import {JSONLoader} from "../../../public/earthSDK/Apps/Demos/Viewer/scripts/three.min";
const videoApi = {
  getEquipmentInter(params) {
    /**
     *
     * @params {Number} page 第几页
     * @params {Number} size 页的数量
     * @params string deviceName 设备名称
     */
    return axios.get("/nvrDevice/list", { params });
  },

  // 查询liveNvr 平台信息
  getNvrInfo(platFormId,params) {
    // return axios.get("/nvrLiveNvr", { params });
    return axios.post(`/nvrChannel/video/${platFormId}`,  params );
  },
  getChannelLiveInter(params) {
    /**
     * @params {string} channelId 通道Id
     */
    return axios.get("/nvrLiveNvr/getLiveNvrStream", { params });
  },
  getNvrGroupInter(params) {
    /**
     *
     * @params {Number} page 第几页
     * @params {Number} size 页的数量
     * @params string nvrGroupName 设备名称
     */
    return axios.get("/nvrGroup/list", { params });
  },
  getChannelInter(params) {
    /**
     *
     * @params {Number} page 第几页
     * @params {Number} size 页的数量
     * @params string channelName 通道名称
     */
    return axios.get("/nvrChannel/list", { params });
  },
  addNvrGroup(params) {
    return axios.post("/nvrGroup", params);
  },
  deleteGroup(params) {
    return axios.delete("/nvrGroup/" + params);
  },
  editeGroup(params) {
    return axios.put("/nvrGroup", params);
  },
  getGroupbyId(params) {
    return axios.get("/nvrGroup/" + params);
  },
  getLiveStreamList(params) {
    return axios.get("/nvrGroup/getLiveStream/" + params);
  },

  // 获取通道的实时预览视频流
  getMonitorLiveStream(params){
      return axios.post("/nvrChannel/stream/realTime", params);
  },

  /*门禁管理添加区域*/
  // 添加区域
  addRegion(params){
    return axios.post("/nvr/accessControl/region", params);
  },
  /*视频管理添加区域*/
  addRegionInVideo(params){
    return axios.post("/nvr/region", params);
  },


  // 编辑区域
  editRegion(params){
    return axios.put("/nvr/accessControl/region", params);
  },

  // 删除区域
  deleteRegion(regionId){
    return axios.delete(`/nvr/accessControl/region/${regionId}` );
  },

  // 大华预置点
  setPresetPoint(params){
    return axios.post("/nvrPtz/icc/operatePresetPoint", params);
  },

  // 添加关联摄像头
  addCamera(params){
    return axios.post("/nvr/accessControl/region/relation", params);
  },

  // 删除摄像头
  deleteCamera(params,success,error){
    return axios.put(`/nvr/accessControl/region/relation`,params,{
      headers: {
        "Content-Type": "application/json;charset=UTF-8"
      }
    });
   // return $.ajax({
   //    type: 'PUT',
   //    url: 'api/nvr/accessControl/region/relation',
   //    data: JSON.stringify(params),//'ids='+arr+'&_method=delete',
   //   dataType: "text",
   //   headers:{
   //     Authorization: store.state.user.token,
   //     "Wid-Org-Id":store.state.user.orgId || "",
   //     "Content-Type":"application/json;charset=UTF-8"
   //   },
   //    success: function(data){
   //      console.log(121212)
   //      success && success(data)
   //    },
   //    error: function(data){
   //    error &&  error(data)
   //    }
   //  });
  },


  deleteCameraReal(channelId) {
    return axios.delete(`/nvrChannel/${channelId}`, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8"
      }
    });
  },



  // 获取chanel list 通道
  getChanelList(params={
    page:0,
    size:99999,
    // channelType:""
  }){
    return axios.get("/nvrChannel/list", {params});
  },

  getnvrChanel(params){
    return axios.get("/nvrChannel/list", {params});
  },


  // 获取转码的视频流地址
  getLiveStreamListZlm(params) {
    return axios.post("/nvrChannel/zlm/stream/realTime", params);
  },

  // 根据通道id 获取根据通道id，查询这个门禁通道下的报警记录
  getEntranceGuardAlarm(params,showLoading=true){
    return axios.post("/nvr/accessControl/alarm/list", params,{
      showLoading,
    });
  },


  // 修改通道信息
  editChannelInfo(params,showLoading=true){
    return axios.put("/nvrChannel", params,{
      showLoading,
    });
  },

  // 获取通道对应的预置点的列表
  getPresetList(outChannelId,platformId,channelId){
    return axios.get("/nvrPtz/getPresetPoint", {
      params:{
        outChannelId,platformId,channelId
      }
    });
  },
  // 绑定预置点与资产
  bindPresetAndAssets(params,showLoading=false){
    return axios.put("/nvrPtz/bindPresetPointAsset", params,{
      showLoading,
    });
  },
  // 调用预置点
  movePreset(data){
    return axios.post("/nvrPtz/icc/operatePresetPoint", data);
  },
  getWeatherList(params){
    return axios.get('/weather/latest',{params})
  },


};
export default videoApi;
