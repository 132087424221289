<template>
  <div class="ok-cancel-wrap" :class="className">
    <el-button v-if="showOk" type="primary" @click="onOk">{{submitText}}</el-button>
    <el-button v-if="showCancel" @click="onCancel">取消</el-button>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  props: {
    className: {
      type: String,
      default: "",
    },
    showOk: {
      type: Boolean,
      default: true,
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
    submitText:{
      type:String,
      default:"提交"
    }
  },
  data() {
    return {};
  },
  components: {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    onOk() {
      if (this.$listeners["onOk"]) {
        this.$emit("onOk");
      }
    },
    onCancel() {
      if (this.$listeners["onCancel"]) {
        this.$emit("onCancel");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.ok-cancel-wrap {
  height: 100%;
}
</style>
