import axios from "../axios";
export const rangeMangeApi = {
  rangeMangeApi: {
    // 查询列表
    getList(params) {
      return axios.get("/patrol/v2/scheme/list", {
        params,
        showLoading: true,
      });
    },

    // 新增巡检方案
    add(params) {
      return axios.post("/patrol/v2/scheme", params, {
        showLoading: true,
      });
    },

    // 删除巡检内容
    del(id) {
      return axios.delete("/patrol/v2/scheme/" + id, {
        showLoading: true,
      });
    },

    // 删除视频巡检内容
    delVideo(schemeVideoIds){
      return axios.delete("/patrol/v2/scheme/video/batch" , {
        showLoading: true,
        params:{
          schemeVideoIds
        }
      });
    },
    // 排序
    sortVideoId(params){
      return axios.put("/patrol/v2/scheme/video/sort", params, {
        showLoading: false,
      });
    },




    // 修改巡检范围
    update(params) {
      return axios.put("/patrol/v2/scheme", params, {
        showLoading: true,
      });
    },

    // 查询巡检范围
    getRange(id) {
      return axios.get("/patrol/v2/scheme/" + id, {
        showLoading: true,
      });
    },


    // 查询巡检类别
    getPollingType(){
      return axios.get("/patrol/v2/item/inspection/type/like" , {
        showLoading: false,
      });
    },






    // 巡检点根据单元位置分组
    getNode(params) {
      return axios.get("/patrol/node/region/list", {
        showLoading: true,
        params
      });
    },
    // 巡检范围名称列表
    getNameList() {
      return axios.get("/patrol/range/name/list", {
        showLoading: true,
      });
    },


    // 新建巡检方案
    createScheme(params) {
      return axios.post("/patrol/v2/scheme", params, {
        showLoading: true,
      });
    },

    // 设置预置点，并返回预置点id
    setCameraPreset(params){
      return axios.post("/patrol/v2/scheme/video/channel/point", params, {
        showLoading: true,
      });
    },

    // 新增视频巡检
    addVideoPolling(params){
      return axios.post("/patrol/v2/scheme/video", params, {
        showLoading: true,
      });
    },

    // 编辑视频巡检
    editVideoPolling(params){
      return axios.put("/patrol/v2/scheme/video", params, {
        showLoading: true,
      });
    },
    // 删除视频巡检
    delVideoPolling(schemeVideoId){
      return axios.delete(`/patrol/v2/scheme/video/${schemeVideoId}`, {
        showLoading: true,
      });
    },
    // 查询巡检子项
    getVideoPolling(schemeVideoId){
      return axios.get(`/patrol/v2/scheme/video/${schemeVideoId}`, {
        showLoading: true,
      });
    },
    // 查询视频巡检分组
    getVideoPollingGroup(params){
      return axios.get(`/patrol/v2/scheme/video/group/region`, {
        showLoading: true,
        params
      });
    },

    // 删除摄像机
    delCamera(params){

    },



    /*巡检路线*/
    //根据巡检方案id,查询这个方案下所有的巡检路线信息
    getLineList(schemeId){
      return axios.get(`/patrol/v2/scheme/${schemeId}/line/list`, {
        showLoading: true,
      });
    },

    // 新增巡检点
    addLinePoint(params){
      return axios.post("/patrol/v2/scheme/line", params, {
        showLoading: true,
      });
    },

    // 编辑巡检点
    updateLinePoint(params){
      return axios.put("/patrol/v2/scheme/line", params, {
        showLoading: true,
      });
    },

    // 查询巡检点详情
    getLindPointDetail(schemeLineId){
      return axios.get(`/patrol/v2/scheme/line/${schemeLineId}`, {
        showLoading: true,
      });
    },

    // 删除巡检点
    delLinePoint(params){
      return axios.delete(`/patrol/v2/scheme/line/batch`, {
        showLoading: true,
        params
      });
    },

    // 修改巡检路线顺序
    updateLinePointSort(params){
      return axios.put("/patrol/v2/scheme/line/sort", params, {
        showLoading: true,
      });
    },

    // 查询预置点List
      getPresetPointList(params){
      return axios.get("/nvrPtz/getPresetPoint",  {
        showLoading: false,
        params,
      });
    },


    // 复制方案
    copyScheme(schemeId){
      return axios.post("/patrol/v2/scheme/copy/"+schemeId,  {
        showLoading: false,
      });
    }




  },
};
