import axios from "../axios";
import qs from "qs";
const loginApi = {
  doLogin(params) {
    /**
     * 登录
     * @parmas {string} userName 用户名 必填
     * @parmas {string} password 密码
     */
    return axios.post("/login", qs.stringify(params), { showLoading: true });
  },
  refreshToken(params){
    return axios.get("/login/refreshToken", {
      params,
      showLoading: true,
    });
  },


  /*获取用户详细信息*/
  getUserInfo(token){
    return axios.get("/user/", {
      showLoading: false,
      headers:{
        'Authorization': token
      }
    });
  },

  /*根据authorizationId，获取登录信息*/
  getUserInfoByAuthorizationId(authorizationId){
    return axios.get(`/login/authorizationId/${authorizationId}`, {
      showLoading: false,
    });
  }

};
export default loginApi;
