import axios from "./axios";
export const controlCenter = {
    controlCenter: {
        // 查询列表
        getList(params) {
            return axios.get("/ctl/plan/list", {
                params,
                showLoading: true,
            });
        },
        // // 新增
        // add(params) {
        //     return axios.post("/ctl/device/config", params, {
        //         headers: {
        //             "node-Type": "application/json",
        //         },
        //         showLoading: true,
        //     });
        // },
        // // 删除
        // del(ctlDeviceIds) {
        //     return axios.delete("/ctl/device/config", {
        //         params: {
        //             ctlDeviceIds,
        //         },
        //         showLoading: true,
        //     });
        // },
        // // 根据id 查询
        // getNodeInfo(id) {
        //     return axios.get("/ctl/device/config/" + id, {
        //         showLoading: true,
        //     });
        // },
        // // 修改
        // modify(params) {
        //     return axios.put("/ctl/device/config", params, {
        //         headers: {
        //             "node-Type": "application/json",
        //         },
        //         showLoading: true,
        //     });
        // }
    },
};
