/**
 * 操作本地存储的对象
 */

const local = {
  get(key, type = "localStorage") {
    return JSON.parse(window[type].getItem(key));
  },
  set(key, val, type = "localStorage") {
    window[type].setItem(key, JSON.stringify(val));
  },
  remove(key, type = "localStorage") {
    window[type].removeItem(key);
  },
  clear(type = "localStorage") {
    window[type].clear();
  },
};

// 暴露
export default local;
