import dayjs from "dayjs"

const state = {
    checkTime :dayjs(new Date()).format("YYYY-MM-DD"),  /*值班记录查询时间*/
    logCount :0         /*交班日志记录数*/
}


const mutations = {
    CHANGE_CHECKTIME(state,data){
        state.checkTime = data
    },
    CHANGE_LOGCOUNT(state,data){
        state.checkTime = data
    },
}

export default {
    namespaced: true,
    state,
    mutations,
};