import axios from "../axios";
import qs from "qs";
const customiseconfigApi = {
  getCustomizeList(params){
    return axios.get('/customize/config/page',{params})
  },
  addCustomize(params){
    return axios.post('/customize/config',params)
  },
  deleteCustomize(code){
    return axios.delete('/customize/config/'+code)
  },
  editCustomize(params){
    return axios.put('/customize/config',params)
  },
  importExcelforCustomize(code,params){
    return axios.post('/customize/ts/importExcel/'+code,params)
  },
  getCustomiseTree(code){
    return axios.get('/customize/ts/tree/'+code)
  },
  getCustomisepagelist(params){
    return axios.get('/customize/ts/page',{params})
  },
  getExcelTemplate(){
    return axios.get('/customize/ts/importTemplate',{
      responseType: "blob",
    })
  },
  exportCustomizeExcel(code){
    return axios.get('/customize/ts/exportExcel/'+code,{
      responseType: "blob",
    })
  },
  editCustomizeList(params){
    return axios.put('/customize/ts',params)
  }
};
export default customiseconfigApi;
