import axios from "../axios";
export const inspectionNodeApi = {
  inspectionNodeApi: {
    // 查询列表
    getList(params) {
      return axios.get("/patrol/node/list", {
        params,
        showLoading: true,
      });
    },
    // 新增巡检内容
    add(params) {
      return axios.post("/patrol/v2/item", params, {
        headers: {
          "node-Type": "application/json",
        },
        showLoading: true,
      });
    },
    // 删除巡检内容
    del(itemIds) {
      return axios.delete("/patrol/v2/item", {
        params: {
          itemIds,
        },
        showLoading: true,
      });
    },
    // 根据id 查询 巡检点详细内容
    getNodeInfo(id) {
      return axios.get("/patrol/v2/item/" + id, {
        showLoading: true,
      });
    },
    // 修改巡检内容
    modify(params) {
      return axios.put("/patrol/v2/item", params, {
        headers: {
          "node-Type": "application/json",
        },
        showLoading: true,
      });
    },
    // 查询巡检类别
    getTypeList(params) {
      return axios.get("/patrol/node/type/list", {
        params,
        showLoading: true,
      });
    },

    // 单元位置、设备名称查询
    getUnitAndDevice(params) {
      return axios.get("/patrol/device/region/list", {
        params,
        showLoading: true,
      });
    },
    // 查询巡检类别和巡检内容
    getContentAndType(params) {
      return axios.get("/patrol/content/type/list", {
        params,
        showLoading: true,
      });
    },
    // 查询巡检点
    getPoint(contentType) {
      return axios.get("/patrol/asset/list", {
        params: {
          contentType,
        },
        showLoading: true,
      });
    },

    // 查询巡检类别--资产
    getInspectionTypeList() {
      return axios.get("/patrol/v2/asset/inspection/type/like", {
        showLoading: true,
      });
    },

    // 根据巡检类别查询巡检内容
    getInspectionContentByType(inspectionContentType) {
      return axios.get("/patrol/content/list/type", {
        params: {
          inspectionContentType,
        },
        showLoading: true,
      });
    },
  },
};
