// config 配置mixin

export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    // 动态更改favcion
    dynamicChangeFavcion(path) {
      let link = document.querySelector("link[rel*='icon']");
      link.href = path;
    },
  },
};
