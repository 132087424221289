import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'

import {
  showFullScreenLoading,
  tryHideFullScreenLoading
} from './servicesHelp'
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_LR, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})
// 设置请求次数，请求的间隙
service.defaults.retry = 4;
service.defaults.retryDelay = 1000;

// request interceptor
service.interceptors.request.use(
  config => {
    const token = store.state.user.token
    const Wid_Org_Id = store.state.user.orgId
    // const Wid_Org_Id = "1"
    if (token) {
      config.headers.Authorization = token || '' // 每次发送请求之前自动将在session中的token提取出来当做响应头header
      config.headers['Wid-Org-Id'] = Wid_Org_Id || ''
    }
    if (config.showLoading) {
      showFullScreenLoading(config.showLoading)
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    // tips error
    // Message({
    //   message: error.response,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    if (response.config.showLoading) {
      tryHideFullScreenLoading()
    }
    if (response.status >= 200 && response.status < 300) {
      return response
    } else {
      return Promise.reject(response)
    }
  },
  error => {
    tryHideFullScreenLoading();
    if(error.response.status == 404){
      if(error.response.config.url=="/sys/ui/table/aleer-event-list"||error.response.config.url=="/sys/ui/summary/alert-event-summary"){
        return Promise.reject(error.response);
      }
    }
    console.log('错误: ' + error) // for debug
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    // return Promise.reject(error)


    // 处理超时的情况
    let config = error.config
    // If config does not exist or the retry option is not set, reject
    if(!config || !config.retry) return Promise.reject(error)

    // Set the variable for keeping track of the retry count
    config.__retryCount = config.__retryCount || 0

    // Check if we've maxed out the total number of retries
    if(config.__retryCount >= config.retry) {
      // Reject with the error
      return Promise.reject(error)
    }

    // Increase the retry count
    config.__retryCount += 1

    // Create new promise to handle exponential backoff
    let backoff = new Promise(function(resolve) {
      setTimeout(function() {
        resolve()
      }, config.retryDelay || 1)
    })

    // Return the promise in which recalls axios to retry the request
    return backoff.then(function() {
      return axios(config)
    })
  }
)

export default service
