import axios from "./axios";
export const videoSetting = {
  videoSetting: {
    // 查询列表
    getList(params) {
      return axios.get("/nvrPlatform/list", {
        params,
        showLoading: true,
      });
    },
    // 新增
    add(params) {
      return axios.post("/nvrPlatform", params, {
        headers: {
          "node-Type": "application/json",
        },
        showLoading: true,
      });
    },
    // 删除巡检内容
    del(nodeIds) {
      return axios.delete(`/nvrPlatform/${nodeIds}`, {
        showLoading: true,
      });
    },

    // 重新初始化平台
    init(platformId) {
      return axios.get(`/nvrPlatform/init/${platformId}`, {
        showLoading: true,
      });
    },

    // 增量同步摄像头
    syncNvr(platformId){
      return axios.put(`/nvrPlatform/sync/device/${platformId}`, {
        showLoading: true,
      });
    }
  },
};
