import axios from "./axios";

export const PTZ = {
  PTZ: {
    // 大华云台方向控制
    DHControl(params) {
      return axios.post("/nvrPtz/icc/operateDirect", params, {});
    },
    // 大华ICC 镜头远近控制
    DHControlDistance(params){
      return axios.post("/nvrPtz/icc/operateCamera", params, {});
    },
    // 海康云台控制
    HKControl(params) {
      return axios.post("/nvrPtz/hik/ptz", params, {});
    },
  },
};
