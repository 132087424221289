import axios from "../axios";
import qs from "qs";
const codecApi = {
  getCodecList(params) {
    /**
     * 分页查询codec信息
     * @parmas {Number} page 第几页 必填
     * @parmas {Number} size 页的数量 必填
     * @parmas {array} status 状态
     * @parmas {string} gatewayIds
     */
    return axios.get("/codec/list", {
      params,
      showLoading: true,
    });
  },
  createCodec(params) {
    /**
     * 新增codec
     * {
        "codecType": "string",
        "config": {},
        "gatewayId": "string",
        "name": "string",
        "protocolType": "string",
        "status": 0
        }
     */
    return axios.post("/codec", params, {
      headers: {
        "Content-Type": "application/json",
      },
      showLoading: true,
    });
  },
  searchByCodecId(params) {
    /**
     * 根据id查询codec信息
     * @params {string} codecId 网关id
     */
    return axios.get("/codec/" + params, { showLoading: true });
  },
  modifyCodec(id, params) {
    /**
     * 更新codec信息
     "codecId": "string"
     * {
        "config": {},
        "name": "string"
        }
     */
    return axios.put("/codec/" + id, params, {
      headers: {
        "Content-Type": "application/json",
      },
      showLoading: true,
    });
  },
  deleteByCodecId(params) {
    /**
     * 删除codec
     * @params {string} codeId
     */
    return axios.delete("/codec/" + params, { showLoading: true });
  },
  modifyCodecStatus(id, params) {
    /**
     * 停用或启用codec
     *  @params {string} codeId
     * @parmas {Number} status 状态
     */
    return axios.post("/codec/" + id + "/status", qs.stringify(params), {
      showLoading: true,
    });
  },
};
export default codecApi;
