import axios from "./axios";

export const digitaltwin = {
  digitaltwin: {
    // 查询列表
    getTree(params) {
      return axios.get("/digitalTwin/model/type/list", {
        params,
        showLoading: false,
      });
    },
    // 添加tag信息
    add(params) {
      return axios.put("/digitalTwin/tag", params, {
        headers: {
          "node-Type": "application/json",
        },
        showLoading: true,
      });
    },
    find(outTagId,modelId) {
      return axios.get("/digitalTwin/tag", {
        params: {
          outTagId,
          modelId
        },
        showLoading: true,
      });
    },
    // get scada tree
    // /metric/metricTable/tree
    getScadaTree(params) {
      return axios.get("/metric/metricTable/tree?withMetric=true", {
        showLoading: false,
        params
      });
    },
    // 获取metric所属站点
    getScadaTreeSite(params) {
      return axios.get("/metric/metricTable/tree?withMetric=false", {
        showLoading: true,
        params
      });
    },
    // 根据modelid 查询详细信息
    getHTpath(modelId) {
      return axios.get(`/digitalTwin/model/${modelId}`, {
        showLoading: true,
      });
    },

    /*查询关联模型*/
    getRelationModel(){
      return axios.get(`/digitalTwin/model/name/list`, {
        showLoading: false,
      });
    },
    /*查询关联swmm*/
    getRelationSwmm(){
      return axios.get(`/digitalTwin/model/swmm/name/list`, {
        showLoading: false,
      });
    },

    /*根据模型名称，获取模型详情*/
    getModelDetailByModelName(modelName){
      return axios.get(`/digitalTwinV2/model/byName/${modelName}`, {
        showLoading: true,
      });
    },
    /*根据模型id获取模型详情*/
    getModelDetailByModelId(modelId){
      return axios.get(`/digitalTwinV2/model/${modelId}`, {
        showLoading: true,
      });
    }
  },
};
